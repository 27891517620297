<template>
	<div class="titan-alerts">
		<div
			class="titan-alert"
			:class="'titan-alert-' + alert.type"
			v-for="alert in alerts"
			v-bind:key="alert.key"
		>
			<div class="titan-alert-icon">
				<font-awesome-icon icon="check" v-if="alert.type === 'success'" />
				<font-awesome-icon icon="times" v-if="alert.type === 'error'" />
				<font-awesome-icon icon="exclamation" v-if="alert.type === 'warning'" />
			</div>
			<div class="titan-alert-text">{{ alert.name }}</div>
			<font-awesome-icon
				icon="times"
				class="titan-alert-dismiss"
				@click="$store.commit('alert/delete', alert.key)"
			/>
		</div>
	</div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	components: {
		FontAwesomeIcon,
	},
	computed: {
		alerts() {
			return this.$store.state.alert.items;
		},
	},
};
</script>
<style lang="scss">
$success: #3ac165 ! default;
$error: #df3545 ! default;
$warning: #ffc107 ! default;

.titan-alerts {
	position: fixed;
	top: 90px;
	right: 20px;
	z-index: 9999;
	width: 280px;

	.titan-alert {
		background: var(--white);
		border: 1px solid #e3e6ea;
		border-radius: 5px;
		position: relative;
		-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
		margin-bottom: 10px;

		.titan-alert-text {
			font-size: 14px;
			line-height: 16px;
		}

		.titan-alert-dismiss {
			position: absolute;
			top: 50%;
			right: 10px;
			width: auto;
			height: 12px;
			margin-top: -6px;
			color: #989898;
		}

		.titan-alert-icon {
			position: absolute;
			top: -1px;
			left: -1px;
			bottom: -1px;
			width: 45px;
			background: var(--success);
			color: var(--white);
			border-radius: 5px;

			svg {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				cursor: pointer;
			}
		}

		&.titan-alert-error .titan-alert-icon {
			background: var(--danger);
		}

		&.titan-alert-warning .titan-alert-icon {
			background: var(--warning);
		}

		.titan-alert-text {
			padding: 13px 23px 13px 60px;
		}
	}
}
</style>
