<template>
	<autocomplete
		:url="this.url"
		:customHeaders="headers"
		:customParams="customParams"
		:onSelect="getData"
		:onInput="onInput"
		ref="rautocomplete"
		label
		anchor="Name"
		:debounce="500"
	></autocomplete>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import Autocomplete from 'vue2-autocomplete-js';
import i18n from '../../services/lang';
import apiCommon from '../../services/api/common.api';
import http from '@/services/helpers/http';
import enums from '@/services/helpers/enums';

export default {
	props: {
		typeSearch: { type: String },
		mode: { type: String, default: 'id' },
	},
	mixins: [abstractField],
	components: {
		autocomplete: Autocomplete,
	},
	name: 'autocompletef',
	data() {
		return {
			headers: '',
			url: '',
			customParams: {},
		};
	},
	mounted() {
		if (this.schema.mode === 'text' && typeof this.value === 'string') {
			// nastavenie hodnoty, napriklad vo fakturacnej adrese
			this.$refs.rautocomplete.setValue(this.value);
		} else if (this.value === null && this.schema.default !== undefined) {
			// nastavenie defaultnej hodnoty ak existuje, napriklad vyber CityParentId v detaile cities
			this.value = this.schema.default;
		}
	},
	created() {
		this.headers = http.getHeaders(true);
		if (this.schema.typeSearch === '1') {
			this.url = process.env.VUE_APP_ROOT_API + 'City/GetCities';
			this.customParams = {};
			if (this.value > 0) {
				this.getCity();
			}
		} else if (this.schema.typeSearch === '2') {
			this.url = process.env.VUE_APP_ROOT_API + 'Region/GetRegions';
			this.customParams = {};
			if (this.value > 0) {
				this.getRegion();
			}
		} else if (this.schema.typeSearch === '3') {
			this.url = process.env.VUE_APP_ROOT_API + 'Region/GetDistricts';
			this.customParams = {};
			if (this.value > 0) {
				this.getDistrict();
			}
		} else if (this.schema.typeSearch === '4') {
			this.url = process.env.VUE_APP_ROOT_API + 'Region/GetTerritories';
			this.customParams = {};
			if (this.value > 0) {
				this.getTerritory();
			}
		} else if (this.schema.typeSearch === '5') {
			this.url = process.env.VUE_APP_ROOT_API + 'Location/GetLocations';
			this.customParams = {};
			if (this.value) {
				this.getLocation();
			}
		} else if (this.schema.typeSearch === '6') {
			this.url = process.env.VUE_APP_ROOT_API + 'Object/GetObjects';
			this.customParams = {};
			if (this.value) {
				this.getObject();
			}
		} else if (this.schema.typeSearch === '7') {
			this.url = process.env.VUE_APP_ROOT_API + 'Location/GetLocations';
			this.customParams = {
				locationTypes: [
					enums.LocationTypeEnum.Region,
					enums.LocationTypeEnum.City,
					enums.LocationTypeEnum.District,
					enums.LocationTypeEnum.Territory,
				],
			};
			if (this.value) {
				this.getLocation();
			}
		}
	},
	methods: {
		getCity() {
			let _this = this;
			apiCommon.getEntity(this.value, 'City').then((resp) => {
				if (resp.data) {
					let name = resp.data.CityNameSk ? resp.data.CityNameSk : resp.data.CityNameCz;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getRegion() {
			let _this = this;
			apiCommon.getEntity(this.value, 'Region').then((resp) => {
				if (resp.data) {
					let name = resp.data.RegionNameSk ? resp.data.RegionNameSk : resp.data.RegionNameCz;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getDistrict() {
			let _this = this;
			apiCommon.getEntity(this.value, 'District').then((resp) => {
				if (resp.data) {
					let name = resp.data.DistrictNameSk ? resp.data.DistrictNameSk : resp.data.DistrictNameCz;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getTerritory() {
			let _this = this;
			apiCommon.getEntity(this.value, 'Territory').then((resp) => {
				if (resp.data) {
					let name = resp.data.TerritoryNameSk ? resp.data.TerritoryNameSk : resp.data.TerritoryNameCz;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getObject() {
			let _this = this;
			http.get('Object/GetDetailById', { id: this.value }).then((resp) => {
				if (resp.data) {
					let name =
						'O' +
						resp.data.ObjectId +
						' / ' +
						(resp.data.ObjectCountryId == 1 ? resp.data.ObjectNameSk : resp.data.ObjectNameCz) +
						' / ' +
						resp.data.UserEmail;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getLocation() {
			let _this = this;
			http.get('Location/GetLocation', { id: this.value.Id, locationType: this.value.Type }).then((resp) => {
				if (resp.data) {
					let name = resp.data.Name;
					_this.$refs.rautocomplete.setValue(name);
				}
			});
		},
		getData(obj) {
			if (this.schema.mode == 'text') {
				this.value = obj.Name.slice(0, -4);
				if (this.schema.onCityFromListSet) {
					this.schema.onCityFromListSet.call(this, obj.Zip);
				}
			} else if ((this.schema.typeSearch === '5', this.schema.typeSearch === '7')) {
				this.value = obj;
			} else {
				this.value = obj.Id;
			}
		},
		onInput(text) {
			if (this.schema.mode == 'text') {
				if (text == '') {
					this.value = '';
					if (this.schema.onCityFromListSet) {
						this.schema.onCityFromListSet.call(this, '');
					}
				} else {
					this.value = text;
				}
			} else if (text == '') {
				this.value = this.schema.default;
			}
		},
	},
};
</script>

<style lang="scss">
@import '~vue2-autocomplete-js/dist/style/vue2-autocomplete.css';
.field-autocomplete > div > div > input {
	display: block;
	padding: 6px 12px;
	font-size: 12px;
	width: 100%;
	line-height: 1.42857143;
	color: #555;
	background-color: var(--white);
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.autocomplete-wrapper {
	width: 100%;
}

.autocomplete > ul {
	z-index: 100;
	opacity: 0.9;
}
</style>
