import http from '@/services/helpers/http';

const commonApi = {
	getList(model, controller) {
		return http.getWithFilters(`${controller}/PagedList`, model);
	},

	getCount(model, controller) {
		return http.getWithFilters(`${controller}/Count`, model);
	},

	getStandardList(model, controller) {
		return http.getWithFilters(`${controller}/List`, model);
	},

	deleteEntity(id, controller) {
		return http.remove(`${controller}/Delete`, { id });
	},

	getEntity(id, controller) {
		return http.get(`${controller}/Get`, { id });
	},

	putEntity(entity, controller) {
		return http.put(`${controller}/Put`, entity);
	},

	postEntity(entity, controller) {
		return http.post(`${controller}/Post`, entity);
	},
};

export default commonApi;
