<template>
	<a
		class="border-0 shadow-none link"
		target="_blank"
		:href="`${this.value.Href}`"
	>{{this.value.Name}}</a>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
};
</script>