var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-client-table", {
        staticClass: "detail-table",
        attrs: { columns: _vm.columns, data: _vm.value, options: _vm.options },
        scopedSlots: _vm._u([
          {
            key: "Edit",
            fn: function (list) {
              return [
                _c("div", { staticClass: "d-flex justify-content-end" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.left",
                          modifiers: { hover: true, left: true },
                        },
                      ],
                      attrs: { title: _vm.$t("editsojourntype") },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "detail-table-icon",
                        attrs: { icon: "edit", cursor: "pointer", size: "lg" },
                        on: {
                          click: function ($event) {
                            return _vm.editDetail(list.row)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "NumberRow",
            fn: function (list) {
              return [_vm._v(_vm._s(list.index) + ".")]
            },
          },
          {
            key: "OstypeIsVisibleHomepage",
            fn: function (list) {
              return [
                _c("boolean-display", {
                  attrs: {
                    boolValue: Boolean(list.row.OstypeIsVisibleHomepage),
                  },
                }),
              ]
            },
          },
          {
            key: "OstypeIsVisibleCatalogSubfilter",
            fn: function (list) {
              return [
                _c("boolean-display", {
                  attrs: {
                    boolValue: Boolean(
                      list.row.OstypeIsVisibleCatalogSubfilter
                    ),
                  },
                }),
              ]
            },
          },
          {
            key: "OstypeIsEnabled",
            fn: function (list) {
              return [
                _c("boolean-display", {
                  attrs: { boolValue: Boolean(list.row.OstypeIsEnabled) },
                }),
              ]
            },
          },
          {
            key: "CountryId",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm.formatCountry(list.row.CountryId)))]
            },
          },
        ]),
      }),
      _c("sojourn-type-countries-modal", {
        ref: "sojourn-modal",
        attrs: { entity: _vm.modalEntity, isVisible: _vm.modalVisible },
        on: { onClose: _vm.modalClosed, onUpdate: _vm.modalUpdated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }