<template>
	<megau-button
		classprop="btn btn-success blue mr-1 ml-1"
		:icon="this.schema.icon"
		:tooltip="getTooltip()"
		@handleClick="routeToPreview()"
	></megau-button>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	props: {
		icon: { type: String, Default: 'eye' },
	},
	methods: {
		routeToPreview() {
			window.open(this.value.Href, '_blank');
		},
		getTooltip() {
			if (this.value) return this.value.Name;
			else return '';
		},
	},
};
</script>
