import axios from 'axios';
import http from './helpers/http';
import utils from './helpers/utils';
import auth from './auth.service';
import store from '../store';
import router from '../router';
import * as links from '../router/links.js';

/*
using:

automatically catch errors from:
- Vue (errors and warnings)
- window.onerror
- unhandled promise rejections
- axios errors

log custom error by logic:
- vue globally:
  Vue.handleError('error from global');

- in components:
  this.handleError('error from component', 'mounted');
  or
  throw 'error from component';

- in js without vue:
  import ErrorHandler from "@/services/errorHandler";
  ErrorHandler.handleError('error from js');
*/

const doNotAlertSuccessUrls = ['user/getrtoken', 'user/isauthenticated', 'logger', 'InvoiceAddress/CheckGetExistsUnpaidInvoices'];
function canAlertSuccess(url) {
	url = url.toLowerCase();
	for (let i = 0; i < doNotAlertSuccessUrls.length; i++) {
		const tmp = doNotAlertSuccessUrls[i];
		if (url.indexOf(tmp) > -1) {
			return false;
		}
	}
	return true;
}

function handleVueError(err, vm, info) {
	var message,
		stack = '';
	if (err.message) {
		message = err.message;
	} else {
		message = err;
	}

	if (err.stack) {
		stack = err.stack + ' / ';
	}

	if (info) {
		stack += info + ' / ';
	}

	if (vm) {
		if (vm.$root === vm) {
			stack += 'root instance';
		} else {
			var name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
			stack +=
				(name ? 'component <' + name + '>' : 'anonymous component') +
				(vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file : '');
		}
	}

	ErrorHandler.handleError(message, stack);
}

const ErrorHandler = {
	install(Vue, options) {
		console.log('[EH] install');

		// catching errors
		// ---------------
		Vue.config.errorHandler = function(err, vm, info) {
			//console.log('[EH] Vue.config.errorHandler:')
			handleVueError(err, vm, info);
		};

		Vue.config.warnHandler = function(msg, vm, trace) {
			//console.log('[EH] Vue.config.warnHandler:');
			handleVueError(msg, vm, trace);
		};

		window.onerror = function(msg, url, line, col, error) {
			//console.log('[EH] window.onerror:');
			ErrorHandler.handleError(msg, url + '\r\n' + line + '\r\n' + col + '\r\n' + error);
		};

		//window.addEventListener('unhandledrejection', function (event) {
		//event.promise contains the promise object
		//event.reason contains the reason for the rejection
		//console.log('[EH] unhandledrejection:');
		// event.promise.then((value) =>{
		// 	if (!(value.isErrorHandlerSource == true))
		// 	{
		// 		ErrorHandler.handleError(event);
		// 	}
		// });

		//ErrorHandler.handleError(event);
		//});

		axios.interceptors.request.use(function(config) {
			return config;
			// }, function (error) {
			// 	console.log('[EH] axios request error');
			// 	console.log(error);
			// 	return Promise.reject(error);
		});

		axios.interceptors.response.use(
			function(response) {
				console.log('[EH] axios response success: ' + response.config.url);

				if (response.config.method != 'get' && canAlertSuccess(response.config.url)) {
					store.dispatch('alert/success', 'succes');
				}
				return response;
			},
			function(error) {
				console.log('[EH] axios response error');
				console.log(error);
				const status = error.response ? error.response.status : null;
				//console.log('[EH] axios.interceptors.response: status:' + status);

				if (!status) {
					// probably network error
					if (error.message) {
						store.dispatch('alert/error', error.message);
					} else if (error.response) {
						let text = utils.getErrorTextFromResponse(error.response);
						store.dispatch('alert/error', text);
					} else {
						store.dispatch('alert/error', 'Unkown error');
					}
					return Promise.reject(error);
				} else if (status === 401) {
					if (auth.ShouldRefreshToken(error)) {
						return new Promise(function(resolve, reject) {
							auth.RefreshToken()
								.then((_) => {
									// recall origin request again
									resolve(http.callRequest(error.config));
								})
								.catch((error) => {
									//let text = utils.getErrorTextFromResponse(error.response);
									//store.dispatch('alert/error', text);

									reject(error);
									//router.push(links.LOGIN);
								});
						});
					} else {
						router.push(links.LOGIN);
						return Promise.reject(error);
					}
				} else {
					var message = '';
					var stack = '';

					if (error.message) {
						message = error.message + '\r\n';
					}

					if (error.stack) {
						stack = error.stack;
					}

					if (error.response && error.response.data && error.response.data.errors) {
						message += utils.getErrorTextFromResponse(error.response);
					} else {
						message += error;
					}

					store.dispatch('alert/error', message);
					ErrorHandler.handleError(message, stack, error.config.url);

					return Promise.reject(error);
				}
			}
		);

		Vue.mixin({
			// unhandled error in component level
			errorCaptured: function(err, vm, info) {
				//console.log('[EH] component.errorCaptured:');
				handleVueError(err, vm, info);
			},
			methods: {
				// methods to custom error logs in every component
				handleError: function(message, stack = '', requestUrl = '') {
					console.log('[EH] component.handleError:');
					ErrorHandler.handleError(message, stack, requestUrl, true);
				},
			},
		});

		// methods to custom error logs from Vue component
		Vue.handleError = function(message, stack = '', requestUrl = '') {
			//console.log('[EH] Vue.handleError:');
			ErrorHandler.handleError(message, stack, requestUrl, true);
		};
	},

	internalErrorCount: 0,
	timer: null,

	// global handler method
	handleError(message, stack, requestUrl = null, isCustom = false) {
		console.log('[EH] handleError:');

		if (ErrorHandler.internalErrorCount >= 2) {
			if (!ErrorHandler.timer) {
				ErrorHandler.timer = window.setTimeout(function() {
					ErrorHandler.internalErrorCount = 0;
					ErrorHandler.timer = null;
				}, 5000);
			}
			return false;
		}

		// try
		// {
		// 	store.dispatch('alert/error', message);
		// }
		// catch(error)
		// {
		// 	console.log(error);
		// }

		if (message) {
			var error = {
				Message: JSON.stringify(message),
				Stack: JSON.stringify(stack),
				Request: requestUrl,
				Page: window.location.toString(),
				IsCustom: isCustom,
			};
			console.log(error);

			if (process.env.NODE_ENV != 'development') {
				http.post('Logger/LogError', error).catch((reason) => {
					ErrorHandler.internalErrorCount += 1;
					console.log('[EH] handleError: cannot log: ' + reason);
				});
			}
			return true;
		} else {
			console.error('[EH] handleError: message is empty');
			return false;
		}
	},
};

export default ErrorHandler;
