var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showLinks
    ? _c("div", { staticClass: "links-container" }, [
        _c(
          "div",
          {
            staticClass: "card-counter reservations",
            attrs: { target: "_blank" },
          },
          [
            _c("span", { staticClass: "count-title" }, [
              _vm._v(_vm._s(_vm.$t("reservations2524"))),
            ]),
            _c("div", { staticClass: "count-name" }, [
              _c("span"),
              _c("span", { staticClass: "realized" }, [
                _vm._v(_vm._s(_vm.$t("notestocontacts"))),
              ]),
              _c("span", { staticClass: "realized" }, [
                _vm._v(_vm._s(_vm.$t("realized"))),
              ]),
            ]),
            _c("div", { staticClass: "count-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("lastmonths")))]),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getUrl(_vm.reservationsLastYear),
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "realized" }, [
                    _vm._v(_vm._s(_vm.value.ReservationLastYearCount)),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getUrl(_vm.reservationsLastYearRealized),
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "realized" }, [
                    _vm._v(_vm._s(_vm.value.RealizedReservationLastYearCount)),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "count-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("overall")))]),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getUrl(_vm.reservationsTotal),
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "realized" }, [
                    _vm._v(_vm._s(_vm.value.ReservationTotalCount)),
                  ]),
                ]
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getUrl(_vm.reservationsTotalRealized),
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "realized" }, [
                    _vm._v(_vm._s(_vm.value.RealizedReservationTotalCount)),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "card-counter stats",
            attrs: { href: _vm.getUrl(_vm.stats), target: "_blank" },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon-link",
              attrs: { icon: "chart-bar" },
            }),
            _c("span", { staticClass: "count-title" }, [
              _vm._v(_vm._s(_vm.$t("statistics2528"))),
            ]),
            _c("span", { staticClass: "count-name" }, [
              _vm._v(_vm._s(_vm.value.ObjectName)),
            ]),
          ],
          1
        ),
        _c(
          "a",
          {
            staticClass: "card-counter preview",
            attrs: { href: _vm.getUrlPreview(), target: "_blank" },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon-link",
              attrs: { icon: "eye" },
            }),
            _c("span", { staticClass: "count-title" }, [
              _vm._v(_vm._s(_vm.$t("preview"))),
            ]),
            _c("span", { staticClass: "count-name" }, [
              _vm._v(_vm._s(_vm.value.ObjectName)),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }