<template>
	<div>
		<router-view v-if="loaded"></router-view>
		<alert />
	</div>
</template>

<script>
import alert from './components/common/alert';
import serviceCommon from './services/service/common.service';
import { loadLanguageTranslations, loadCountries, loadDashboardState } from './store/actions';
import 'vue-loading-overlay/dist/vue-loading.css';
import http from './services/helpers/http';
import auth from './services/auth.service';

export default {
	name: 'app',
	components: { alert },
	computed: {
		loaded() {
			return this.$store.state.country.status === 'success' && this.$store.state.lang.status === 'success';
		},
	},
	beforeCreate() {
		// any data load must be at first in this event or later
		this.$store.dispatch(loadLanguageTranslations, 'sk');
		this.$store.dispatch(loadCountries);
	},
	created() {
		//// loop every completed commit in vuex
		this.$store.subscribe((mutation, state) => {
			serviceCommon.handleScrollOnSelectPage(mutation);
		});
		this.loadState();

		this.interval = setInterval(
			function () {
				this.loadState();
			}.bind(this),
			600000
		);
	},
	methods: {
		loadState: async function () {
			let res = await auth.IsAuthenticated();
			if (res === true) {
				this.$store.dispatch(loadDashboardState);
			}
		},
	},
	beforeDestroy: function () {
		clearInterval(this.interval);
	},
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

@import 'styles/basics/base';
@import 'styles/basics/formgenerator';
@import 'styles/basics/vuetable';
@import 'styles/basics/bootstrap';
@import 'styles/basics/detail-page';
@import 'styles/basics/table-page';
</style>
