import moment from 'moment';
import store from '../../store';

const plugin = {
	install(Vue, options) {
		// TODO: asi sa uz ani nepouziva, namiesto sa pouzivaju filtre dole
		Vue.prototype.$_f = formatter;
	},
};

const filters = {
	install(Vue, options) {
		Vue.filter('currency', formatter.currencyToString);
		Vue.filter('date', formatter.dateToString);
		Vue.filter('dateTime', formatter.dateTimeToString);
	},
};

const currencySymbols = {
	EUR: '€', // Euro
	CZK: 'Kč', //czech
	GBP: '£', // British Pound Sterling
	PLN: 'zł', // Ukrainian Hryvnia
	USD: '$', // US Dollar
};

const formatter = {
	capitalize(value) {
		if (typeof value !== 'string') return '';
		return value.charAt(0).toUpperCase() + value.slice(1);
	},

	dateToString(value, format = store.state.lang.DateFormat) {
		if (value) {
			return moment.utc(value).format(format);
		}
		return '';
	},

	dateTimeToString(value, format = store.state.lang.DateTimeFormat) {
		if (value) {
			return moment.utc(value).format(format);
		}
		return '';
	},

	currencyToString(value, currencyCode) {
		if (isNaN(parseFloat(value))) {
			return value;
		}

		var locale;
		switch (currencyCode) {
			case 'EUR':
				locale = 'sk-SK';
				break;
			case 'CZK':
				locale = 'cs-CZ';
				break;
			default:
				locale = store.state.lang.locale;
				break;
		}
		var formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
			minimumFractionDigits: 2,
		});
		return formatter.format(value);
	},

	getCurrencySymbol(currencyCode) {
		return currencySymbols[currencyCode];
	},
};

export default { plugin };
export { formatter };
export { filters };
