<template>
	<div class="d-flex align-items-center">
		<label>
			<input type="checkbox" v-model="value" :disabled="disabled" :name="schema.inputName" :id="getFieldID(schema)" />
			<span :data-on="schema.textOn || 'On'" :data-off="schema.textOff || 'Off'" :for="getFieldID(schema)" class="label" />
			<span class="handle" />
		</label>
		<span class="mr-3">{{ this.schema.date | dateTime }}</span>
		<span>{{ formatedUserName() }}</span>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
export default {
	mixins: [abstractField],
	props: {
		user: { type: String },
		date: { type: Date },
	},
	methods: {
		formatedUserName() {
			if (_.has(this.schema.user, 'ContactPersonName') && _.has(this.schema.user, 'ContactPersonSurname')) {
				let name = this.schema.user.ContactPersonName ? this.schema.user.ContactPersonName : '';
				let surname = this.schema.user.ContactPersonSurname ? this.schema.user.ContactPersonSurname : '';
				return name + ' ' + surname;
			}
		},
	},
};
</script>

<style lang="scss">
@include custom-switch('.field-verified-switch');
</style>
