var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        attrs: {
          type: "checkbox",
          disabled: _vm.disabled,
          name: _vm.schema.inputName,
          id: _vm.getFieldID(_vm.schema),
        },
        domProps: {
          checked: Array.isArray(_vm.value)
            ? _vm._i(_vm.value, null) > -1
            : _vm.value,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          },
        },
      }),
      _c("span", {
        staticClass: "label",
        attrs: {
          "data-on": _vm.schema.textOn || "On",
          "data-off": _vm.schema.textOff || "Off",
          for: _vm.getFieldID(_vm.schema),
        },
      }),
      _c("span", { staticClass: "handle" }),
    ]),
    _c("span", { staticClass: "mr-3" }, [
      _vm._v(_vm._s(_vm._f("dateTime")(this.schema.date))),
    ]),
    _c("span", [_vm._v(_vm._s(_vm.formatedUserName()))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }