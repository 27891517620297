var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "note-wrapper" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("remark2469"),
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.closeModal()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("savenote"),
                      classprop: "btn btn-success",
                    },
                    on: { handleClick: _vm.handleUpdateInvoiceNote },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deletenote"),
                      classprop: "btn btn-danger",
                    },
                    on: { handleClick: _vm.handleDeleteInvoiceNote },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showNoteModal,
            callback: function ($$v) {
              _vm.showNoteModal = $$v
            },
            expression: "showNoteModal",
          },
        },
        [
          _c("b-textarea", {
            ref: "textArea",
            attrs: { rows: "4" },
            model: {
              value: _vm.invoiceNoteText,
              callback: function ($$v) {
                _vm.invoiceNoteText = $$v
              },
              expression: "invoiceNoteText",
            },
          }),
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showInvoiceNote,
                expression: "showInvoiceNote",
              },
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            attrs: { title: this.invoiceNote },
            on: {
              click: function ($event) {
                _vm.editable
                  ? (_vm.showNoteModal = true)
                  : (_vm.showNoteModal = false)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              attrs: {
                icon: ["far", "sticky-note"],
                size: "lg",
                cursor: "pointer",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }