import createItemButton from '../../../components/common/button';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import stcModal from './sojourn-type-countries-modal';


function importTableComponents(vue) {
	vue.component('font-awesome-icon', FontAwesomeIcon);
	vue.component('megau-button', createItemButton);
	vue.component('sojourn-type-countries-modal', stcModal);
}

export default {
	importTableComponents
};
