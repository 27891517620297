var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      directives: [
        { name: "format-with-comma", rawName: "v-format-with-comma" },
      ],
      staticClass: "formatedInput",
      attrs: {
        "data-position": _vm.position,
        "data-prev-value": _vm.prevValue,
      },
      domProps: { value: _vm.formatedValue },
      on: { input: _vm.handleInput, blur: _vm.onBlurHandler },
    }),
    _c("label", { staticClass: "currency-symbol" }, [
      _vm._v(_vm._s(_vm.lblCurrency)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }