import i18n from '../../services/lang';

const validatorCommon = {
	checkEmpty(value, required) {
		if (!value || value === '') {
			if (required) {
				return [i18n.t('amistake!')];
			} else {
				return null;
			}
		}
		return null;
	},
};

export default validatorCommon;
