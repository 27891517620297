var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "titan-alerts" },
    _vm._l(_vm.alerts, function (alert) {
      return _c(
        "div",
        {
          key: alert.key,
          staticClass: "titan-alert",
          class: "titan-alert-" + alert.type,
        },
        [
          _c(
            "div",
            { staticClass: "titan-alert-icon" },
            [
              alert.type === "success"
                ? _c("font-awesome-icon", { attrs: { icon: "check" } })
                : _vm._e(),
              alert.type === "error"
                ? _c("font-awesome-icon", { attrs: { icon: "times" } })
                : _vm._e(),
              alert.type === "warning"
                ? _c("font-awesome-icon", { attrs: { icon: "exclamation" } })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "titan-alert-text" }, [
            _vm._v(_vm._s(alert.name)),
          ]),
          _c("font-awesome-icon", {
            staticClass: "titan-alert-dismiss",
            attrs: { icon: "times" },
            on: {
              click: function ($event) {
                return _vm.$store.commit("alert/delete", alert.key)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }