import http from '../../services/helpers/http';
import i18n from '../../services/lang';
import serviceEnums from '../../services/service/enums.service';
import { formatter } from '../../services/helpers/formatter';

const service = {
	getList(objectId, invoiceId, markCurrentPackage) {
		var data = { markCurrentPackage: markCurrentPackage };
		if (objectId) data.objectId = objectId;
		if (invoiceId) data.invoiceId = invoiceId;

		return http.get('UserProductListItem/GetList', data);
	},
	getProductForAddForm(productId, objectId) {
		return http.get('UserProduct/GetProductDataForNewUserProduct', {
			productId: productId,
			objectId: objectId,
		});
	},
	getProductForAddFormByInvoice(productId, invoiceId) {
		return http.get('UserProduct/GetProductDataForNewUserProductByInvoice', {
			productId: productId,
			invoiceId: invoiceId,
		});
	},
	getProductForEditForm(uProductId, productId) {
		return http.get('UserProduct/GetProductDataForEditUserProduct', {
			uProductId: uProductId,
			productId: productId,
		});
	},

	headingTranslations() {
		return {
			NumberRow: i18n.t('s.no.'),
			Name: i18n.t('packagename'),
			DateStart: i18n.t('validfrom'),
			DateEnd: i18n.t('validto2420'),
			PriceTotal: i18n.t('totalprice'),
			Price: i18n.t('pricewithoutvat'),
			Vat: i18n.t('vat'),
			DatePayment: i18n.t('paidactivated'),
			InvoiceVariableSymbol: i18n.t('proformainvoice'),
			INumber: i18n.t('invoicevariable'),
			Edit: i18n.t('actions'),
		};
	},
};

export default service;
