<template>
	<v-client-table :columns="columns" :data="value" :options="options">
		<template v-slot:ContactIsSubscribed="contactsList">{{ formatTitle(contactsList.row.ContactIsSubscribed) }}</template>

		<template v-slot:CreatedOn="contactsList">{{ contactsList.row.CreatedOn | date }}</template>
	</v-client-table>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	name: 'subscribeHistory',
	data() {
		return {
			columns: ['CreatedOn', 'ContactIsSubscribed', 'ReasonString', 'ContactUnsubscribeRemark', 'CreatorEmail'],
			options: {
				filterable: false,
				filterByColumn: true,
				headings: {
					CreatedOn: this.$t('dateandtime'),
					ContactIsSubscribed: this.$t('sending2353'),
					ReasonString: this.$t('reasonforunsubs'),
					ContactUnsubscribeRemark: this.$t('detailedspecifi'),
					CreatorEmail: this.$t('changed'),
				},
			},
		};
	},
	methods: {
		formatTitle(isChecked) {
			return isChecked == 0 ? this.$t('no') : this.$t('yes');
		},
	},
};
</script>

<style lang="scss">
.field-subscribeHistory {
	.table-responsive {
		min-height: auto;
		.VueTables__table > th:nth-child(1) {
			width: 18%;
		}
		.VueTables__table > th:nth-child(2) {
			width: 10%;
		}
		.VueTables__table > th:nth-child(3) {
			width: 25%;
		}
		.VueTables__table > th:nth-child(4) {
			width: 22%;
		}
		.VueTables__table > th:nth-child(5) {
			width: 25%;
		}
	}
	.VueTables--client {
		width: 100%;
	}
	.VuePagination {
		display: none;
	}
}
</style>
