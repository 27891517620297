<template>
	<label class="file-select mb-0">
		<div class="select-button">
			<megau-button
				classprop="btn btn-secondary btn-upload"
				icon="file"
				:title="$t('uploadanimage')"
				@handleClick="openfileDialog()"
			></megau-button>
		</div>
		<label>&nbsp;&nbsp;</label>
		<span v-if="file"> {{ file.name }}</span>
		<div id="preview">
			<img v-if="url" :src="url" />
		</div>
		<input id="fileInput" accept="image/*" type="file" @change="handleFileChange" />
	</label>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import http from '@/services/helpers/http';

export default {
	mixins: [abstractField],

	methods: {
		async handleFileChange(e) {
			this.file = e.target.files[0];

			let formData = new FormData();
			formData.append('file', this.file);
			let response = await http.postFile('FileUpload/UploadFile', formData);
			this.value = response.data;
			this.url = URL.createObjectURL(this.file);

			this.$emit('input', this.value);
		},
		openfileDialog() {
			document.getElementById('fileInput').click();
		},
	},
	created() {
		if (this.value) {
			this.url = this.value;
		}
	},
	data() {
		return {
			file: null,
			url: null,
		};
	},
};
</script>

<style lang="scss">
.file-select {
	> input[type='file'] {
		display: none;
	}
}

.btn-upload {
	color: var(--white) !important;
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}

#preview {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 10px;
}

#preview img {
	max-width: 100%;
	max-height: 250px;
}
</style>
