import formFields from './invoice-address-invoice.fields';

function detailModel(i18n) {
	return {
		controllerName: 'InvoiceAddress',
		isVisibleDetail: false,
		buttonTitle: '',
		modelInvAddress: {
			InvaddrName: null,
			InvaddrStreet: null,
			InvaddrCity: null,
			InvaddrZip: null,
			InvaddrIco: '',
			InvaddrDic: '',
			InvaddrIcdph: '',
			InvaddrEmail: null,
			InvaddrPhone: null,
			InvoiceId: null,
		},
		schemaInvAddress: {
			fields: formFields(i18n),
		},
		formOptionsInvAddress: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateDebounceTime: 500,
			validateAsync: true,
		},
	};
}

export { detailModel };
