var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.wrapperClass },
        [
          _c("label", { attrs: { isVisible: this.showEmailSend } }, [
            _vm._v(_vm._s(this.labelText)),
          ]),
          _c(
            "b-dropdown",
            {
              staticClass: "mr-1",
              attrs: { id: "dropdown-header", text: this.buttonText },
            },
            _vm._l(_vm.offersList, function (offer) {
              return _c(
                "b-dropdown-item",
                {
                  key: offer.Id,
                  on: {
                    click: function ($event) {
                      return _vm.onDropdownSelect(offer.Id)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(offer.Subject))])]
              )
            }),
            1
          ),
          _c("email-send-modal", {
            attrs: {
              isVisible: this.showEmailSend,
              typeId: _vm.pesLogTypeId,
              templateId: _vm.emailTemplateId,
              entityId: _vm.entityId,
              isSentEmail: false,
            },
            on: { onClose: _vm.onCloseEmailSend },
          }),
        ],
        1
      ),
      _vm.withTable
        ? _c("emails-table", {
            ref: "parentEmailsTable",
            attrs: {
              show: _vm.showTable,
              entityId: _vm.entityId,
              entityType: _vm.pesLogTypeId,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }