<template>
	<div>
		<b-form-group required="true">
			<b-form-radio-group :options="schema.dataval" :name="schema.groupname" v-model="selected"></b-form-radio-group>
		</b-form-group>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
	props: {
		dataval: {
			type: Array,
		},
		groupname: { type: String },
	},
	computed: {
		selected: {
			get() {
				return this.value ? this.value : -1;
			},
			set(newVal, oldwal) {
				if (newVal === -1) {
					this.value = null;
				} else {
					this.value = newVal;
				}
			},
		},
	},
	mixins: [abstractField],
};
</script>

<style lang="scss">
/* _custom-forms.scss:136 */

.form-group.valid.field-radios-group {
	height: auto;
}

/* _custom-forms.scss:136 */
.custom-radio {
	width: 150px;

	.custom-control-label > span {
		vertical-align: middle;
	}

	.custom-control-label::before {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: '';
		background-color: var(--white);
		border: var(--mu-icongray) solid 1px;
	}

	.custom-control-input:checked ~ .custom-control-label {
		&::before {
			content: '';
		}
		&::after {
			background-color: var(--primary);
			border-radius: 50%;
			background-image: url('../../../public/img/icons/check.png');
		}
	}

	.custom-control-input:disabled {
		&:checked ~ .custom-control-label::before {
			background-color: var(--black);
		}
	}
}
</style>
