import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import megaButton from '@/components/common/button';
import LinkToManual from '@/components/common/link-to-manual';
import CountryFlag from '@/components/common/country-flag';
import PerPageSelect from '@/components/table/per-page-select';
import ActiveFilters from '@/components/table/active-filters';
import FieldFileSelect from '@/components/detail/file-select.field';

import CreateItemRemark from '@/components/common/remark';
import FieldDetailDatepickerSingle from '@/components/detail/datepicker-range-single.field';
import invoiceLink from '@/components/common/invoice-link';
import htmlTextEditor from '@/components/common/html-text-editor-vfg.field';
import FieldCheckboxes from '@/components/detail/checkboxes.field';

import FieldAccountingCompany from '@/modules/accounting-companies/accounting-company-vfg.field.vue';
import FieldInvoiceAddress from '@/modules/invoices/invoice-address/invoice-address-vfg.field.vue';
import FieldInvoiceAddressInvoice from '@/modules/invoices/invoice-address-invoice/invoice-address-invoice-vfg.field.vue';
import FieldContactBox from '@/modules/invoices/contact-box-vfg.field.vue';
import FieldLinkBox from '@/modules/invoices/link-box-vfg.field.vue';
import accountigCompanyDetail from '@/modules/accounting-companies/accounting-company-vfg.detail';

import linkField from '@/components/detail/link.field';
import GoogleMap from '@/components/detail/google-map';
import FieldAutocomplete from '@/components/detail/autocomplete.field';
import CitySubTable from '@/modules/cities/city-sub.table.vue';
import fieldSojournTypeCountriesTable from '@/modules/sojourn-types/countries/sojourn-type-countries.table';

import attractionVerified from '@/components/detail/verified-vfg.field';

import FieldRadios from '@/components/detail/radios.field';
import FieldSubscribeHistory from '@/modules/contacts/subscribe-history';
import FieldchangePassword from '@/modules/contacts/change-password.field';
import FieldSwitchAction from '@/components/detail/switch-action.field';

import LabelsField from '@/components/detail/labels.field';
import fieldButtonLink from '@/components/detail/buttonLink.field';
import fieldObjectLink from '@/components/detail/link.field';

import fieldDetailDatepicker from '@/components/detail/datepicker-range.field';

import VfgFieldClickActions from '@/components/detail/click-actions-vfg.field';
import starRating from '@/components/detail/star-rating.field';

import emailsSendModul from '@/modules/emails/email-send.dropdown';
import productsSection from '@/modules/user-products/products.section';

import formatedInputField from '@/components/detail/formated-number-input.field';

import fieldOwnerHistory from '@/modules/object-reservation/owner-history-vfg.field';
import FieldReviewHistory from '@/modules/object-reservation/object-reservation-reviews-history';

import articleCreated from '@/modules/seo-pages/seo-page-article/seo-page-article-created-vfg.field';

const componentsRegister = (vue) => {
	vue.component('font-awesome-icon', FontAwesomeIcon);
	vue.component('megau-button', megaButton);
	vue.component('link-to-manual', LinkToManual);
	vue.component('megau-button', megaButton);
	vue.component('remark', CreateItemRemark);
	vue.component('field-single-datepicker', FieldDetailDatepickerSingle);
	vue.component('invoice-link', invoiceLink);
	vue.component('country-flag', CountryFlag);
	vue.component('perpage-select', PerPageSelect);
	vue.component('active-filters', ActiveFilters);
	vue.component('field-file-select', FieldFileSelect);

	// Components used in object reservation detail
	vue.component('field-owner-history', fieldOwnerHistory);
	vue.component('field-reviewHistory', FieldReviewHistory);

	// Components used in invoice detail
	vue.component('field-contact-box', FieldContactBox);
	vue.component('field-link-box', FieldLinkBox);
	vue.component('field-accounting-company', FieldAccountingCompany);
	vue.component('field-invoice-address', FieldInvoiceAddress);
	vue.component('field-invoice-address-invoice', FieldInvoiceAddressInvoice);
	vue.component('accounting-company-detail', accountigCompanyDetail);

	// Components used in territory detail
	vue.component('google-map', GoogleMap);
	vue.component('field-link', linkField);
	vue.component('field-autocomplete', FieldAutocomplete);
	vue.component('city-sub-table', CitySubTable);

	// Components used in country detail
	vue.component('field-html-text-editor', htmlTextEditor);
	vue.component('field-checkboxes-group', FieldCheckboxes);

	// Components used in attraction detail
	vue.component('field-verified-switch', attractionVerified);

	// Components used in contact detail
	vue.component('field-radios-group', FieldRadios);
	vue.component('field-subscribeHistory', FieldSubscribeHistory);
	vue.component('field-switchAction', FieldSwitchAction);
	vue.component('field-change-password', FieldchangePassword);

	// Components used in email template detail
	vue.component('field-labels', LabelsField);

	// Components used in backlink template detail
	vue.component('field-buttonlink', fieldButtonLink);
	vue.component('field-objectlink', fieldObjectLink);

	// Components used in object-demand template detail
	vue.component('field-range-datepicker', fieldDetailDatepicker);

	// Components used in object-review template detail
	vue.component('field-click-actions', VfgFieldClickActions);
	vue.component('field-starrating', starRating);

	// Components used in object template detail
	vue.component('email-send-dropdown', emailsSendModul);
	vue.component('products-section', productsSection);

	// Components used in portal-product template detail
	vue.component('field-formated-input', formatedInputField);

	// Components used in sojourn-type template detail
	vue.component('field-sojourn-type-countries-table', fieldSojournTypeCountriesTable);

	// COmponents used in seo-page-article
	vue.component('field-article-created', articleCreated);
};

export { componentsRegister };
