import http from '@/services/helpers/http';

const auth = {
	login(username, password) {
		return http.post('User/Login', { username, password }, false);
	},

	isAuthenticated() {
		return http.post('User/IsAuthenticated');
	},

	getRToken(entity) {
		return http.put('user/getrtoken', entity, false);
	},
};

export default auth;
