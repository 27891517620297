<template>
	<div>
		<v-client-table :columns="columns" :data="value" :options="options" class="detail-table">
			<template v-slot:Edit="list">
				<div class="d-flex justify-content-end">
					<div v-b-tooltip.hover.left :title="$t('editsojourntype')">
						<font-awesome-icon
							icon="edit"
							cursor="pointer"
							size="lg"
							class="detail-table-icon"
							@click="editDetail(list.row)"
						></font-awesome-icon>
					</div>
				</div>
			</template>
			<template v-slot:NumberRow="list">{{ list.index }}.</template>
			<template v-slot:OstypeIsVisibleHomepage="list">
				<boolean-display :boolValue="Boolean(list.row.OstypeIsVisibleHomepage)"></boolean-display>
			</template>
			<template v-slot:OstypeIsVisibleCatalogSubfilter="list">
				<boolean-display :boolValue="Boolean(list.row.OstypeIsVisibleCatalogSubfilter)"></boolean-display>
			</template>
			<template v-slot:OstypeIsEnabled="list">
				<boolean-display :boolValue="Boolean(list.row.OstypeIsEnabled)"></boolean-display>
			</template>
			<template v-slot:CountryId="list">{{ formatCountry((list.row.CountryId))}}</template>
		</v-client-table>
		<sojourn-type-countries-modal
			@onClose="modalClosed"
			:entity="modalEntity"
			:isVisible="modalVisible"
			@onUpdate="modalUpdated"
			ref="sojourn-modal"
		></sojourn-type-countries-modal>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import loading from '../../../mixins/loading.mixin';
import imports from './sojourn-type-countries.imports';
import vue from 'vue';
import http from '@/services/helpers/http';
import BooleanDisplay from '@/components/common/boolean-display';

imports.importTableComponents(vue);

export default {
	name: 'sojournTypeCountries',

	components: { BooleanDisplay },

	mixins: [loading, abstractField],

	data() {
		return {
			modalVisible: false,
			modalEntity: null,
			columns: [
				'NumberRow',
				'CountryId',
				'OstypeOrder',
				'OstypeIsEnabled',
				'OstypeIsVisibleHomepage',
				'OstypeIsVisibleCatalogSubfilter',
				'Edit',
			],
			options: {
				filterable: false,
				filterByColumn: true,
				headings: {
					OstypeOrder: this.$t('order3254'),
					CountryId: this.$t('country2576'),
					OstypeIsEnabled: this.$t('active'),
					OstypeIsVisibleHomepage: this.$t('onhomepage'),
					OstypeIsVisibleCatalogSubfilter: this.$t('oncatalog'),
					NumberRow: this.$t('s.no.'),
					Edit: this.$t('actions'),
				},
			},
		};
	},
	methods: {
		formatCountry(id) {
			let values = this.$store.state.country.data;
			return values.find((obj) => obj.Id === id).Name;
		},
		editDetail(entity) {
			this.modalEntity = entity;
			this.modalVisible = true;
		},
		modalClosed() {
			this.modalVisible = false;
		},
		modalUpdated() {
			this.modalVisible = false;
			this.loadData();
		},
		async loadData() {
			let item = this.value[0];
			if (item) {
				let sojournTypeId = item.OstypeId;
				let resp = await http.get('ObjectSojournTypeCountry/GetAllForActiveCountries', { sojournTypeId });
				this.value = resp.data;
			}
		},
	},
};
</script>

<style lang="scss">
.field-sojourn-type-countries-table {
	.table-responsive {
		min-height: auto;
	}
	.VueTables--client {
		width: 100%;
	}
	.VuePagination {
		display: none;
	}
	@import '@/styles/components/detail-table.scss';
}
</style>
