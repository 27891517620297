import http from '@/services/helpers/http';

const dicApi = {
	getList(controllerName, useToken = true) {
		let data = {};
		return http.get(controllerName + '/List', data, useToken);
	},

	getProductListByCountry(countryId) {
		return http.get('Product/GetListByCountry', { countryId: countryId });
	},
	
	getPortalEmailList() {
		return http.get("PortalEmailTask/GetEmailsListForTask");
	},

};

export default dicApi;
