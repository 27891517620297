import { ServerTable, ClientTable } from 'vue-tables-2';
import errorHandler from '../services/error-handler.service';
import appModal from '../plugins.js';
import formatter from '../services/helpers/formatter';
import { filters } from '../services/helpers/formatter';
import Loading from 'vue-loading-overlay';

const importAdditionalPlugins = function(vue) {
	vue.use(errorHandler);
	vue.use(formatter.plugin);
	vue.use(filters);
	// vue.use(ServerTable, {}, true, require('../styles/theme/vuetable-theme'), { sortControl });
	vue.use(ServerTable, {}, true, 'bootstrap3');
	//vue.use(ServerTable, {}, true, 'bootstrap3', 'default');
	vue.use(ClientTable, {}, false, 'bootstrap3');
	vue.use(appModal);
	vue.use(Loading, {
		color: '#20a8d8',
		loader: 'dots',
		width: 80,
		height: 65,
		backgroundColor: '#ffffff',
		opacity: 0.0,
		zIndex: 999999999999999,
	});
};

export default importAdditionalPlugins;
