<template>
	<div class="label-container">
		<div class="break"></div>
		<div class="container" v-for="(groupOption, index) in this.schema.items" v-bind:key="index">
			<div class="item" v-for="(item, index) in groupOption" v-bind:key="index">
				<label
					><b>{{ item.id }}</b></label
				>
				<label>&nbsp;</label>
				<label> {{ item.value }}</label>
			</div>
			<div class="break"></div>
		</div>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
	props: {
		items: {
			type: Array,
			Default: [],
		},
	},
	mixins: [abstractField],
};
</script>

<style lang="scss">
.item {
	width: 100%;
}

.container {
	display: flex;
	flex-wrap: wrap;
}

.container > div {
	flex: 0 50%;
}
.break {
	flex-basis: 100%;
	height: 15px;
}
</style>
