// import BootstrapVue from "bootstrap-vue";
// Vue.use(BootstrapVue);

import { LinkPlugin } from 'bootstrap-vue/esm/components/link';
import { DropdownPlugin } from 'bootstrap-vue/esm/components/dropdown';
import { ModalPlugin } from 'bootstrap-vue/esm/components/modal';
import { CardPlugin } from 'bootstrap-vue/esm/components/card';
import { TooltipPlugin } from 'bootstrap-vue/esm/components/tooltip';
import { FormSelectPlugin } from 'bootstrap-vue/esm/components/form-select';
import { FormInputPlugin } from 'bootstrap-vue/esm/components/form-input';
import { FormGroupPlugin } from 'bootstrap-vue/esm/components/form-group';
import { FormRadioPlugin } from 'bootstrap-vue/esm/components/form-radio';
import { FormCheckboxPlugin } from 'bootstrap-vue/esm/components/form-checkbox';
import { FormTextareaPlugin } from 'bootstrap-vue/esm/components/form-textarea';
import { BadgePlugin } from 'bootstrap-vue/esm/components/badge';
import { ButtonPlugin } from 'bootstrap-vue/esm/components/button';
import { FormPlugin } from 'bootstrap-vue/esm/components/form';
import { LayoutPlugin } from 'bootstrap-vue/esm/components/layout';
import { CollapsePlugin } from 'bootstrap-vue/esm/components/collapse';
import { PopoverPlugin } from 'bootstrap-vue';
import { TabsPlugin } from 'bootstrap-vue/esm/components/tabs';

const importBootstrapVue = function(vue) {
	vue.use(LinkPlugin);
	vue.use(DropdownPlugin);
	vue.use(LayoutPlugin);
	vue.use(ModalPlugin);
	vue.use(CardPlugin);
	vue.use(TooltipPlugin);
	vue.use(FormSelectPlugin);
	vue.use(FormInputPlugin);
	vue.use(BadgePlugin);
	vue.use(ButtonPlugin);
	vue.use(FormPlugin);
	vue.use(FormGroupPlugin);
	vue.use(FormRadioPlugin);
	vue.use(FormCheckboxPlugin);
	vue.use(FormTextareaPlugin);
	vue.use(CollapsePlugin);
	vue.use(PopoverPlugin);
	vue.use(TabsPlugin);
};

export default importBootstrapVue;
