import apiDictionary from '../api/dictionary.api';
import utils from '../helpers/utils';
import serviceCommon from '../../services/service/common.service';
import { formatter } from '../../services/helpers/formatter';

const serviceDictionary = {
	getProductListFormGenerator(countryId) {
		return apiDictionary.getProductListByCountry(countryId).then((resp) => {
			return utils.convertItems(resp.data, function(item) {
				return {
					id: item.ProductId,
					name:
						item.ProductNameSk +
						' (' +
						formatter.currencyToString(item.ProductPriceAdmin, serviceCommon.getCurrencyCodeById(item.CurrencyId)) +
						')',
				};
			});
		});
	},

	getRegistrationRecommends() {
		return apiDictionary.getList('RegistrationRecommend').then((resp) => {
			let registrationRecommendSelectorItemsFormGenerator = utils.convertItems(resp.data, function(item) {
				return { id: item.RecommendId, name: item.RecommendNameSk };
			});
			let registrationRecommendSelectorItems = utils.convertItems(
				resp.data,
				function(item) {
					return { value: item.RecommendId, text: item.RecommendNameSk };
				},
				{ value: null, text: '' }
			);

			let registrationRecommendDict = utils.arrayToDict(resp.data, 'Id', [{ Id: 0, Name: '' }]);

			return {
				registrationRecommendSelectorItemsFormGenerator,
				registrationRecommendDict,
				registrationRecommendSelectorItems,
			};
		});
	},

	getAccountingCompanies() {
		return apiDictionary.getList('AccountingCompany').then((resp) => {
			let accountingCompanySelectorItems = utils.convertItems(
				resp.data,
				function(item) {
					return { value: item.AcompanyId, text: item.AcompanyName };
				},
				{ value: null, text: '' }
			);

			let accountingCompanyDict = utils.arrayToDict(resp.data, 'Id', [{ Id: 0, Name: '' }]);

			return {
				accountingCompanyDict,
				accountingCompanySelectorItems,
			};
		});
	},

	async getSeoGroupEnum() {
		var groupEnum = await apiDictionary.getList('SeoPageGroup');
		var vfgData = groupEnum.data;
		var seoGroupSelectorItems = utils.convertItems(
			groupEnum.data,
			function(item) {
				return { value: item.SpgroupId, text: item.SpgroupName };
			},
			{ value: null, text: '' }
		);
		var seoGroupDict = utils.convertItems(groupEnum.data, function(item) {
			return { Id: item.SpgroupId, Text: item.SpgroupName };
		});
		return {
			vfgData,
			seoGroupSelectorItems,
			seoGroupDict,
		};
	},

	async getAttractionGroupEnum() {
		var attractionEnum = await apiDictionary.getList('AttractionGroup');
		var dropdownVfg = attractionEnum.data;
		var attractionGroupSelectorItems = utils.convertItems(
			attractionEnum.data,
			function(item) {
				return { value: item.AgroupId, text: item.AgroupNameSk };
			},
			{ value: null, text: '' }
		);
		var attractionGroupDict = utils.convertItems(attractionEnum.data, function(item) {
			return { Id: item.AgroupId, Text: item.AgroupNameSk };
		});
		return {
			dropdownVfg,
			attractionGroupSelectorItems,
			attractionGroupDict,
		};
	},

	async getTerritoryEnum(countryId) {
		const territoryEnum = await apiDictionary.getList('Territory');
		if(countryId)
		{
			territoryEnum.data = territoryEnum.data.filter((item) => item.CountryId == countryId);
		}
		const territorySelectorItems = utils.convertItems(territoryEnum.data, function(item) {
			return {
				value: item.TerritoryId,
				text: utils.getPropValue(item, 'TerritoryName', item.CountryId),
				CountryId: item.CountryId,
			};
		});

		const territoryDict = utils.convertItems(territoryEnum.data, function(item) {
			return { Id: item.TerritoryId, Text: item.TerritoryNameSk };
		});

		return {
			territorySelectorItems,
			territoryDict,
		};
	},

	async getDistrictEnum(territoryId) {
		const districtEnum = await apiDictionary.getList('District');
		if(territoryId)
		{
			console.log('territory service');
			console.log(territoryId)
			districtEnum.data = districtEnum.data.filter((item) => item.TerritoryId == territoryId);
		};

		const districtSelectorItems = utils.convertItems(districtEnum.data, function(item) {
			return {
				value: item.DistrictId,
				text: utils.getPropValue(item, 'DistrictName', item.CountryId),
				CountryId: item.CountryId,
			};
		});

		const districtDict = utils.convertItems(districtEnum.data, function(item) {
			return { Id: item.DistrictId, Text: item.DistrictNameSk };
		});

		return {
			districtSelectorItems,
			districtDict,
		};
	},

	async getDictionaryTextGroupsEnum() {
		var groups = await apiDictionary.getList('DictionaryTextGroup');
		var dropdownVfg = groups.data;
		var GroupSelectorItems = utils.convertItems(
			groups.data,
			function(item) {
				return { value: item.DtgroupId, text: item.DtgroupName };
			},
			{ value: null, text: '' }
		);
		var GroupDict = utils.convertItems(groups.data, function(item) {
			return { Id: item.DtgroupId, Text: item.DtgroupName };
		});
		GroupDict = utils.arrayToDict(GroupDict, 'Id');

		return {
			dropdownVfg,
			GroupSelectorItems,
			GroupDict,
		};
	},
};

export default serviceDictionary;
