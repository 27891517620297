var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("invoice-link", {
    attrs: {
      iNumber: this.value.iType == 2 ? this.value.iNumber : "",
      iCode: this.value.iType == 2 ? this.value.iCode : "",
      iPFNumber: this.value.iType == 1 ? this.value.iNumber : "",
      iPFCode: this.value.iType == 1 ? this.value.iCode : "",
      iType: this.value.iType,
      wrapperClass: this.invoiceClass(),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }