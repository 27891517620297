<template>
	<div>
		<b-card-title class="mt-4">
			{{ title }}
			<a v-if="showLinkToObjectsOnGMap" :href="linkToObjectsOnGMap" target="_blank">{{ $t('objectsonthemap') }}</a>
		</b-card-title>
		<v-server-table
			v-show="showTable"
			:name="tableName"
			:columns="columns"
			:options="tableOptions"
			:ref="tableName"
			class="detail-table"
			:key="this.tableKey"
		>
			<template v-slot:NumberRow="list">{{ list.index }}.</template>

			<template v-slot:CountryId="list">
				<country-flag :countryId="list.row.CountryId"></country-flag>
			</template>
		</v-server-table>
	</div>
</template>

<script>
import vars from '../../services/helpers/variables';
import serviceCommon from '../../services/service/common.service';
import http from '../../services/helpers/http';
import i18n from '../../services/lang';
import { mapState } from 'vuex';

export default {
	props: {
		tableName: { type: String, required: true },
		locationId: { type: Number, required: true },
		locationQueryName: {
			type: String,
			required: true,
			validator: (val) => ['RegionId', 'DistrictId', 'TerritoryId'].includes(val),
		},
		domainUrl: { type: String },
		title: { type: String },
	},
	data() {
		return {
			tableKey: 0,
			showTable: false,
			controllerName: 'CitySubListItem',
			columns: ['NumberRow', 'CityName', 'CityDistrictName', 'CityTerritoryName', 'CountryId', 'CityZip'],
		};
	},
	computed: {
		showLinkToObjectsOnGMap() {
			return this.locationQueryName === 'RegionId';
		},

		getFilter() {
			return { [this.locationQueryName]: this.locationId };
		},

		tableOptions() {
			return {
				preserveState: true,
				orderBy: { column: 'CityName', ascending: 1 },
				columns: 'Cols',
				pagination: vars.tableVars.paginationSetting,
				perPage: vars.tableVars.defaultPerPage,
				sortIcon: vars.tableVars.sortIcons,
				texts: serviceCommon.recordsPaginationsTexts(),
				filterable: false,
				headings: {
					NumberRow: i18n.t('s.no.'),
					CityName: i18n.t('city3270'),
					CityDistrictName: i18n.t('district'),
					CityTerritoryName: i18n.t('county'),
					CountryId: i18n.t('country2576'),
					CityZip: i18n.t('zip2577'),
				},
				requestFunction: (data) => {
					data['filter'] = this.getFilter;

					var info = {
						data: data,
						controller: this.controllerName,
						tableName: this.tableName,
						onLoaded: () => {
							this.showTable = true;
						},
					};

					return serviceCommon.getListForTable(info);
				},
			};
		},
		linkToObjectsOnGMap() {
			return this.domainUrl + 'Home/GetOldMap?locationId=' + this.locationId + '&locationTypeId=0';
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/components/detail-table.scss';
</style>
