<template>
	<b-modal
		modal-class="invoice-address-modal"
		size="lg"
		v-model="showModal"
		centered
		:title="$t('billingaddress2448')"
		@hidden="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
	>
		<vue-form-generator
			:schema="schemaInvAddress"
			:model="modelInvAddress"
			:options="formOptionsInvAddress"
			ref="detailForm"
		></vue-form-generator>

		<template v-slot:modal-footer="{}">
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechangesandn')"
				@handleClick="updateInvoice()"
			></megau-button>
			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('closewithoutsav2459')"
				@handleClick="close()"
			></megau-button>
		</template>
	</b-modal>
</template>

<script>
import vue from 'vue';

import serviceEnums from '../../../services/service/enums.service';
import serviceDictionary from '../../../services/service/dictionary.service';
import apiCommon from '../../../services/api/common.api';
import http from '../../../services/helpers/http';

import service from './invoice-address-invoice.service';

import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);

export default {
	props: {
		isVisible: { type: Boolean, default: false },
		modelInvAddress: { type: Object, default: {} },
		schemaInvAddress: { type: Object, default: {} },
		formOptionsInvAddress: { type: Object, default: {} },
	},
	data() {
		return {
			controllerName: 'InvoiceAddress',
			showModal: false,
		};
	},
	computed: {
		detailTitle() {
			return (
				this.$i18n.t('detail fakturacnej adresy preklad pre fa') +
				' ' +
				this.$i18n.t('id') +
				' ' +
				this.modelDetail.InvoiceId
			);
		},
		language: function() {
			return this.$i18n.locale;
		},
	},
	created() {
		var res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
	},
	watch: {
		language: function() {
			service.formTranslations(this.schemaInvAddress);
		},
		isVisible: function() {
			this.showModal = this.isVisible;
		},
	},
	methods: {
		close() {
			this.showModal = false;
			this.$emit('onClose');
		},

		async updateInvoice() {
			var validateResult = this.$refs.detailForm.validate();
			validateResult.then((value) => {
				if (value.length == 0) {
					var update = http.put('InvoiceAddress/UpdateByInvoiceId', this.modelInvAddress).then(() => {
						this.close();
						this.$emit('onChanged');
					});
				}
			});
		},
	},
};
</script>
