import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { en } from './locale/en';
import { sk } from './locale/sk';

Vue.use(VueI18n);

const locale = 'sk';
const messages = {
	en: en,
	sk: sk,
};
const i18n = new VueI18n({
	locale,
	messages,
});

export default i18n;
