var sendLogsTypeEnum = {
	Unknown: 0,
	Common: 1,
	Contact: 2,
	LodgingObject: 3,
	Invoice: 4,
	Backlink: 5,
};

var invoiceType = {
	Proforma: 1,
	Invoice: 2,
};

var role = {
	Admin: 1,
	User: 2,
	Operator: 3,
	CopyWriterJunior: 4,
	TeamLeader: 5,
	Owner: 6,
	CopyWriterSenior: 7,
};

var invoiceState = {
	Unpaid: 1,
	Paid: 2,
	Canceled: 3,
};

var remarkEntityType = {
	Dashboard: 0,
	Contact: 1,
	Object: 2,
	Invoice: 3,
	Backlink: 4,
};

var remarkType = {
	Default: 1,
	Accountant: 2,
};

var country = {
	SK: 1,
	CZ: 2,
	PL: 3,
	RU: 4,
	HU: 112,
	EN: 177,
	DE: 126,
};

var objectCondition = {
	verified: 1,
	notVerified: 2,
	objectState: 3,
};

var simplePropertiesTypes = {
	ObjectLocations: 1,
	LocationDetails: 2,
	AccommodationSuitableForMultipleGuestsGroups: 4,
	ObjectOperationgSeasons: 5,
	OwnerSpeaksLanguages: 6,
	OwnerLivesInObject: 7,
	Animals: 8,
	RoomsEquipment: 9,
	Toilets: 10,
	Bathrooms: 11,
	DayroomEquipment: 12,
	KitchenEquipment: 13,
	Food: 14,
	PublicAreas: 15,
	Children: 16,
	Sport: 17,
	WellnessAndRelax: 18,
	Agrotourism: 19,
	Congres: 20,
	NearObject: 21,
	AdditionalServices: 22,
	Heating: 23,
	Smoking: 24,
	MobilePhoneSignal: 25,
	Internet: 26,
	BarrierFree: 27,
	SportActivitiesAround: 28,
	FoodIncludedInPrice: 29,
	Preferred: 31,
	NotAccepted: 32,
	Fence: 33,
	ParkingDistance: 34,
	ParkingOptions: 35,
	BathroomEquipment: 36,
};

var suitableFor = {
	Persons: 1,
	Events: 2,
};

var portalJobRunResults = {
	Running: 1,
	Success: 2,
	Failed: 3,
	Fixed: 4,
};

var reservationOccupancyStatus = {
	Initial: 0,
	Confirm: 1,
	Cancel: 2,
};

var objectReservationType = {
	reservation: 1,
	question: 2,
};

var productSet = {
	StandardSet: 1,
	TopSet: 2,
	ReInvoiceSet: 3,
	AdditionalTopSet: 4,
	LastminuteSet: 5,
	AdditionalSectionSet: 6,
};

var reservationCreatedBy = {
	Visitor: 1,
	Owner: 2,
	VisitorViaWidget: 3,
};

var statisticType = {
	Invoice: 'invoices',
};

var LocationTypeEnum = {
	Region: 0,
	City: 1,
	District: 2,
	Attraction: 3,
	Territory: 4,
	SojournType: 5,
	CatalogSection: 6,
	Unknown: 7,
};

export default {
	productSet,
	sendLogsTypeEnum,
	invoiceType,
	remarkEntityType,
	invoiceState,
	remarkType,
	simplePropertiesTypes,
	suitableFor,
	country,
	objectCondition,
	role,
	portalJobRunResults,
	reservationOccupancyStatus,
	objectReservationType,
	reservationCreatedBy,
	statisticType,
	LocationTypeEnum,
};
