const esc = encodeURIComponent;

function tableFiltersConvertToGetQuery(items, propName) {
	var result = '';
	for (var key in items) {
		if (typeof items[key] == 'object' && items[key] != null) {
			Object.keys(items[key]).forEach(function(index) {
				if (items[key][index] != null) {
					var value = _.isDate(items[key][index])
						? items[key][index].toJSON()
						: items[key][index];
					result += '&filter[' + key + '_' + index + ']=' + value;
				}
			});
		} else if (
			items[key] != null &&
			typeof items[key] !== 'undefined' &&
			items[key] !== ''
		) {
			result += '&filter[' + key + ']=' + items[key];
		}
	}
	return result;
}

const qs = {
	toUrl(params) {
		return Object.keys(params)
			.map(k => esc(k) + '=' + esc(params[k]))
			.join('&');
	},

	toFilterUrl(params) {
		var filter = params.filter;
		delete params.filter;

		var result = qs.toUrl(params);
		result += tableFiltersConvertToGetQuery(filter, 'filter');

		return result;
	},
};

export default qs;
