var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "sojourn-type-countries-modal",
        size: "lg",
        centered: "",
        title: _vm.$t("editthesojournt"),
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-enforce-focus": "",
        "body-class": "no-scrollable",
      },
      on: {
        hidden: function ($event) {
          return _vm.close()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-success",
                  title: _vm.$t("savechangesandn"),
                  icon: "check",
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.update()
                  },
                },
              }),
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-primary",
                  icon: "undo",
                  title: _vm.$t("closewithoutsav2459"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c("vue-form-generator", {
        ref: "detailForm",
        attrs: {
          schema: this.schema,
          model: this.model,
          options: this.formOptions,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }