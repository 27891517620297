var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between flex-wrap align-items-center",
        },
        [
          _vm.showTitle
            ? _c("b-card-title", { staticClass: "mt-4" }, [
                _vm._v(_vm._s(_vm.$t("orderedpackages"))),
              ])
            : _vm._e(),
          _vm.editable
            ? _c("megau-button", {
                attrs: {
                  classprop: "btn btn-success",
                  icon: "plus",
                  titlePosition: "right",
                  title: _vm.$t("addanewpackage"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.editOrInsertDetail(0)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-server-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTable,
            expression: "showTable",
          },
        ],
        key: _vm.tableKey,
        ref: "productsTable",
        staticClass: "detail-table",
        attrs: {
          name: "productsTable",
          columns: _vm.columns,
          options: _vm.tableOptions,
        },
        scopedSlots: _vm._u([
          {
            key: "NumberRow",
            fn: function (list) {
              return [_vm._v(_vm._s(list.index) + ".")]
            },
          },
          {
            key: "DateStart",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm._f("date")(list.row.DateStart)))]
            },
          },
          {
            key: "DateEnd",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm._f("date")(list.row.DateEnd)))]
            },
          },
          {
            key: "PriceTotal",
            fn: function (list) {
              return [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("currency")(
                        list.row.PriceTotal,
                        _vm.getCurrencyById(list.row.CurrencyId)
                      )
                    ) +
                    "\n\t\t"
                ),
              ]
            },
          },
          {
            key: "Vat",
            fn: function (list) {
              return [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("currency")(
                        list.row.Vat,
                        _vm.getCurrencyById(list.row.CurrencyId)
                      )
                    ) +
                    "\n\t\t"
                ),
              ]
            },
          },
          {
            key: "Price",
            fn: function (list) {
              return [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("currency")(
                        list.row.Price,
                        _vm.getCurrencyById(list.row.CurrencyId)
                      )
                    ) +
                    "\n\t\t"
                ),
              ]
            },
          },
          {
            key: "DatePayment",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm.formatDatePayment(list.row)))]
            },
          },
          {
            key: "INumber",
            fn: function (list) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("invoice-link", {
                      attrs: {
                        iNumber: list.row.INumber,
                        iPFNumber: list.row.IPFNumber,
                        iCode: list.row.ICode,
                        iPFCode: list.row.IPFCode,
                        iType: list.row.InvoiceType,
                        rowMarked: list.row.Marked,
                        wrapperClass: _vm.invoiceClass(list.row),
                      },
                    }),
                    _c("invoice-note-for-products", {
                      attrs: {
                        invoiceId: list.row.InvoiceId,
                        invoiceNote: list.row.InvoiceNote,
                        refreshMethod: _vm.refresh,
                        editable: _vm.editable,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "InvoiceVariableSymbol",
            fn: function (list) {
              return [
                _vm._v(
                  _vm._s(
                    _vm.formatVariableSymbol(list.row.InvoiceVariableSymbol)
                  )
                ),
              ]
            },
          },
          {
            key: "Edit",
            fn: function (list) {
              return [
                !_vm.invoiceId || list.row.Marked
                  ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.left",
                              modifiers: { hover: true, left: true },
                            },
                          ],
                          attrs: { title: _vm.$t("editpackage") },
                        },
                        [
                          _c("font-awesome-icon", {
                            class: list.row.Marked
                              ? "higlighted-link"
                              : "detail-table-icon",
                            attrs: {
                              icon: "edit",
                              cursor: "pointer",
                              size: "lg",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editOrInsertDetail(list.row.Id)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }