var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "chpwd-buttons" },
        [
          _c("megau-button", {
            attrs: {
              classprop: "btn btn-secondary btn-chPwd",
              icon: "unlock-alt",
              title: _vm.$t("changepassword"),
            },
            on: { handleClick: _vm.changeVisible },
          }),
          _c("megau-button", {
            attrs: {
              classprop: "btn btn-secondary btn-resetAlc",
              icon: "sync-alt",
              title: _vm.$t("resetautolog"),
            },
            on: { handleClick: _vm.resetAutologCode },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            visible: _vm.visible,
            centered: "",
            title: _vm.$t("changepassword2361"),
            "no-close-on-backdrop": "",
            "no-enforce-focus": "",
          },
          on: {
            hide: function ($event) {
              return _vm.close()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("changepassword2365"),
                    },
                    on: { handleClick: _vm.change },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-primary",
                      icon: "undo",
                      title: _vm.$t("closewithoutcha"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.close()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("vue-form-generator", {
            ref: "chPwdVfg",
            attrs: {
              schema: _vm.chPwdschema,
              model: _vm.chPwdmodel,
              options: _vm.chPwdformOptions,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }