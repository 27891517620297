// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
// import cssVars from 'css-vars-ponyfill'
import './js-extensions.js';

import Vue from 'vue';

// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import importBootstrapVue from './startup/bootstrap.imports';
import importAdditionalPlugins from './startup/additional.imports';
import './startup/fontawesome.imports';

import App from './App';
import router from './router';
import store from './store';
import i18n from './services/lang';

import moment from 'moment';
moment.fn.toJSON = function() {
	return this.toDate().toJSON();
};

import auth from './services/auth.service.js';
auth.StartUpSetup();

importBootstrapVue(Vue);
importAdditionalPlugins(Vue);

import { componentsRegister } from './startup/components.register.js';
componentsRegister(Vue);

/* eslint-disable no-new */
new Vue({
	router,
	store,
	el: '#app',
	i18n,
	template: '<App/>',
	components: {
		App,
	},
});
