var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "active-filters-wrapper" },
    [
      _vm._l(_vm.activeFiltersAction, function (filter, key) {
        return _c("span", { key: key }, [
          _vm._v(_vm._s(filter.label) + ": " + _vm._s(filter.value)),
        ])
      }),
      _c("megau-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showButton,
            expression: "showButton",
          },
        ],
        attrs: {
          title: _vm.$t("cancelallfilter"),
          classprop: "btn btn-customdanger",
          icon: "times-circle",
        },
        on: {
          handleClick: function ($event) {
            return _vm.removeAllFilters()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }