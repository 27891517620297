var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-title", { staticClass: "mt-4" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t\t"),
        _vm.showLinkToObjectsOnGMap
          ? _c(
              "a",
              { attrs: { href: _vm.linkToObjectsOnGMap, target: "_blank" } },
              [_vm._v(_vm._s(_vm.$t("objectsonthemap")))]
            )
          : _vm._e(),
      ]),
      _c("v-server-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTable,
            expression: "showTable",
          },
        ],
        key: this.tableKey,
        ref: _vm.tableName,
        staticClass: "detail-table",
        attrs: {
          name: _vm.tableName,
          columns: _vm.columns,
          options: _vm.tableOptions,
        },
        scopedSlots: _vm._u([
          {
            key: "NumberRow",
            fn: function (list) {
              return [_vm._v(_vm._s(list.index) + ".")]
            },
          },
          {
            key: "CountryId",
            fn: function (list) {
              return [
                _c("country-flag", {
                  attrs: { countryId: list.row.CountryId },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }