var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("megau-button", {
        attrs: {
          classprop: "btn",
          titlePosition: "none",
          title: _vm.buttonTitle,
        },
        on: {
          handleClick: function ($event) {
            return _vm.openModal()
          },
        },
      }),
      _c("invoice-address-invoice-detail", {
        attrs: {
          isVisible: _vm.isVisibleDetail,
          modelInvAddress: _vm.value,
          schemaInvAddress: _vm.schemaInvAddress,
          formOptionsInvAddress: _vm.formOptionsInvAddress,
        },
        on: { onClose: _vm.closeDetail, onChanged: _vm.onChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }