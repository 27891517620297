<template>
	<div>
		<div class="map-title-wrapper">
			<label class="map-title">{{ $t('locationonthema') }}</label>
			<gmap-autocomplete
				id="searchTextField"
				:placeholder="$t('enterlocation')"
				@place_changed="setPlace"
			></gmap-autocomplete>
			<label>&nbsp; &nbsp;</label>
			<button class="btn btn-primary" @click="addMarker">{{ $t('find') }}</button>
			<br />
		</div>
		<br />
		<gmap-map class="google-map" :center="center" ref="map" :zoom="mapZoom" @zoom_changed="zoomChanged"></gmap-map>
		<megau-button
			v-if="boundariesEnabled"
			classprop="btn btn-customdanger google-map-btn"
			icon="trash-alt"
			id="delete-button"
			:tooltip="$t('deletepolygon')"
			@handleClick="deletePolygon"
		></megau-button>
	</div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps';
import vue from 'vue';

vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAP_KEY,
		libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
	},
});

export default {
	props: {
		zoom: { type: Number },
		latitude: { type: Number },
		longitude: { type: Number },
		boundaries: { type: String },
		boundariesEnabled: { type: Boolean, default: false },
		onChanged: { type: Function },
		height: { type: String, default: '600' },
	},
	name: 'GoogleMap',
	data() {
		return {
			center: {
				lat: this.latitude == 0 ? 48.736279 : this.latitude,
				lng: this.longitude == 0 ? 19.146191 : this.longitude,
			},
			markers: [],
			places: [],
			currentPlace: null,
			currentPolygon: null,
			currentMarker: null,
			infoWindow: null,
			mapZoom: this.zoom == 0 ? 10 : this.zoom,
			geocoder: null,
			polygonOptions: this.boundaries
				? {
						paths: JSON.parse(this.boundaries),
						fillColor: 'blue',
						fillOpacity: '0.2',
						strokeOpacity: 0.8,
						strokeWeight: 1,
						editable: true,
				  }
				: null,
		};
	},

	mounted() {
		document.querySelector('body').style.setProperty('--height', this.height + 'px');

		this.$refs.map.$mapPromise.then((map) => {
			this.$refs.map.$gmapApiPromiseLazy().then(() => {
				if (this.boundariesEnabled) {
					this.loadMapDrawingManager();
				}
				this.geocoder = new google.maps.Geocoder();
				if (this.center.lat && this.center.lng) {
					this.paintData();
				}
			});
		});
	},

	methods: {
		paintData() {
			this.createMarker(this.center.lat, this.center.lng);
			this.createMarkerInfowindow();
			if (this.boundariesEnabled) {
				this.createBoundaries();
			}
		},
		createMarker(lat, lng) {
			if (this.currentMarker) {
				this.currentMarker.setMap(null);
			}
			this.currentMarker = new google.maps.Marker({
				position: new google.maps.LatLng(lat, lng),
				map: this.$refs.map.$mapObject,
				draggable: true,
			});
			this.currentMarker.addListener('dragend', this.dragend);
		},
		createMarkerInfowindow() {
			this.infoWindow = new google.maps.InfoWindow({});
			this.setInfoWindowContent();
			google.maps.event.addListener(this.currentMarker, 'mouseover', () => {
				this.infoWindow.open(this.$refs.map.$mapObject, this.currentMarker);
			});
		},
		createBoundaries() {
			if (this.boundaries) {
				this.currentPolygon = new google.maps.Polygon(this.polygonOptions);
				this.addMovedEvents();
				this.currentPolygon.setMap(this.$refs.map.$mapObject);
			}
		},
		toDegreesMinutesAndSeconds(coordinate) {
			var absolute = Math.abs(coordinate);
			var degrees = Math.floor(absolute);
			var minutesNotTruncated = (absolute - degrees) * 60;
			var minutes = Math.floor(minutesNotTruncated);
			var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

			return degrees + '°' + minutes + "'" + seconds + "''";
		},

		convertDMS(lat, lng) {
			var latitude = this.toDegreesMinutesAndSeconds(lat);
			var latitudeCardinal = lat >= 0 ? 'N' : 'S';

			var longitude = this.toDegreesMinutesAndSeconds(lng);
			var longitudeCardinal = lng >= 0 ? 'E' : 'W';

			return latitude + '' + latitudeCardinal + '\n' + longitude + ' ' + longitudeCardinal;
		},
		polygonChanged() {
			let mvcArray = this.currentPolygon.getPaths();
			let paths = [];
			for (let j = 0; j < mvcArray.getAt(0).getLength(); j++) {
				let point = mvcArray.getAt(0).getAt(j);
				paths.push({ lat: point.lat(), lng: point.lng() });
			}

			this.onChanged(null, null, null, [paths]);
		},
		loadMapDrawingManager() {
			let self = this;
			const drawingManager = new google.maps.drawing.DrawingManager({
				drawingControl: true,
				drawingControlOptions: {
					position: google.maps.ControlPosition.TOP_CENTER,
					drawingModes: [google.maps.drawing.OverlayType.POLYGON],
				},
				polygonOptions: {
					fillColor: 'blue',
					fillOpacity: 0.2,
					strokeOpacity: 0.8,
					strokeWeight: 1,
					editable: true,
				},
			});
			drawingManager.setMap(this.$refs.map.$mapObject);
			this.$refs.map.$mapObject.controls[google.maps.ControlPosition.TOP_CENTER].push(
				document.getElementById('delete-button')
			);

			google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
				let paths = [];
				let mvcArray = event.overlay.getPath();
				for (let i = 0; i < mvcArray.length; i++) {
					let p = mvcArray.getAt(i);
					paths.push({ lat: p.lat(), lng: p.lng() });
				}

				self.onChanged(null, null, null, [paths]);
				self.currentPolygon = event.overlay;
				self.addMovedEvents(self.currentPolygon);
			});
		},
		deletePolygon() {
			this.currentPolygon.setMap(null);
			this.onChanged(null, null, null, []);
		},
		async setInfoWindowContent() {
			let _this = this;
			let pos = new google.maps.LatLng(this.center.lat, this.center.lng);
			await this.geocoder.geocode({ location: pos }, (results, status) => {
				if (status === 'OK') {
					if (results[0]) {
						this.infoWindow.setContent(
							'<h6>' +
								results[0].formatted_address +
								'</h6><br /><b>' +
								this.convertDMS(this.center.lat, this.center.lng) +
								'</b><br />' +
								this.center.lat +
								', ' +
								this.center.lng
						);
					}
				} else {
					this.infoWindow.setContent(
						this.convertDMS(this.center.lat, this.center.lng) + '\n' + this.center.lat + ' ' + this.center.lng
					);
				}
			});
		},

		dragend(marker) {
			var latLng = marker.latLng;
			this.center = {
				lat: latLng.lat(),
				lng: latLng.lng(),
			};
			this.setInfoWindowContent();
			this.onChanged(this.center.lat, this.center.lng, this.mapZoom, null);
		},
		setPlace(place) {
			this.currentPlace = place;
			this.createMarker(this.currentPlace.geometry.location.lat(), this.currentPlace.geometry.location.lng());
			this.onChanged(this.currentPlace.geometry.location.lat(), this.currentPlace.geometry.location.lng(), null, null);
		},
		zoomChanged(value) {
			this.mapZoom = value;
			this.onChanged(null, null, value, null);
		},
		addMovedEvents() {
			this.currentPolygon.getPaths().forEach((path, index) => {
				google.maps.event.addListener(path, 'insert_at', this.polygonChanged);
				google.maps.event.addListener(path, 'remove_at', this.polygonChanged);
				google.maps.event.addListener(path, 'set_at', this.polygonChanged);
			});

			google.maps.event.addListener(this.currentPolygon, 'dragend', this.polygonChanged);
		},
		addMarker() {
			if (this.currentPlace) {
				const marker = {
					lat: this.currentPlace.geometry.location.lat(),
					lng: this.currentPlace.geometry.location.lng(),
				};
				this.markers = [];
				this.markers.push({ position: marker });
				this.places = [];
				this.places.push(this.currentPlace);
				this.center = marker;
				this.currentPlace = null;
			}
		},
		geolocate: function() {
			navigator.geolocation.getCurrentPosition((position) => {
				this.center = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};
			});
		},
	},
};
</script>

<style lang="scss">
:root {
	--height: 600px;
}
.google-map {
	width: 100%;
	height: var(--height);
}

.map-title-wrapper {
	display: flex;
	align-items: center;
	.map-title {
		width: $formLabelWidth;
	}

	label:nth-child(1):after
	{
	content: '*';
    font-weight: 400;
    color: var(--danger);
    padding-left: 0.2em;
    font-size: 1em;
	}

	> input {
		display: block;
		padding: 6px 12px;
		font-size: 12px;
		line-height: 1.42857143;
		width: 25%;
		color: #555;
		background-color: var(--white);
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	}
}

.google-map-btn {
	margin-top: 2px;
	transform: scale(0.8);
}
</style>
