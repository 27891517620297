<template>
	<div id="perPageSelect" class="d-flex align-items-center">
		<!-- <span class="title pr-2">{{ $t('recordcount') }}:</span> -->
		<b-form-select @change="handleChange" v-model="perPageLimit" :options="initOptions"></b-form-select>
	</div>
</template>

<script>
import serviceEnums from '../../services/service/enums.service';
import store from '../../store';

export default {
	props: {
		tableName: { type: String },
	},
	data() {
		return {
			perPageLimit: store.state[this.tableName].limit,
			initOptions: [],
		};
	},
	mounted() {
		this.getPerPageEnum();
	},
	methods: {
		getPerPageEnum() {
			this.initOptions = serviceEnums.getEnumForDropdown('itemsPerPage');
		},
		handleChange(currValue) {
			var tableLink = this.tableName + '/SET_LIMIT';
			this.$store.commit(tableLink, currValue);
		},
	},
};
</script>
<style scoped>
.title {
	white-space: nowrap;
}
</style>
