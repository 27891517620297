<template>
	<div class="wrapper">
		<!-- Modal Component -->
		<b-modal
			v-model="visible"
			:title="title"
			:class="modalClass"
			:ok-variant="variant"
			centered
			:cancel-title="cancelTitle"
			:ok-title="okTitle"
			@ok="confirm()"
			@cancel="hide()"
		>
			<template slot="default">{{text}}</template>
		</b-modal>
	</div>
</template>

<script>
import Modal from '../../plugins.js';

export default {
	data() {
		return {
			visible: false,
			title: '',
			text: Object,
			modalClass: '',
			variant: '',
			onConfirm: null,
			onCancel: null,
			okTitle: this.$t('ok'),
			cancelTitle: this.$t('cancel'),
		};
	},
	methods: {
		hide() {
			if (typeof this.onCancel === 'function') {
				this.onCancel();
			}
			this.visible = false;
		},
		confirm() {
			if (typeof this.onConfirm === 'function') {
				this.onConfirm();
				this.visible = false;
			} else {
				this.visible = false;
			}
		},
		show(params) {
			this.visible = true;
			this.title = params.title;
			this.onCancel = params.onCancel;
			this.text = params.text;
			(this.modalClass = params.modalClass), (this.variant = params.variant);
			this.onConfirm = params.onConfirm;
			this.okTitle = params.okTitle ? params.okTitle : this.$t('ok');
			this.cancelTitle = params.cancelTitle ? params.cancelTitle : this.$t('cancel');
		},
	},
	beforeMount() {
		Modal.EventBus.$on('show', (params) => {
			this.show(params);
		});
	},
};
</script>
