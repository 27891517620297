<template>
<div class="d-flex align-items-center">
	<input
		class="formatedInput"
		:value="formatedValue"
		v-format-with-comma
		v-on:input="handleInput"
		:data-position="position"
		:data-prev-value="prevValue"
		@blur="onBlurHandler"
	/>
	<label class="currency-symbol" >{{ lblCurrency }}</label>
</div>
</template>

<script>
import formatNumber from 'accounting-js/lib/formatNumber';
import unformat from 'accounting-js/lib/unformat';
import { abstractField } from 'vue-form-generator';
import serviceCommon from '../../services/service/common.service';
import { Script } from 'vm';

export default {
	mixins: [abstractField],
	props: {
		currency: { type: Function }
	},
	mounted(){
		this.lblCurrency = this.model.CurrencyId > 0 ? serviceCommon.getCurrencySymbolById(this.model.CurrencyId) : '';
	},
	data() {
		return {
			formatedValue: this.processFormatting(this.value, this.formatOptions),
			prevValue: '',
			formatOptions: { thousand: ' ', precision: 2 },
			position: 0,
			lblCurrency:''
		};
	},
	directives: {
		formatWithComma: {
			update(e) {
				let positionDiff = 0;
				if (e.dataset.prevValue.length === e.value.length - 1) {
					positionDiff = 1;
				}
				if (e.dataset.prevValue.length === e.value.length + 1) {
					positionDiff = -1;
				}
				if (e.selectionEnd !== e.dataset.position) {
					e.selectionEnd = Number(e.dataset.position) + positionDiff;
				}
			},
		},
	},
	watch: {
		value() {
			this.formatedValue = this.processFormatting(this.value, this.formatOptions);
			this.lblCurrency = this.model.CurrencyId > 0 ? serviceCommon.getCurrencySymbolById(this.model.CurrencyId) : '';
		},
	},
	methods: {
		onBlurHandler(e) {
			this.$emit('blur', e);
		},
		handleInput(e) {
			this.prevValue = e.target.value;
			let targetValue = unformat(e.target.value);
			this.position = e.target.selectionStart;
			this.formatedValue = formatNumber(targetValue, this.formatOptions);
			this.value = targetValue;
			this.$emit('input', Number(targetValue));
		},
		processFormatting(value, formatOptions) {
			if (!value) {
				return 0;
			}
			if (typeof value === 'string' && value.indexOf(',') >= 0) {
				return value;
			}
			return formatNumber(value, formatOptions);
		},
	},
};
</script>

<style lang="scss">
.currency-symbol{
	margin: 5px;
}
.formatedInput {
	display: block;
	padding: 6px 12px;
	font-size: 12px;
	width: 235px;
	line-height: 1.42857143;
	color: #555;
	background-color: var(--white);
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>
