import vueFormGenerator from 'vue-form-generator';
import validator from '../../../services/helpers/validator.common';

function fields(i18n) {
	return [
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('companyname'),
			model: 'InvaddrName',
			required: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('streetandnumber'),
			model: 'InvaddrStreet',
			required: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'autocomplete',
			label: i18n.t('city'),
			mode: 'text',
			required: true,
			model: 'InvaddrCity',
			typeSearch: '1',
			validator: validator.checkEmpty,
			onCityFromListSet(zip) {
				this.model.InvaddrZip = zip;
			},
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('zip'),
			model: 'InvaddrZip',
			required: true,
			validator: vueFormGenerator.validators.string,
		},

		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('companyid'),
			model: 'InvaddrIco',
			required: false,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('taxid'),
			model: 'InvaddrDic',
			required: false,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('vatid'),
			model: 'InvaddrIcdph',
			required: false,
			validator: vueFormGenerator.validators.string,
		},
	];
}

export default fields;
