import apiDictionary from '../../services/api/dictionary.api';
import utils from '../../services/helpers/utils';
import http from '@/services/helpers/http';

const service = {
	getSalutationEnum(i18n, store) {
		return apiDictionary
			.getList('Salutation')
			.then((resp) => {
				let locale = store.state.lang.locale;
				let responseDict = utils.convertItems(resp.data, function(item) {
					let val =
						locale === 'cs-CZ'
							? item.SalutationNameCz
							: locale === 'sk-SK'
							? item.SalutationNameSk
							: item.SalutationNameEn;
					return {
						Id: item.SalutationId,
						Text: val,
					};
				});
				let responseSelector = utils.convertItems(
					resp.data,
					function(item) {
						let val =
							locale === 'cs-CZ'
								? item.SalutationNameCz
								: locale === 'sk-SK'
								? item.SalutationNameSk
								: item.SalutationNameEn;
						return {
							value: item.SalutationId,
							text: val,
						};
					},
					{ value: null, text: '' }
				);
				responseSelector.push({
					value: -1,
					text: i18n.t('withoutsalutati'),
				});

				return { responseDict, responseSelector };
			})
			.then((resp) => {
				let dict = utils.arrayToDict(resp.responseDict, 'Id', [{ Id: 0, Text: '' }]);
				let czechEmailSalutationId = 3;
				let selector = resp.responseSelector.filter((item) => item.value !== czechEmailSalutationId);
				return { dict, selector };
			});
	},

	getObjectTypeEnum(store) {
		return apiDictionary.getList('ObjectType').then((resp) => {
			let responseSelector = utils.convertItems(resp.data, function(item) {
				let locale = store.state.lang.locale;
				let val = locale === 'cs-CZ' ? item.OtypeNameCz : locale === 'sk-SK' ? item.OtypeNameSk : item.OtypeNameEn;
				return { value: item.OtypeId, text: val };
			});

			return { responseSelector };
		});
	},

	setContactPassword(contactId, password) {
		return http.post('Contact/SetContactPassword', { contactId, password });
	},

	resetAutologCode(contactId) {
		return http.post('Contact/ResetAutologCode/' + contactId);
	},

	formatFullName(row) {
		if (row.ContactSurname != '' || row.ContactSurnameEmail != '') {
			return row.ContactName + ' ' + row.ContactSurname + ' / ' + row.ContactSurnameEmail;
		} else {
			return row.ContactName;
		}
	},

	formatContactEmails(row) {
		if (!row.ContactEmailLogin) {
			return row.ContactEmails.split(';')[0];
		}

		return row.ContactEmailLogin;
	},

	formatSalutation(row, salutationDict) {
		let salutation =
			salutationDict && row.ContactSalutationId && row.ContactSalutationId > 0
				? salutationDict[row.ContactSalutationId].Text
				: '';
		let salutationEmail =
			salutationDict && row.ContactSalutationEmailId && row.ContactSalutationEmailId > 0
				? salutationDict[row.ContactSalutationEmailId].Text
				: '';

		if (salutation != '' && salutationEmail != '') {
			return salutation + ' / ' + salutationEmail;
		} else if (salutation != '') {
			return salutation;
		} else if (salutationEmail != '') {
			return salutationEmail;
		}
	},

	itemsOnNewLine(items) {
		var itemsArray = items.split(/(?:,|;)/);
		var itemsOnNewLineArray = itemsArray.join('\n');
		return itemsOnNewLineArray;
	},
};

export default service;
