<template>
	<span :class="this.wrapperClass">
		<span :class="linkInvoiceClass" @click="getInvoiceFile(invoiceType.Invoice)">{{
			formatInvoiceName(invoiceType.Invoice)
		}}</span>
		<span :class="linkProformaClass" @click="getInvoiceFile(invoiceType.Proforma)">{{
			formatInvoiceName(invoiceType.Proforma)
		}}</span>
	</span>
</template>

<script>
import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '@/services/lang';

export default {
	props: {
		iNumber: { type: String },
		iCode: { type: String },
		iPFNumber: { type: String },
		iPFCode: { type: String },
		iType: { type: Number },
		wrapperClass: { type: String, default: '' },
		rowMarked: { type: Boolean, default: false },
	},
	data() {
		return {
			invoiceType: enums.invoiceType,
		};
	},
	computed: {
		linkProformaClass() {
			return [
				{
					'link': this.iPFNumber,
					'order-0': this.iType == this.invoiceType.Proforma && this.iPFNumber && this.iNumber,
					'main-invoice': this.iType == this.invoiceType.Proforma && this.iPFNumber && this.iNumber,
					'higlighted-link': this.rowMarked,
				},
			];
		},
		linkInvoiceClass() {
			return [
				{
					'link': this.iNumber,
					'order-1': this.iType == this.invoiceType.Proforma && this.iPFNumber && this.iNumber,
					'main-invoice': this.iType == this.invoiceType.Invoice && this.iPFNumber && this.iNumber,
					'higlighted-link': this.rowMarked,
				},
			];
		},
	},
	methods: {
		getInvoiceFile(invoiceType) {
			if (invoiceType == this.invoiceType.Proforma) {
				window.open(this.getInvoiceFileUrl(this.iPFCode), '_blank');
			} else {
				window.open(this.getInvoiceFileUrl(this.iCode), '_blank');
			}
		},
		formatInvoiceName(invoiceType) {
			if (invoiceType == this.invoiceType.Proforma) {
				if (!this.iNumber && !this.iPFNumber) {
					return this.$i18n.t('withoutinvoice');
				} else if (this.iPFNumber) {
					return 'PF ' + this.iPFNumber;
				}
			} else {
				if (!this.iNumber && !this.iPFNumber) {
					return this.$i18n.t('withoutinvoice');
				} else if (this.iNumber) {
					return 'OF ' + this.iNumber;
				}
			}
		},
		getInvoiceFileUrl(invoicePublicCode) {
			let today = new Date();
			const date = today.getDate() + '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
			return http.getUrl('Invoice/GetInvoiceFile', { invoicePublicCode: invoicePublicCode, c: date });
		},
	},
};
</script>
<style scoped>
.main-invoice {
	font-weight: 500;
	font-size: 0.8rem;
}
</style>
