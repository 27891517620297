import createItemButton from '../../components/common/button';
import fieldDetailDatepickerSingle from '../../components/detail/datepicker-range-single.field';
import fieldDetailDatepicker from '../../components/detail/datepicker-range.field';
import productsTable from '../../modules/user-products/products.table';
import invoiceLink from '../../components/common/invoice-link';
import invoiceNoteForProducts from '../invoices/invoice-note-for-products';
import invoiceLinkWrapper from '../../components/common/invoice-link-vfg.field';
import FieldRadios from '../../components/detail/radios.field';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import formatedInputField from '../../components/detail/formated-number-input.field';

function importDetailComponents(vue) {
	vue.component('field-single-datepicker', fieldDetailDatepickerSingle);
	vue.component('field-range-datepicker', fieldDetailDatepicker);
	vue.component('field-radios-group', FieldRadios);
	vue.component('field-formated-input', formatedInputField);
	vue.component('field-invoice-link-wrapper', invoiceLinkWrapper);
}

function importTableComponents(vue) {
	vue.component('megau-button', createItemButton);
	vue.component('font-awesome-icon', FontAwesomeIcon);
}

function importWrapperComponents(vue) {
	vue.component('products-table', productsTable);
	vue.component('products-detail', () => import('./products.detail'));
	vue.component('invoice-link', invoiceLink);
	vue.component('invoice-note-for-products', invoiceNoteForProducts);
}

export default {
	importTableComponents,
	importDetailComponents,
	importWrapperComponents,
};
