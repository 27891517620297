<template>
	<div v-if="entityId > 0">
		<div v-if="isModal">
			<div class="remarkModal">
				<div class="dataRemarkModal">
					<date-range-picker
						class="dtRemark"
						ref="datepickerModal"
						:dateRange="dateRange"
						:locale-data="locale"
						:singleDatePicker="true"
						:timePicker="true"
						:timePicker24Hour="true"
						:ranges="false"
						:autoApply="true"
						:time-picker-increment="5"
						:showDropdowns="true"
						@update="updateValue"
					></date-range-picker>
					<font-awesome-icon
						icon="calendar-alt"
						@click="clickDateIcon"
						cursor="pointer"
						class="calendar-icon"
					></font-awesome-icon>
					<b-dropdown v-if="remarkTypeId != 2" class="dtRemark" :text="selectedOperator.name">
						<b-dropdown-item
							v-for="operator in administratorList"
							:key="operator.id"
							:value="operator.name"
							@click="operatorSelected(operator)"
						>
							<span>{{ operator.name }}</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<b-textarea ref="textArea" rows="12" v-model="dataText"></b-textarea>
			</div>
		</div>
		<div v-if="!isModal">
			<div class="remark">
				<span class="titleRemark">{{ detailTitle }}</span>
				<div class="dataRemark">
					<div v-if="isLoaded">
						<date-range-picker
							class="dtRemark"
							ref="datepicker"
							:dateRange="dateRange"
							:locale-data="locale"
							:singleDatePicker="true"
							:timePicker="true"
							:timePicker24Hour="true"
							:ranges="false"
							:autoApply="true"
							:time-picker-increment="5"
							:showDropdowns="true"
							@update="updateValue"
						></date-range-picker>
						<font-awesome-icon
							icon="calendar-alt"
							@click="clickDateIcon"
							cursor="pointer"
							class="calendar-icon"
						></font-awesome-icon>
					</div>
					<b-dropdown class="dtRemark" :text="selectedOperator.name">
						<b-dropdown-item
							v-for="operator in administratorList"
							:key="operator.id"
							:value="operator.name"
							@click="operatorSelected(operator)"
						>
							<span>{{ operator.name }}</span>
						</b-dropdown-item>
					</b-dropdown>
					<b-textarea ref="textArea" rows="8" v-model="dataText"></b-textarea>
				</div>
			</div>
			<div class="buttons">
				<megau-button
					icon="check"
					:title="$t('saveremark')"
					@handleClick="handleInsertOrUpdate(false)"
					classprop="btnRemark btn btn-success"
				></megau-button>
				<megau-button
					icon="check"
					:title="$t('saveandreturnto')"
					@handleClick="handleInsertOrUpdate(true)"
					classprop="btnRemark btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDelete(false)"
					:title="$t('deleteremark')"
					classprop="btnRemark btn btn-danger"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDelete(true)"
					:title="$t('deleteandreturn')"
					classprop="btnRemark btn btn-danger"
				></megau-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

import auth from '../../services/auth.service';
import apiCommon from '../../services/api/common.api';
import serviceCommon from '../../services/service/common.service';
import http from '../../services/helpers/http';
import apiUser from '../../services/api/user.api';
import { loadRemarksCount } from '../../store/actions';

export default {
	components: { DateRangePicker },
	props: {
		entityId: { type: Number },
		entityTypeId: { type: Number },
		remarkTypeId: { type: Number },
		backmethod: { type: Function },
		loadedData: { type: Object },
		isModal: { Type: Boolean, default: false },
		administratorList: { Type: Array },
	},
	data() {
		return {
			selectedOperator: {
				id: null,
				name: this.$i18n.t('Vyber operatora'),
			},
			detailTitle: this.$i18n.t('remark:2307'),
			dataText: null,
			isLoaded: false,
			data: {
				Id: 0,
				EntityId: this.entityId,
				EntityTypeId: this.entityTypeId,
				Date: moment(),
				Text: null,
				RemarkTypeId: this.remarkTypeId,
				UserId: null,
			},
			locale: {
				format: this.$store.state.lang.DateTimeFormat,
				applyLabel: this.$i18n.t('delete2315'),
				cancelLabel: this.$i18n.t('cancel2314'),
				daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
				firstDay: 1, //ISO first day of week - see moment documenations for details
			},
		};
	},
	created() {
		if (this.loadedData) {
			this.fillPropData();
		} else if (this.entityId) {
			this.getRemark();
		}
		this.setModalWidth();
	},
	mounted() {
		if (this.$refs.datepickerModal && this.isModal) {
			this.$refs.datepickerModal.open = true;
		}
	},
	computed: {
		language() {
			const locale = this.$i18n.locale;
			return translatorLocales[locale];
		},
		dateRange: {
			get() {
				return this.data ? { startDate: this.data.Date, endDate: this.data.Date } : { startDate: null, endDate: null };
			},
		},
	},
	methods: {
		operatorSelected(operator) {
			this.selectedOperator = operator;
			this.data.UserId = this.selectedOperator.id;
		},
		fillOperator() {
			if (!this.data.UserId) {
				let user = auth.GetUser();
				this.data.UserId = user.Id;
			}

			if (this.administratorList) {
				const operator = this.administratorList.find((obj) => obj.id === this.data.UserId);
				if (operator) {
					this.selectedOperator = operator;
				}
			}
		},
		async getRemark() {
			if (!this.isModal) {
				var remark = await this.GetRemarkByIds(this.entityId, this.entityTypeId, this.remarkTypeId);

				if (remark.data == '') {
					this.clearData();
					this.dataText = '';
				} else {
					this.fillApiData(remark);
				}

				this.isLoaded = true;
			}
			this.fillOperator();
		},

		openDeleteRemarkModal(itemId, callback) {
			this.$modal.show({
				title: this.$i18n.t('deleteremark2312'),
				text: this.$i18n.t('areyousureyouwa2313'),
				modalClass: 'modal-danger',
				variant: 'danger',
				onConfirm() {
					return apiCommon
						.deleteEntity(itemId, 'Remark')
						.then(() => callback())
						.then(() => this.$store.dispatch(loadRemarksCount));
				},
			});
		},

		GetRemarkByIds(entityId, entityTypeId, remarkTypeId) {
			if (!remarkTypeId) {
				return http.getWithFilters('Remark/GetRemarkByIds', { entityId, entityTypeId });
			} else {
				return http.getWithFilters('Remark/GetRemarkByIds', { entityId, entityTypeId, remarkTypeId });
			}
		},

		handleInsertOrUpdate(back) {
			if (this.dataText !== this.data.Text) {
				this.mergeNewWithOldRemarkText();
			} else {
				this.data.Text = this.data.Text.length > 0 ? this.data.Text.substr(2) : this.data.Text;
			}
			if (back) {
				this.insertOrUpdateRemark()
					.then(() => {
						this.backmethod();
					})
					.then(() => this.$store.dispatch(loadRemarksCount));
			} else {
				this.insertOrUpdateRemark().then(() => this.$store.dispatch(loadRemarksCount));
			}
		},

		updateValue(date) {
			if (!date) {
				this.data.Date = date;
			} else {
				let dt = moment(date.startDate, this.inputFormat).format(this.$store.state.lang.DateTimeFormat); //must be first month because of validation

				this.data.Date = date.startDate;
			}
		},

		mergeNewWithOldRemarkText() {
			var date = moment().format(this.$store.state.lang.DateTimeFormat);
			var usr = auth.GetUser();
			var user = auth.GetUser().ContactPersonName.charAt(0) + '. ' + auth.GetUser().ContactPersonSurname + ': ';
			var header = '------------------------------------ ' + '\n' + date + ' ' + user;
			this.data.Text = header + this.dataText;
		},

		insertOrUpdateRemark() {
			if (this.data.Id > 0) {
				console.log("huraaaa > 0 ");
				return apiCommon.putEntity(this.data, 'Remark');
			} else {
				console.log("huraaaa  elseee ");

				return apiCommon.postEntity(this.data, 'Remark').then((resp) => {
					this.data.Id = resp.data;
				});
			}
		},

		clickDateIcon() {
			let component = this.$refs.datepicker;

			if (this.isModal) {
				component = this.$refs.datepickerModal;
			}

			if (component.open == false) {
				component.open = true;
			} else {
				component.open = false;
			}
		},

		setModalWidth() {
			let root = document.documentElement;
			if (this.isModal) {
				root.style.setProperty('--dtRemarkWidth', 95 + '%');
			} else {
				root.style.setProperty('--dtRemarkWidth', 50 + '%');
			}
		},

		clearData() {
			this.data.Id = 0;
			this.data.EntityId = this.entityId;
			this.data.EntityTypeId = this.entityTypeId;
			this.data.Date = moment();
			this.data.Text = null;
			this.data.RemarkTypeId = this.remarkTypeId;
			this.dataText = '';
		},

		handleDelete(back) {
			if (this.data.Id == 0) {
				//invalid
			} else {
				if (back) {
					this.openDeleteRemarkModal(this.data.Id, this.backmethod);
				} else {
					this.openDeleteRemarkModal(this.data.Id, this.clearData);
				}
			}
		},

		fillPropData() {
			this.data = Object.assign({}, this.loadedData);
			this.data.Text = '\n' + this.data.Text;
			this.dataText = this.data.Text;
			this.fillOperator();
		},

		fillApiData(remark) {
			remark.data.Text = '\n' + remark.data.Text;
			this.data = remark.data;
			this.dataText = remark.data.Text;
		},
	},
};
</script>

<style lang="scss" scoped>
.remark {
	margin-bottom: 10px;
	display: flex;
	flex-flow: row wrap;
	align-content: center;

	.titleRemark {
		@include formLabel;
		order: 0;
		margin-top: 7px;
	}

	.dataRemark {
		width: 50%;
	}
}

.buttons {
	margin-bottom: 20px;
	padding-left: $formLabelWidth;
	display: flex;
	flex-flow: row wrap;
	flex-direction: row;

	.btnRemark {
		padding: 10px;
	}

	> div {
		margin-right: 10px;
	}
}

.remarkModal {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-content: center;
	align-items: flex-start;

	.dataRemarkModal {
		width: 43%;
	}
}

.remark,
.remarkModal {
	.dtRemark {
		padding-bottom: 5px;
		width: var(--dtRemarkWidth);
	}
	.calendar-icon {
		top: -11px;
		margin-left: -20px;
		position: relative;
		z-index: 10;
		color: var(--mu-calendargray);
	}
}
</style>
