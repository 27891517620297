import formFields from './product.fields';

function detailModel(i18n) {
	return {
		controllerName: 'UserProduct',
		addDaysToDateRange: null,
		showModal: false,
		showFormAdd: false,
		showFormEdit: false,
		showDeleteButton: false,
		modalTitle: '',
		buttonTitle: i18n.t('savechangesande'),
		modelEdit: {
			UproductId: 0,
			GuserId: null,
			ObjectId: null,
			ProductId: 0,
			VariabelSymbol: null,
			UproductDateTaxable: null,
			UproductDatetimeCreated: null,
			UproductDateStart: null,
			UproductDateEnd: null,
			UproductDatePayment: null,
			UproductPrice: null,
			InvoiceType: null,
			Activation: null,
			InvoiceNumber: null,
			CurrencyId: null,
			PackagesList: null,
			InvoiceId: null,
			InvoiceNote: null,
			ObjectCountryId: 0,
			ProductMonths: null,
			DateRange: {
				startDate: null,
				endDate: null,
			},
			InvoiceLink: {
				iNumber: null,
				iCode: null,
				iType: null,
			},
		},
		modelAdd: {
			UproductId: 0,
			ProductId: 0,
			VariabelSymbol: null,
			UproductDateTaxable: null,
			UproductDateStart: null,
			UproductDateEnd: null,
			UproductPrice: null,
			Activation: null,
			ObjectCountryId: 0,
			ProductMonths: null,
			InvoiceId: null,
			DateRange: {
				startDate: null,
				endDate: null,
			},
		},
		schemaAdd: {
			groups: formFields(i18n).addFields,
		},
		schemaEdit: {
			groups: formFields(i18n).editFields,
		},
		formOptions: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateDebounceTime: 500,
			validateAsync: true,
		},
	};
}

function tableModel() {
	return {
		showTable: false,
		currencyDict: null,
		showEmailSend: false,
		sendLogEmailId: 0,
		controllerName: 'PortalEmailSendLog',
		columns: [
			'NumberRow',
			'Name',
			'DateStart',
			'DateEnd',
			'Price',
			'Vat',
			'PriceTotal',
			'DatePayment',
			'INumber',
			'InvoiceVariableSymbol',
			'Edit',
		],
	};
}

export { detailModel, tableModel };
