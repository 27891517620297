var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCheck
    ? _c("font-awesome-icon", {
        staticClass: "text-success",
        attrs: { icon: "check", size: "lg" },
      })
    : _vm.showBadge
    ? _c(
        "span",
        [
          _c("b-badge", { attrs: { variant: "primary" } }, [
            _vm._v(_vm._s(this.badgeTitle)),
          ]),
        ],
        1
      )
    : _c("span", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }