import i18n from '../../services/lang';
const validators = {
	validateDateFrom() {
		if (!this.model.UproductDateStart || moment(this.model.UproductDateStart) > moment(this.model.UproductDateEnd)) {
			return [i18n.t('amistake!')];
		} else {
			return [];
		}
	},
	validateDateTo() {
		if (!this.model.UproductDateEnd || moment(this.model.UproductDateStart) > moment(this.model.UproductDateEnd)) {
			return [i18n.t('amistake!')];
		} else {
			return [];
		}
	},
};

export default validators;
