import vue from 'vue';
import vueRouter from 'vue-router';
import * as links from './links';
import auth from '../services/auth.service';
import enums from '../services/helpers/enums';

vue.use(vueRouter);

const router = new vueRouter({
	mode: 'history', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes: [
		{
			path: links.DEFAULT,
			name: links.homeName,
			component: () => import('@/views/default-container'),
			redirect: { name: links.dashboardName },
			meta: {
				requiresAuth: true,
			},
			children: [
				{
					path: links.dashboard,
					name: links.dashboardName,
					component: () => import('@/modules/dashboard/dashboard'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.portalRatings,
					name: links.portalRatingsTableName,
					component: () => import('@/modules/portal-ratings/portal-rating.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.PORTALRATINGDETAIL,
					name: links.portalRatingsDetailName,
					component: () => import('@/modules/portal-ratings/portal-rating.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.OBJECTREVIEWS,
					name: links.objectReviewsTableName,
					component: () => import('@/modules/object-reviews/object-reviews.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.OBJECTREVIEWDETAIL,
					name: links.objectReviewsDetailName,
					component: () => import('@/modules/object-reviews/object-review.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.contactsTable,
					name: links.contactsTableName,
					component: () => import('@/modules/contacts/contacts.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.contactDetail,
					name: links.contactDetailName,
					component: () => import('@/modules/contacts/contact.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.objectsTable,
					name: links.objectsTableName,
					component: () => import('@/modules/objects/object.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.objectDetail,
					name: links.objectDetailName,
					component: () => import('@/modules/objects/object.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.invoicesTable,
					name: links.invoicesTableName,
					component: () => import('@/modules/invoices/invoice.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.invoiceDetail,
					name: links.invoiceDetailName,
					component: () => import('@/modules/invoices/invoice.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.objectDemandTable,
					name: links.objectDemandTableName,
					component: () => import('@/modules/object-demand/object-demand.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.objectDemandDetail,
					name: links.objectDemandDetailName,
					component: () => import('@/modules/object-demand/object-demand.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.seoPageTable,
					name: links.seoPageTableName,
					component: () => import('@/modules/seo-pages/seo-page.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.CopyWriterSenior,
					},
				},
				{
					path: links.seoPageDetail,
					name: links.seoPageDetailName,
					component: () => import('@/modules/seo-pages/seo-page.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.CopyWriterSenior,
					},
				},
				{
					path: links.attractionTable,
					name: links.attractionTableName,
					component: () => import('@/modules/attractions/attraction.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.CopyWriterJunior,
					},
				},
				{
					path: links.attractionDetail,
					name: links.attractionDetailName,
					component: () => import('@/modules/attractions/attraction.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.CopyWriterJunior,
					},
				},
				{
					path: links.regionTable,
					name: links.regionTableName,
					component: () => import('@/modules/regions/region.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.regionDetail,
					name: links.regionDetailName,
					component: () => import('@/modules/regions/region.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.reinvoicingDetail,
					name: links.reinvoicingDetailName,
					component: () => import('@/modules/reinvoicings/reinvoicing.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.portalJobRunsTable,
					name: links.portalJobRunsTableName,
					component: () => import('@/modules/portal-job-runs/portal-job-runs.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.urlCatalogTable,
					name: links.urlCatalogTableName,
					component: () => import('@/modules/url-catalog/url-catalog.table'),
					meta: {
						requiresAuth: true,
					},
				},
				{
					path: links.urlCatalogDetail,
					name: links.urlCatalogDetailName,
					component: () => import('@/modules/url-catalog/url-catalog.detail'),
					meta: {
						requiresAuth: true,
					},
				},
				{
					path: links.backlinksTable,
					name: links.backlinksTableName,
					component: () => import('@/modules/backlinks/backlink.table'),
					meta: {
						requiresAuth: true,
					},
				},
				{
					path: links.backlinkDetail,
					name: links.backlinkDetailName,
					component: () => import('@/modules/backlinks/backlink.detail'),
					meta: {
						requiresAuth: true,
					},
				},
				{
					path: links.faqTable,
					name: links.faqTableName,
					component: () => import('@/modules/faqs/faq.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.faqDetail,
					name: links.faqDetailName,
					component: () => import('@/modules/faqs/faq.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.priceListSeasonTable,
					name: links.priceListSeasonTableName,
					component: () => import('@/modules/price-list-season/price-list-season.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.priceListSeasonDetail,
					name: links.priceListSeasonDetailName,
					component: () => import('@/modules/price-list-season/price-list-season.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.priceListUnitTable,
					name: links.priceListUnitTableName,
					component: () => import('@/modules/price-list-unit/price-list-unit.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.priceListUnitDetail,
					name: links.priceListUnitDetailName,
					component: () => import('@/modules/price-list-unit/price-list-unit.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.accomodationUnitTypeTable,
					name: links.accomodationUnitTypeTableName,
					component: () => import('@/modules/accomodation-unit-type/accomodation-unit-type.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.accomodationUnitTypeDetail,
					name: links.accomodationUnitTypeDetailName,
					component: () => import('@/modules/accomodation-unit-type/accomodation-unit-type.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.objectSimplePropertyTable,
					name: links.objectSimplePropertyTableName,
					component: () => import('@/modules/object-simple-properties/object-simple-properties.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.objectSimplePropertyDetail,
					name: links.objectSimplePropertyDetailName,
					component: () => import('@/modules/object-simple-properties/object-simple-properties.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.objectTypesTable,
					name: links.objectTypesTableName,
					component: () => import('@/modules/object-types/object-type.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.objectTypesDetail,
					name: links.objectTypesDetailName,
					component: () => import('@/modules/object-types/object-type.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.catalogSectionTable,
					name: links.catalogSectionTableName,
					component: () => import('@/modules/catalog-sections/catalog-section.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.catalogSectionDetail,
					name: links.catalogSectionDetailName,
					component: () => import('@/modules/catalog-sections/catalog-section.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.sectionMenuTable,
					name: links.sectionMenuTableName,
					component: () => import('@/modules/section-menu/section-menu.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.sectionMenuDetail,
					name: links.sectionMenuDetailName,
					component: () => import('@/modules/section-menu/section-menu.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.currencyTable,
					name: links.currencyTableName,
					component: () => import('@/modules/currencies/currency.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.currencyDetail,
					name: links.currencyDetailName,
					component: () => import('@/modules/currencies/currency.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.discountCategoryTable,
					name: links.discountCategoryTableName,
					component: () => import('@/modules/discount-categories/discount-category.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.discountCategoryDetail,
					name: links.discountCategoryDetailName,
					component: () => import('@/modules/discount-categories/discount-category.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.discountTypeTable,
					name: links.discountTypeTableName,
					component: () => import('@/modules/discount-types/discount-type.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.discountTypeDetail,
					name: links.discountTypeDetailName,
					component: () => import('@/modules/discount-types/discount-type.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.objectReservationTable,
					name: links.objectReservationTableName,
					component: () => import('@/modules/object-reservation/object-reservation.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.objectReservationDetail,
					name: links.objectReservationDetailName,
					component: () => import('@/modules/object-reservation/object-reservation.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.emailsTemplatesTable,
					name: links.emailsTemplatesTableName,
					component: () => import('@/modules/emails-templates/emails-templates.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.emailsTemplateDetail,
					name: links.emailsTemplateDetailName,
					component: () => import('@/modules/emails-templates/emails-templates.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.emailsLogsTable,
					name: links.emailsLogsTableName,
					component: () => import('@/modules/emails-logs/emails-logs.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.emailerTable,
					name: links.emailerTableName,
					component: () => import('@/modules/emailer/emailer.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.emailerDetail,
					name: links.emailerDetailName,
					component: () => import('@/modules/emailer/emailer.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Operator,
					},
				},
				{
					path: links.productTable,
					name: links.productTableName,
					component: () => import('@/modules/portal-product/product.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.productDetail,
					name: links.productDetailName,
					component: () => import('@/modules/portal-product/product.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.accountingCompanyTable,
					name: links.accountingCompanyTableName,
					component: () => import('@/modules/accounting-companies/accounting-company.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.accountingCompanyDetail,
					name: links.accountingCompanyDetailName,
					component: () => import('@/modules/accounting-companies/accounting-company.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.accountingExportDetail,
					name: links.accountingExportDetailName,
					component: () => import('@/modules/accounting-export/accounting-export.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.translationTable,
					name: links.translationTableName,
					component: () => import('@/modules/translations/translation.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.translationDetail,
					name: links.translationDetailName,
					component: () => import('@/modules/translations/translation.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.sojournTypesTable,
					name: links.sojournTypeTableName,
					component: () => import('@/modules/sojourn-types/sojourn-type.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.sojournTypeDetail,
					name: links.sojournTypeDetailName,
					component: () => import('@/modules/sojourn-types/sojourn-type.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.attractionGroupTable,
					name: links.attractionGroupTableName,
					component: () => import('@/modules/attraction-groups/attraction-group.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.attractionGroupDetail,
					name: links.attractionGroupDetailName,
					component: () => import('@/modules/attraction-groups/attraction-group.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.cityTable,
					name: links.cityTableName,
					component: () => import('@/modules/cities/city.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.cityDetail,
					name: links.cityDetailName,
					component: () => import('@/modules/cities/city.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.districtTable,
					name: links.districtTableName,
					component: () => import('@/modules/districts/district.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.districtDetail,
					name: links.districtDetailName,
					component: () => import('@/modules/districts/district.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.territoryTable,
					name: links.territoryTableName,
					component: () => import('@/modules/territories/territory.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.territoryDetail,
					name: links.territoryDetailName,
					component: () => import('@/modules/territories/territory.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.TeamLeader,
					},
				},
				{
					path: links.countryTable,
					name: links.countryTableName,
					component: () => import('@/modules/countries/country.table'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.countryDetail,
					name: links.countryDetailName,
					component: () => import('@/modules/countries/country.detail'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Admin,
					},
				},
				{
					path: links.statisticsPage,
					name: links.statisticsPageName,
					component: () => import('@/modules/statistics/statistics'),
					meta: {
						requiresAuth: true,
						requiredPermission: enums.role.Owner,
					},
				},
			],
		},
		{
			path: links.LOGIN,
			name: 'login',
			component: () => import('@/views/login-page'),
		},
		{
			path: '*',
			redirect: links.DEFAULT,
		},
	],
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		auth.IsAuthenticated()
			.then((res) => {
				// console.log(res);
				if (res === true) {
					if (to.path === links.LOGIN) {
						next(links.DEFAULT);
					} else {
						if (auth.IsAllowedForUser(to.meta.requiredPermission)) {
							next();
						}
					}
				} else {
					next(links.LOGIN);
				}
			})
			.catch((_) => {
				next(links.LOGIN);
			});
	} else {
		next();
	}
});

export default router;
