<template>
	<font-awesome-icon v-if="showCheck" class="text-success" icon="check" size="lg"></font-awesome-icon>
	<span v-else-if="showBadge">
		<b-badge variant="primary">{{this.badgeTitle}}</b-badge>
	</span>
	<span v-else>-</span>
</template>

<script>
export default {
	props: {
		boolValue: { type: Boolean, default: false },
		badgeTitle: { type: String, default: '' },
	},
	computed: {
		showCheck() {
			return this.boolValue && this.badgeTitle == '';
		},
		showBadge() {
			return this.boolValue && this.badgeTitle != '';
		},
	},
};
</script>