import vue from 'vue';

export default {
	data() {
		return {
			isLoaded: false,
		};
	},

	methods: {
		startLoading(hideContainer = true) {
			console.log('start loading');
			vue.prototype.$loader = this.$loading.show();
			vue.prototype.$hideContainer = hideContainer;
			if (hideContainer) this.isLoaded = false;
		},
		stopLoading() {
			console.log('stop loading');
			if (vue.prototype.$loader) {
				vue.prototype.$loader.hide();
				vue.prototype.$loader = null;
				this.isLoaded = true;
			}
		},
	},

	errorCaptured(err, vm, info) {
		this.stopLoading();
	},
};
