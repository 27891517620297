import http from '../../services/helpers/http';

const service = {
	getList() {
		return http.get('Dashboard/GetItems');
	},
	getState() {
		return http.get('Dashboard/GetState');
	},
};

export default service;
