export default {
	namespaced: true,
	state: {
		items: [],
	},
	getters: {},
	actions: {
		success(store, name) {
			store.dispatch('set', {
				type: 'success',
				name: name,
			});
		},
		warning(store, name) {
			store.dispatch('set', {
				type: 'warning',
				name: name,
			});
		},
		error(store, name) {
			store.dispatch('set', {
				type: 'error',
				name: name,
			});
		},
		set(store, alert) {
			const key = new Date().valueOf() + (Math.random() * (0 - 9999999) + 9999999);
			alert.key = key;
			store.commit('set', alert);
			setTimeout(() => {
				store.commit('delete', key);
			}, 3000);
		},
	},
	mutations: {
		set(state, alert) {
			state.items.push(alert);
		},
		delete(state, key) {
			state.items = state.items.filter(function(obj) {
				return obj.key !== key;
			});
		},
	},
};
