import { setQuickFilter, deleteQuickFilter } from './actions';

const state = {};

const mutations = {
	[setQuickFilter](state, { filter, value }) {
		if (value.trim()) {
			state[filter] = value;
		} else {
			delete state[filter];
		}
	},

	[deleteQuickFilter](state, { filter }) {
		delete state[filter];
	},
};

export default {
	state,
	mutations,
};
