import i18n from '../../../services/lang';

const service = {

		formTranslations(formFields, i18n) {
			formFields.CountryName.label = i18n.t('country2453');
			formFields.OstypeOrder.label = i18n.t('order3255');
            formFields.OstypeIsVisibleHomepage.label = i18n.t('showonhomepage3256');
            formFields.OstypeIsVisibleHomepage.textOn = i18n.t('yes2220');
		    formFields.OstypeIsVisibleHomepage.textOff = i18n.t('no2221');
            formFields.OstypeIsVisibleCatalogSubfilter.label = i18n.t('showoncatalog');
            formFields.OstypeIsVisibleCatalogSubfilter.textOn = i18n.t('yes2220');
		    formFields.OstypeIsVisibleCatalogSubfilter.textOff = i18n.t('no2221');
            formFields.OstypeIsEnabled.label = i18n.t('isthissojournty');
            formFields.OstypeIsEnabled.textOn = i18n.t('yes2205');
		    formFields.OstypeIsEnabled.textOff = i18n.t('no2206');
			
        },
};

export default service;
