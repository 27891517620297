<template>
	<div v-if="isLoaded">
		<megau-button classprop="btn" titlePosition="none" :title="buttonTitle" @handleClick="openModal()"></megau-button>
		<invoice-address-detail
			:isVisible="isVisibleDetail"
			:modelInvAddress="modelInvAddress"
			:schemaInvAddress="schemaInvAddress"
			:showQuestion="schema.showQuestion"
			:formOptionsInvAddress="formOptionsInvAddress"
			@onClose="closeDetail"
			@onChanged="onChanged"
		/>
	</div>
</template>
<script>
import vue from 'vue';
import { abstractField } from 'vue-form-generator';

import apiCommon from '../../../services/api/common.api';

import { detailModel } from './invoice-address.models';

import imports from './invoice-address.imports';
imports.importDetailComponents(vue);

export default {
	mixins: [abstractField],
	name: 'invoice-address',
	created() {
		this.fetchData();
	},
	data() {
		return detailModel(this.$store, this.$i18n);
	},
	computed: {
		buttonTitle() {
			let ico = this.modelInvAddress.InvaddrIco == '' ? '' : this.$i18n.t('companyid') + ' ' + this.modelInvAddress.InvaddrIco;
			let dic = this.modelInvAddress.InvaddrDic == '' ? '' : this.$i18n.t('taxid') + ' ' + this.modelInvAddress.InvaddrDic;
			let icdph = this.modelInvAddress.InvaddrIcdph == '' ? '' : this.$i18n.t('vatid') + ' ' + this.modelInvAddress.InvaddrIcdph;

			return [
				this.modelInvAddress.InvaddrName,
				this.modelInvAddress.InvaddrStreet,
				this.modelInvAddress.InvaddrZip,
				this.modelInvAddress.InvaddrCity,
				ico,
				dic,
				icdph,
			]
				.filter((x) => typeof x == 'string' && x.length > 0)
				.join(', ');
		},
	},
	methods: {
		onChanged() {
			if (this.schema.onChanged) {
				this.schema.onChanged.call(this);
			}
		},
		openModal() {
			this.isVisibleDetail = true;
		},
		closeDetail() {
			this.isVisibleDetail = false;
		},
		async fetchData() {
			const invoiceAddress = await apiCommon.getEntity(this.value, this.controllerName);
			this.modelInvAddress = invoiceAddress.data || {};
			this.isLoaded = true;
		},
	},
};
</script>
