<template>
	<div v-if="showLinks" class="links-container">
		<div class="card-counter reservations" target="_blank">
			<!-- <font-awesome-icon icon="calendar-alt" class="icon-link" /> -->
			<span class="count-title">{{$t('reservations2524')}}</span>

			<div class="count-name">
				<span></span>
				<span class="realized">{{$t('notestocontacts')}}</span>
				<span class="realized">{{$t('realized')}}</span>
			</div>

			<div class="count-name">
				<span>{{$t('lastmonths')}}</span>
				<a :href="getUrl(reservationsLastYear)" target="_blank">
					<span class="realized">{{ value.ReservationLastYearCount }}</span>
				</a>
				<a :href="getUrl(reservationsLastYearRealized)" target="_blank">
					<span class="realized">{{ value.RealizedReservationLastYearCount }}</span>
				</a>
			</div>

			<div class="count-name">
				<span>{{$t('overall')}}</span>
				<a :href="getUrl(reservationsTotal)" target="_blank">
					<span class="realized">{{ value.ReservationTotalCount }}</span>
				</a>
				<a :href="getUrl(reservationsTotalRealized)" target="_blank">
					<span class="realized">{{ value.RealizedReservationTotalCount }}</span>
				</a>
			</div>
		</div>

		<a :href="getUrl(stats)" target="_blank" class="card-counter stats">
			<font-awesome-icon icon="chart-bar" class="icon-link" />
			<span class="count-title">{{$t('statistics2528')}}</span>
			<span class="count-name">{{ value.ObjectName }}</span>
		</a>

		<a :href="getUrlPreview()" target="_blank" class="card-counter preview">
			<font-awesome-icon icon="eye" class="icon-link" />
			<span class="count-title">{{$t('preview')}}</span>
			<span class="count-name">{{ value.ObjectName }}</span>
		</a>
	</div>
</template>
<script>
import auth from '../../services/auth.service';
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	name: 'link-box',

	data() {
		return {
			autologCode: '',
			reservationsTotal: {
				pageName: `ubytovatel-prehlad-rezervacii/`,
				pageParameters: '',
			},
			reservationsTotalRealized: {
				pageName: `ubytovatel-prehlad-rezervacii/`,
				pageParameters: `customReservationsFilter=3`,
			},
			reservationsLastYear: {
				pageName: `ubytovatel-prehlad-rezervacii/`,
				pageParameters: `customReservationsFilter=1`,
			},
			reservationsLastYearRealized: {
				pageName: `ubytovatel-prehlad-rezervacii/`,
				pageParameters: `customReservationsFilter=2`,
			},
			stats: {
				pageName: `ubytovatel-statistiky/`,
				pageParameters: ``,
			},
		};
	},
	computed: {
		showLinks: function() {
			return this.value ? true : false;
		},
	},
	mounted() {
		this.autologCode = auth.GetUser().AutologCode;
	},
	methods: {
		getUrlPreview() {
			var url = '';
			url = `${this.value.DomainUrl}/${this.value.ObjectUrl}?tkn=${this.autologCode}`;
			return url;
		},
		getUrl(linkObject) {
			var url = '';
			url = `${this.value.DomainUrl}/${linkObject.pageName}${this.value.ObjectId}?tkn=${this.autologCode}&${linkObject.pageParameters}`;
			return url;
		},
	},
};
</script>

<style lang="scss" scoped>
.links-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0px;
	.card-counter {
		box-shadow: 2px 2px 10px #dadada;
		padding: 10px 10px;
		background-color: #fff;
		border-radius: 5px;
		transition: 0.3s linear all;
		font-size: 200% !important;
		display: block;
		line-height: 1;
	}

	.count-title {
		font-size: 20px;
		display: block;
		text-align: left;
		padding-bottom: 10px;
	}

	.count-name {
		font-size: 14px;
	}
	.icon-link {
		float: right;
		opacity: 0.3;
	}
	.reservations {
		background-color: var(--primary);
		color: var(--white);
		width: 50%;

		.count-name {
			padding: 5px 0px 5px 0px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			a,
			span {
				color: #fff;
				width: 33%;
				text-align: center;
				&:first-child {
					text-align: left;
				}
			}

			a:hover {
				font-weight: bold;
			}
			.realized {
				font-weight: bold;
			}
		}
		.count-name:nth-child(2) {
			padding-bottom: 16px;
		}
		.count-title {
			padding-bottom: 0px;
			margin-bottom: -20px;
		}
	}

	.stats {
		width: 23%;
		background-color: var(--warning);
		color: var(--white);
	}

	a.preview {
		background-color: var(--success);
		color: var(--white);
		width: 23%;
	}
	a.preview:hover {
		background-color: var(--success-light);
	}
	a.stats:hover {
		background-color: var(--warning-light);
	}
}
</style>
