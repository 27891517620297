<template>
	<div>
		<products-table
			:objectId="objectId"
			:invoiceId="invoiceId"
			:editable="editable"
			:userId="userId"
			:markCurrentPackage="markCurrentPackage"
			@editproduct="editProd"
			:key="tableKey"
		></products-table>
		<products-detail
			:isVisible="this.isVisibleDetail"
			:uProductId="this.productId"
			:userId="this.userId"
			:objectId="this.objectId"
			:invoiceId="invoiceId"
			:objectCountryId="this.objectCountryId"
			@onClose="closeDetail"
			@onUpdate="forceReRender"
		/>
	</div>
</template>
<script>
import vue from 'vue';
import imports from './product.imports';
import { EventBus } from '../../services/helpers/event-bus.js';
imports.importWrapperComponents(vue);

export default {
	props: {
		objectId: { type: Number },
		userId: { type: Number },
		invoiceId: { type: Number },
		objectCountryId: { type: Number },
		refresh: { type: Function },
		markCurrentPackage: { type: Boolean, default: false },
		editable: { type: Boolean, default: true },
	},
	data() {
		return {
			tableKey: 0,
			productId: null,
			isVisibleDetail: false,
		};
	},
	mounted() {
		EventBus.$on('change-note-from-detail', () => {
			this.forceReRender();
		});
	},

	methods: {
		forceReRender() {
			this.tableKey += 1;
			if (this.refresh) {
				this.refresh();
			}
		},
		closeDetail() {
			this.isVisibleDetail = false;
		},
		editProd(productId) {
			this.isVisibleDetail = true;
			if (productId >= 0) {
				this.isVisibleDetail = true;
				this.productId = productId;
			}
		},
	},
};
</script>
