<template>
	<div class="megauButton">
		<button
			@click="handleClick()"
			type="button"
			v-b-tooltip.hover
			:class="buttonClass"
			:title="tooltip"
			:disabled="isDisabled"
		>
			<span v-if="title" :class="titleClass">{{title}}</span>
			<font-awesome-icon v-if="icon" :icon="icon" size="lg"></font-awesome-icon>
		</button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			processing: false,
		};
	},
	props: {
		title: { type: String },
		titlePosition: { type: String, default: 'left' },
		icon: { type: String },
		classprop: { type: String },
		tooltip: { type: String },
		disabled: { type: Boolean },
	},
	computed: {
		buttonClass() {
			return this.classprop + ' d-flex align-items-center';
		},
		isDisabled() {
			return this.disabled;
		},
		titleClass() {
			return 'title ' + this.titlePosition;
		},
	},
	methods: {
		handleClick() {
			if (!this.isDisabled) {
				if (this.processing) {
					return;
				}

				this.processing = true;
				this.$emit('handleClick');
				let _this = this;
				setTimeout(() => {
					_this.processing = false;
				}, 1000);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.title {
	&.left {
		padding-right: 7.5px;
	}
	&.right {
		order: 2;
		padding-left: 7.5px;
	}
}
</style>