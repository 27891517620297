import vueFormGenerator from 'vue-form-generator';
import { formatter } from '../../services/helpers/formatter';
import enums from '../../services/helpers/enums';
import serviceCommon from '../../services/service/common.service';
import validators from './product.validators';

function paymentStatus(i18n) {
	return {
		type: 'label',
		label: i18n.t('paid'),
		model: 'UproductDatePayment',
		get: function(model) {
			return model && model.UproductDatePayment
				? formatter.dateTimeToString(model.UproductDatePayment)
				: i18n.t('unpaidandnotact');
		},
	};
}

function groups(i18n) {
	return {
		addFields: [
			{
				fields: [
					{
						type: 'select',
						label: i18n.t('packagename2427'),
						model: 'ProductId',
						styleClasses: 'threequarter-width',
						values: [],
						required: true,
						selectOptions: {
							noneSelectedText: i18n.t('select'),
						},
						validator: (value, required) => {
							if (value > 0) {
								return null;
							}
							return [i18n.t('amistake!')];
						},
					},
					{
						type: 'single-datepicker',
						label: i18n.t('validfrom'),
						model: 'UproductDateStart',
						language: 'sk',
						showtime: false,
						autoselect: true,
						required: true,
						validator: validators.validateDateFrom,
						onChanged(model, value) {
							if (value) {
								let newDateFrom = new Date(value);
								let newDate = newDateFrom.setMonth(newDateFrom.getMonth() + model.ProductMonths);
								model.UproductDateEnd = new Date(newDate);
							}
						},
					},
				],
			},
			{
				styleClasses: 'col-xs-12 inline-input-group-date',
				fields: [
					{
						type: 'single-datepicker',
						label: i18n.t('validto'),
						language: 'sk',
						showtime: false,
						autoselect: true,
						required: true,
						model: 'UproductDateEnd',
						styleClasses: 'col-xs-12 first-input',
						validator: validators.validateDateTo,
					},
					{
						type: 'input',
						label: i18n.t('adddays'),
						inputType: 'number',
						model: 'addDaysToDateRange',
						min: 0,
						styleClasses: 'col-xs-12 second-input',
						onChanged(model, value) {
							if (model.UproductDateEnd && !isNaN(value) && value > 0) {
								let date = new Date(model.UproductDateEnd);
								let newDate = date.setDate(date.getDate() + value);
								model.UproductDateEnd = new Date(newDate);
							}
						},
					},
				],
			},
			{
				fields: [
					{
						type: 'formated-input',
						label: i18n.t('totalprice2432'),
						model: 'UproductPrice',
						required: true,
						validator: vueFormGenerator.validators.number,
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'VariabelSymbol',
						label: i18n.t('variablesymbol'),
						styleClasses: 'half-width',
						visible(model) {
							return model && model.Activation != 1;
						},
					},
					{
						type: 'radios-group',
						label: i18n.t('iwantto'),
						required: true,
						model: 'Activation',
						dataval: [
							{ text: i18n.t('activateitforfr'), value: 1, buttonValue: i18n.t('addthepackagefo') },
							{ text: i18n.t('dontactivateand'), value: 2, buttonValue: i18n.t('addthepackagedo') },
							{ text: i18n.t('activateandcrea'), value: 3, buttonValue: i18n.t('addthepackageac') },
						],
						groupname: '1',
						styleClasses: 'vertical-radios-group',
						required: true,
						validator: vueFormGenerator.validators.number,
						visible(model) {
							return model && (model.InvoiceId === undefined || model.InvoiceId === null);
						},
					},
				],
			},
		],

		editFields: [
			{
				fields: [
					{
						type: 'select',
						label: i18n.t('packagename2427'),
						model: 'ProductId',
						styleClasses: 'threequarter-width',
						values: [],
						required: true,
						selectOptions: {
							noneSelectedText: i18n.t('select'),
						},
					},
					{
						type: 'label',
						label: i18n.t('created2428'),
						model: 'UproductDatetimeCreated',
						get: function(model) {
							return model && model.UproductDatetimeCreated
								? formatter.dateTimeToString(model.UproductDatetimeCreated)
								: '';
						},
					},
					{
						type: 'single-datepicker',
						label: i18n.t('validfrom'),
						model: 'UproductDateStart',
						language: 'sk',
						showtime: false,
						autoselect: true,
						required: true,
						validator: validators.validateDateFrom,
						onChanged(model, value) {
							if (value) {
								let newDateFrom = new Date(value);
								let newDate = newDateFrom.setMonth(newDateFrom.getMonth() + model.ProductMonths);
								model.UproductDateEnd = new Date(newDate);
							}
						},
					},
				],
			},
			{
				styleClasses: 'col-xs-12 inline-input-group-date',
				fields: [
					{
						type: 'single-datepicker',
						label: i18n.t('validto'),
						language: 'sk',
						showtime: false,
						required: true,
						autoselect: true,
						model: 'UproductDateEnd',
						styleClasses: 'col-xs-12 first-input',
						validator: validators.validateDateTo,
					},
					{
						type: 'input',
						label: i18n.t('adddays'),
						inputType: 'number',
						model: 'addDaysToDateRange',
						min: 0,
						styleClasses: 'col-xs-12 second-input',
						onChanged(model, value) {
							if (model.UproductDateEnd && !isNaN(value) && value > 0) {
								let date = new Date(model.UproductDateEnd);
								let newDate = date.setDate(date.getDate() + value);
								model.UproductDateEnd = new Date(newDate);
							}
						},
					},
				],
			},
			{
				fields: [
					{
						type: 'formated-input',
						label: i18n.t('totalprice2432'),
						model: 'UproductPrice',
						required: true,
						validator: vueFormGenerator.validators.number,
					},
					paymentStatus(i18n),

					{
						type: 'invoice-link-wrapper',
						label: i18n.t('invoice'),
						model: 'InvoiceLink',
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'VariabelSymbol',
						label: i18n.t('variablesymbol'),
						styleClasses: 'half-width',
						visible(model) {
							return model && model.InvoiceNumber != null;
						},
					},
				],
			},
		],
	};
}

export default groups;
