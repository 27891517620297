var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rating-container" },
    [
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.RattrId,
            staticClass: "row-rating d-flex align-items-center mb-2",
          },
          [
            _c("div", { staticClass: "label-rating" }, [
              _vm._v(_vm._s(_vm.labelName(item))),
            ]),
            _c("rating", {
              attrs: {
                "item-size": _vm.itemSize,
                "max-rating": _vm.maxRating,
                "inactive-color": "var(--mu-gray)",
                "active-color": "var(--primary)",
                "show-rating": false,
                glyph: _vm.starIcon,
                spacing: _vm.starSpacing,
                "border-width": _vm.borderWidth,
              },
              model: {
                value: _vm.value[item.RattrId],
                callback: function ($$v) {
                  _vm.$set(_vm.value, item.RattrId, $$v)
                },
                expression: "value[item.RattrId]",
              },
            }),
            _c("span", { staticClass: "ml-3" }, [
              _vm._v(_vm._s(_vm.value[item.RattrId])),
            ]),
          ],
          1
        )
      }),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "label-rating" }, [
          _vm._v(_vm._s(_vm.$t("overallrating:"))),
        ]),
        _c(
          "div",
          { staticClass: "summary-rating d-flex align-items-center" },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-2",
              attrs: { icon: "star", size: "lg" },
            }),
            _c("span", [_vm._v(_vm._s(_vm.initSummary))]),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }