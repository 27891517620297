var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "label-container" },
    [
      _c("div", { staticClass: "break" }),
      _vm._l(this.schema.items, function (groupOption, index) {
        return _c(
          "div",
          { key: index, staticClass: "container" },
          [
            _vm._l(groupOption, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("label", [_c("b", [_vm._v(_vm._s(item.id))])]),
                _c("label", [_vm._v(" ")]),
                _c("label", [_vm._v(" " + _vm._s(item.value))]),
              ])
            }),
            _c("div", { staticClass: "break" }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }