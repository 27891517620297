var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
      attrs: { id: "perPageSelect" },
    },
    [
      _c("b-form-select", {
        attrs: { options: _vm.initOptions },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.perPageLimit,
          callback: function ($$v) {
            _vm.perPageLimit = $$v
          },
          expression: "perPageLimit",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }