var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showContact
    ? _c(
        "div",
        { staticClass: "contact-wrapper" },
        [
          _c("div", { staticClass: "left-wrapper" }, [
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "user", size: _vm.iconSize },
                }),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.value.ContactName) +
                      "\n\t\t\t\t" +
                      _vm._s(_vm.value.ContactSurname) +
                      "\n\t\t\t\t"
                  ),
                  _vm.contactDetailLink
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.contactDetailLink,
                            target: "_blank",
                          },
                        },
                        [_vm._v("P" + _vm._s(_vm.value.UserId))]
                      )
                    : _c("span", [_vm._v("P" + _vm._s(_vm.value.UserId))]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "envelope", size: _vm.iconSize },
                }),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:" + _vm.value.ContactEmailLogin,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.value.ContactEmailLogin))]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "phone-square-alt", size: _vm.iconSize },
                }),
                _c(
                  "div",
                  { staticClass: "phone-list" },
                  _vm._l(_vm.value.ContactPhonesList, function (phone) {
                    return _c(
                      "a",
                      { key: phone, attrs: { href: "tel:" + phone } },
                      [_vm._v(_vm._s(phone))]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "right-wrapper" }, [
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "user", size: _vm.iconSize },
                }),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.value.ObjectContactPerson) +
                      "\n\t\t\t\t"
                  ),
                  _c(
                    "a",
                    { attrs: { href: _vm.objectDetailLink, target: "_blank" } },
                    [_vm._v("O" + _vm._s(_vm.value.ObjectId))]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "envelope", size: _vm.iconSize },
                }),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:" + _vm.value.ObjectEmailForReservations,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.value.ObjectEmailForReservations))]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "phone-square-alt", size: _vm.iconSize },
                }),
                _c(
                  "div",
                  { staticClass: "phone-list" },
                  _vm._l(this.objectPhoneList, function (phone) {
                    return _c(
                      "a",
                      { key: phone, attrs: { href: "tel:" + phone } },
                      [_vm._v(_vm._s(phone))]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  class: _vm.iconClass,
                  attrs: { icon: "globe", size: _vm.iconSize },
                }),
                _c(
                  "a",
                  { attrs: { href: _vm.value.ObjectWeb, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.value.ObjectWeb))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "backlinks-list" },
              [
                !_vm.value.ObjectWebBacklinks.length
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("nobacklink")))])
                  : _vm._l(_vm.value.ObjectWebBacklinks, function (backlink) {
                      return _c("div", { key: backlink.Id }, [
                        backlink.State == 1
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: backlink.linkToDetail,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("backlinkfrom")) +
                                      " " +
                                      _vm._s(_vm.backlinkDate) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        backlink.State == 2
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: backlink.linkToDetail,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("waitingforaddfi")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        backlink.State == 3
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: backlink.linkToDetail,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("wewanttoget")) + " ")]
                              ),
                            ])
                          : _vm._e(),
                        backlink.State == 4
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: backlink.linkToDetail,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("linkscanceledno")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
              ],
              2
            ),
          ]),
          _c("b-textarea", {
            ref: "textArea",
            staticClass: "contact-note",
            attrs: { rows: "3" },
            model: {
              value: _vm.value.InvoiceNote,
              callback: function ($$v) {
                _vm.$set(_vm.value, "InvoiceNote", $$v)
              },
              expression: "value.InvoiceNote",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }