<template>
	<div class="rating-container">
		<div class="row-rating d-flex align-items-center mb-2" v-for="item in items" v-bind:key="item.RattrId">
			<div class="label-rating">{{ labelName(item) }}</div>
			<rating
				v-model="value[item.RattrId]"
				:item-size="itemSize"
				:max-rating="maxRating"
				inactive-color="var(--mu-gray)"
				active-color="var(--primary)"
				:show-rating="false"
				:glyph="starIcon"
				:spacing="starSpacing"
				:border-width="borderWidth"
			></rating>
			<span class="ml-3">{{ value[item.RattrId] }}</span>
		</div>

		<div class="d-flex align-items-center">
			<div class="label-rating">{{ $t('overallrating:') }}</div>
			<div class="summary-rating d-flex align-items-center">
				<font-awesome-icon icon="star" size="lg" class="mr-2"></font-awesome-icon>
				<span>{{ initSummary }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import { FaRating } from 'vue-rate-it';
import Star from 'vue-rate-it/glyphs/star';
import api from '../../services/api/dictionary.api';

export default {
	mixins: [abstractField],
	components: {
		rating: FaRating,
	},
	data() {
		return {
			items: [],
			itemSize: 17,
			maxRating: 10,
			starSpacing: 5,
			borderWidth: 0,
			starIcon: '',
			summaryRating: 0,
		};
	},
	created() {
		this.starIcon = Star;

		api.getList('ReviewAttribute').then((resp) => {
			this.items = resp.data;
		});
	},
	methods: {
		labelName(item) {
			let locale = this.$store.state.lang.locale;
			return locale === 'cs-CZ' ? item.RattrNameCz : locale === 'sk-SK' ? item.RattrNameSk : item.RattrNameEn;
		},
	},
	computed: {
		initSummary() {
			let summary = 0.0;
			for (let i = 0; i < this.items.length; i++) {
				summary = summary + parseFloat(this.value[this.items[i].RattrId]);
			}
			return (summary / this.items.length).toFixed(1);
		},
	},
};
</script>

<style lang="scss">
.rating-container {
	width: 100%;
	padding-top: $fgInputLabelPaddingtop;
	.label-rating {
		width: 30%;
	}
	.summary-rating {
		padding: 5px 10px;
		background-color: var(--primary);
		color: var(--white);
		font-size: 14px;

		span {
			font-size: 15px;
		}
	}
}
</style>
