var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "border-0 shadow-none link",
      attrs: { target: "_blank", href: "" + this.value.Href },
    },
    [_vm._v(_vm._s(this.value.Name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }