import formFields from './invoice-address.fields';

function detailModel(store, i18n) {
	return {
		controllerName: 'InvoiceAddress',
		UserInvoiceAddresses: [],
		isLoaded: false,
		isVisibleDetail: false,
		modelInvAddress: {
			InvaddrId: 0,
			GuserId: null,
			InvaddrDatetimeCreated: null,
			InvaddrDatetimeUpdated: null,
			InvaddrName: null,
			InvaddrStreet: null,
			InvaddrCity: null,
			InvaddrZip: null,
			InvaddrIco: '',
			InvaddrDic: '',
			InvaddrIcdph: '',
			InvaddrEmail: null,
			InvaddrPhone: null,
			InvaddrBankCode: null,
			InvaddrBankAccount: null,
			InvaddrIban: null,
			InvaddrSwift: null,
			InvaddrBankName: null,
			InvaddrBankAddress: null,
			CountryId: null,
			InvaddrIsPrimary: null,
			InvaddrNote: null,
			SendFa: null,
			Regenerate: false,
		},
		schemaInvAddress: {
			fields: formFields(store, i18n),
		},
		formOptionsInvAddress: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateDebounceTime: 500,
			validateAsync: true,
		},
	};
}

export { detailModel };
