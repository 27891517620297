import formFields from './accounting-company.fields';

function detailVfgModel(i18n) {
	return {
		controllerName: 'AccountingCompany',
		isLoaded: false,
		isVisibleDetail: false,
		modelAC: {
			AcompanyId: 0,
			AcompanyName: null,
			AcompanyStreet: null,
			AcompanyCity: null,
			AcompanyZip: null,
			AcompanyIco: null,
			AcompanyCountry: null,
			AcompanyDic: null,
			AcompanyIcdph: '',
			AcompanyEmail: '',
			AcompanyPhone: '',
			AcompanyBankCode: null,
			AcompanyBankAccount: null,
		},
		schemaAC: {
			fields: formFields.fieldsVfg(i18n),
		},
		formOptionsAC: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateDebounceTime: 500,
			validateAsync: true,
		},
	};
}

export { detailVfgModel };
