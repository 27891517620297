<template>
	<b-modal
		modal-class="invoice-address-modal"
		size="lg"
		v-model="showModal"
		centered
		:title="$t('billingaddress2448')"
		@hidden="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
	>
		<vue-form-generator
			:schema="schemaInvAddress"
			:model="modelInvAddress"
			:options="formOptionsInvAddress"
			ref="detailForm"
		></vue-form-generator>

		<template v-slot:modal-footer="{}">
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechangesandn')"
				@handleClick="checkGetUnpaidInvoices(false)"
			></megau-button>
			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('closewithoutsav2459')"
				@handleClick="close()"
			></megau-button>
		</template>
	</b-modal>
</template>

<script>
import vue from 'vue';

import serviceEnums from '../../../services/service/enums.service';
import serviceDictionary from '../../../services/service/dictionary.service';
import apiCommon from '../../../services/api/common.api';
import http from '../../../services/helpers/http';
import service from './invoice-adress.service';

import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);

export default {
	props: {
		isVisible: { type: Boolean, default: false },
		showQuestion: { type: Boolean, default: true },
		modelInvAddress: { type: Object, default: {} },
		schemaInvAddress: { type: Object, default: {} },
		formOptionsInvAddress: { type: Object, default: {} },
	},
	data() {
		return {
			controllerName: 'InvoiceAddress',
			showModal: false,
		};
	},
	computed: {
		detailTitle() {
			return (
				this.$i18n.t('detail fakturacnej adresy preklad') + ' ' + this.$i18n.t('id') + ' ' + this.modelDetail.InvaddrId
			);
		},
		language: function() {
			return this.$i18n.locale;
		},
	},
	created() {
		var res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
	},
	watch: {
		language: function() {
			service.formTranslations(this.schemaInvAddress);
		},
		isVisible: function() {
			this.showModal = this.isVisible;
		},
	},
	methods: {
		question(invoiceNumbers) {
			let _this = this;
			this.$modal.show({
				title: this.$i18n.t('regeneratepdfof'),
				text: invoiceNumbers.toString(),
				okTitle: this.$i18n.t('yesregeneratean'),
				cancelTitle: this.$i18n.t('noidontwanttoch'),
				modalClass: 'modal-danger',
				variant: 'danger',
				onConfirm() {
					_this.modelInvAddress.Regenerate = true;
					return _this.updateInvoice();
				},
				onCancel() {
					_this.modelInvAddress.Regenerate = false;
					return _this.updateInvoice();
				},
			});
		},
		close() {
			this.showModal = false;
			this.$emit('onClose');
		},
		async checkGetUnpaidInvoices(send) {
			var validateResult = this.$refs.detailForm.validate();
			validateResult.then((value) => {
				if (value.length == 0) {
					this.modelInvAddress.SendFa = send;
					if (this.showQuestion) {
						http.post('InvoiceAddress/CheckGetExistsUnpaidInvoices', this.modelInvAddress).then((response) => {
							if (response.data.length > 0) {
								this.question(response.data);
							} else {
								this.updateInvoice();
							}
						});
					} else {
						this.modelInvAddress.Regenerate = true;
						this.updateInvoice();
					}
				}
			});
		},
		async updateInvoice() {
			var update = http.put('InvoiceAddress/UpdateSingle', this.modelInvAddress);
			this.close();

			await update;
			this.$emit('onChanged');
		},
	},
};
</script>
