<template>
	<div class="d-flex flex-row justify-content-start align-items-center">
		<megau-button
			v-if="model && model.OreviewReservationId"
			classprop="btn btn-info"
			icon="calendar-alt"
			:tooltip="`${$t('pairedreservati')} ${model.OreviewReservationId}`"
			@handleClick="routeToReservation(model.OreviewReservationId)"
		></megau-button>
		<span v-else>{{ $t('3278') }}</span>

		<a v-if="user && model" class="ml-4" target="_blank" :href="reservationsUrl">
			{{ $t('seeallreservati3279') }}
		</a>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import * as links from '@/router/links';
import auth from '@/services/auth.service';

export default {
	name: 'click-actions',
	mixins: [abstractField],
	data() {
		return {
			user: auth.GetUser(),
		};
	},
	computed: {
		reservationsUrl() {
			const { origin } = new URL(this.model.ObjectUrl);
			const url = `${origin}/ubytovatel-prehlad-rezervacii/${this.model.ObjectId}?tkn=${this.user.AutologCode}`;
			return url;
		},
	},
	methods: {
		routeToReservation(itemId) {
			this.$router.push({
				name: links.objectReservationDetailName,
				params: { objectReservationId: itemId },
			});
		},
	},
};
</script>