<template>
	<div class="active-filters-wrapper">
		<span v-for="(filter, key) in activeFiltersAction" :key="key">{{ filter.label }}: {{ filter.value }}</span>

		<megau-button
			v-show="showButton"
			:title="$t('cancelallfilter')"
			classprop="btn btn-customdanger"
			icon="times-circle"
			@handleClick="removeAllFilters()"
		></megau-button>
	</div>
</template>

<script>
import megauButton from '../../components/common/button';

export default {
	components: { megauButton },

	props: {
		filters: { type: Object },
		filtersTranslations: { type: Object },
		filterDropdowns: { type: Object },
	},

	data() {
		return {
			filtersData: this.filters,
			translations: {
				...this.filtersTranslations,
				QuickFilter: this.$t('quicksearch'),
			},
			activeFilters: [],
		};
	},

	computed: {
		showButton() {
			return this.activeFilters.length > 0 ? true : false;
		},

		activeFiltersAction() {
			this.activeFilters = [];
			return this.filterOnlyActive();
		},
	},

	methods: {
		removeAllFilters() {
			this.$emit('handleClick');
		},

		filterOnlyActive() {
			for (const [key, value] of Object.entries(this.filters)) {
				if ((value || value === 0) && value !== '') {
					const translatedLabel = this.translations[key];

					if (typeof value === 'object') {
						this.handleNestedObject(value, translatedLabel);
					} else {
						const filterValue = _.has(this.filterDropdowns, key) ? this.handleFilterWithDropdownValue(key) : value;
						this.fillFilter(filterValue, translatedLabel);
					}
				}
			}

			return this.activeFilters;
		},

		handleFilterWithDropdownValue(index) {
			return this.findInDropdownOptions(this.filters[index], this.filterDropdowns[index]);
		},

		findInDropdownOptions(value, options) {
			for (let i = 0, l = options.length; i < l; i++) {
				if (options[i].options) {
					const found = this.findInDropdownOptions(value, options[i].options);
					if (found) {
						return found;
					}
				}
				if (options[i].value === value) {
					return options[i].text;
				}
			}

			return null;
		},

		handleNestedObject(nestedObj, translatedLabel) {
			if (_.has(nestedObj, 'startDate') && _.has(nestedObj, 'endDate')) {
				this.nestedObjectHandleDateTime(nestedObj, translatedLabel);
			} else {
				this.nestedObjectHandleOtherValues(nestedObj, translatedLabel);
			}
		},

		nestedObjectHandleOtherValues(nestedObj, translatedLabel) {
			let nestedVals = [];
			Object.keys(nestedObj).forEach((indx) => {
				if (nestedObj[indx] != null) {
					nestedVals.push(nestedObj[indx]);
				}
			});
			if (nestedVals.length > 0) {
				let value = nestedVals.join(' - ');
				this.fillFilter(value, translatedLabel);
			}
		},

		nestedObjectHandleDateTime(nestedObj, translatedLabel) {
			if (nestedObj.startDate != null && nestedObj.endDate != null) {
				let value = moment(nestedObj.startDate).format('DD.MM.YYYY') + ' - ' + moment(nestedObj.endDate).format('DD.MM.YYYY');

				this.fillFilter(value, translatedLabel);
			}
		},

		fillFilter(value, label) {
			this.activeFilters.push({
				label: label,
				value: value,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.active-filters-wrapper {
	display: flex;
	flex-wrap: wrap;
	> span {
		padding: $btnpadding;
		display: flex;
		border: 1px solid #dce8f2;
	}
}
</style>
