var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: this.wrapperClass }, [
    _c(
      "span",
      {
        class: _vm.linkInvoiceClass,
        on: {
          click: function ($event) {
            return _vm.getInvoiceFile(_vm.invoiceType.Invoice)
          },
        },
      },
      [_vm._v(_vm._s(_vm.formatInvoiceName(_vm.invoiceType.Invoice)))]
    ),
    _c(
      "span",
      {
        class: _vm.linkProformaClass,
        on: {
          click: function ($event) {
            return _vm.getInvoiceFile(_vm.invoiceType.Proforma)
          },
        },
      },
      [_vm._v(_vm._s(_vm.formatInvoiceName(_vm.invoiceType.Proforma)))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }