import moment from 'moment';
import { isEmpty } from 'lodash';

const utils = {
	arrayToDict(items, key) {
		var result = {};
		for (var i = 0, len = items.length; i < len; i++) {
			var item = items[i];
			var keyValue = item[key];
			if (keyValue || keyValue == 0) {
				result[keyValue] = item;
			}
		}
		return result;
	},

	arrayToDict: function(items, key, extraItems = []) {
		var result = {};
		if (items) {
			for (var i = 0, len = items.length; i < len; i++) {
				var item = items[i];
				var keyValue = item[key];
				if (keyValue || keyValue == 0) {
					result[keyValue] = item;
				}
			}
		}
		for (var i = 0, len = extraItems.length; i < len; i++) {
			var item = extraItems[i];
			var keyValue = item[key];
			if (keyValue || keyValue == 0) {
				result[keyValue] = item;
			}
		}

		return result;
	},
	convertItems(items, convertFunc, firstItem = null) {
		var result = [];

		if (firstItem) {
			result.push(firstItem);
		}

		if (items) {
			for (var i = 0, len = items.length; i < len; i++) {
				result.push(convertFunc(items[i]));
			}
		}

		return result;
	},

	returnDateOrNull(stringDate, format) {
		return stringDate ? moment(stringDate, format).toDate() : null;
	},

	returnStringDateOrNull(dateObject, format) {
		return dateObject ? moment(dateObject).format(format) : null;
	},

	isUndefined(property, key) {
		return typeof property[key] == 'undefined';
	},

	hasValue(value) {
		return typeof value != 'undefined' && value != null;
	},

	getErrorTextFromResponse(response) {
		//console.log('[http] getErrorTextFromResponse');
		//console.log(response);
		if (response) {
			if (response.data) {
				if (response.data.errors) {
					let text = '';

					for (var prop in response.data.errors) {
						text += response.data.errors[prop] + '\n';
					}
					return text;
				} else if (response.data.title) {
					return response.data.title;
				}
				return response.data;
			}
			return response;
		}

		return 'Unknown error';
	},

	setObjValuesToNull(obj) {
		Object.keys(obj).forEach(function(index) {
			if (obj[index] != null) {
				if (typeof obj[index] == 'object') {
					let nestedObj = obj[index];
					Object.keys(nestedObj).forEach(function(indx) {
						nestedObj[indx] = null;
					});
				} else {
					obj[index] = null;
				}
			}
		});
	},

	// TODO len jeden mapovaci switch medzi krajinou a jazykom by mal byt v apke
	getPropValue(obj, propBaseName, countryId) {
		const postfix = {
			1: 'Sk',
			2: 'Cz',
		};

		const propName = propBaseName + (postfix[countryId] || 'Sk');
		return obj[propName];
	},

	removeEmptyRecursive(obj, emptyValues = [null, undefined]) {
		return Object.fromEntries(
			Object.entries(obj)
				.map(([k, v]) => {
					let value = v;
					if (v === Object(v) && !(v instanceof Date)) {
						value = this.removeEmptyRecursive(v);
						if (isEmpty(value)) {
							value = null;
						}
					}
					return [k, value];
				})
				.filter(([k, v]) => !emptyValues.includes(v))
		);
	},
};

export default utils;
