import http from '@/services/helpers/http';

const getTranslations = (langCode) => {
	return http.get(
		'Translation/GetTranslations',
		{
			groupId: 158,
			languageCode: langCode,
		},
		false
	);
};

export default {
	getTranslations,
};
