<template>
	<div class="note-wrapper">
		<b-modal
			v-model="showNoteModal"
			:title="$t('remark2469')"
			size="lg"
			no-close-on-backdrop
			centered
			@hidden="closeModal()"
		>
			<b-textarea ref="textArea" rows="4" v-model="invoiceNoteText"></b-textarea>

			<template v-slot:modal-footer>
				<megau-button
					icon="check"
					:title="$t('savenote')"
					@handleClick="handleUpdateInvoiceNote"
					classprop="btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDeleteInvoiceNote"
					:title="$t('deletenote')"
					classprop="btn btn-danger"
				></megau-button>
			</template>
		</b-modal>
		<div>
			<div
				v-show="showInvoiceNote"
				@click="editable ? showNoteModal = true : showNoteModal = false"
				v-b-tooltip.hover
				:title="this.invoiceNote"
			>
				<font-awesome-icon :icon="['far', 'sticky-note']" size="lg" cursor="pointer"></font-awesome-icon>
			</div>
		</div>
	</div>
</template>

<script>
import service from './invoice.service.js';
import http from '../../services/helpers/http';
import { EventBus } from '../../services/helpers/event-bus.js';

export default {
	props: {
		invoiceNote: { type: String },
		invoiceId: { type: Number },
		refreshMethod: { type: Function },
		editable: { type: Boolean, default: true },
	},
	data() {
		return {
			showNoteModal: false,
			invoiceNoteText: this.invoiceNote,
		};
	},
	computed: {
		showInvoiceNote() {
			return this.invoiceNote ? true : false;
		},
	},
	methods: {
		handleDeleteInvoiceNote() {
			if (this.invoiceNoteText) {
				service.handleDeleteWithModal(this.invoiceId, this.$modal, this.closeAndEmitModal);
			}
		},
		async handleUpdateInvoiceNote() {
			if (this.invoiceNoteText) {
				await http.put('Invoice/PutInvoiceNote', { invoiceId: this.invoiceId, invoiceNote: this.invoiceNoteText });
				EventBus.$emit('change-note');
				this.closeModal();
			}
		},
		closeModal() {
			this.showNoteModal = false;
			this.refreshMethod();
		},
		closeAndEmitModal() {
			this.closeModal();
			EventBus.$emit('change-note');
		},
	},
	watch: {
		invoiceNote() {
			this.invoiceNoteText = this.invoiceNote;
		},
	},
};
</script>

