var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-client-table", {
    attrs: { columns: _vm.columns, data: _vm.value, options: _vm.options },
    scopedSlots: _vm._u([
      {
        key: "Date",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("dateTime")(list.row.Date)))]
        },
      },
      {
        key: "Rating",
        fn: function (list) {
          return [
            _c(
              "div",
              {
                staticClass: "summary-rating d-flex align-items-center pointer",
                on: {
                  click: function ($event) {
                    return _vm.routeToReview(list.row.ReviewId)
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "star", size: "lg" },
                }),
                _c("span", [_vm._v(_vm._s(_vm.formatRating(list.row.Rating)))]),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "Text",
        fn: function (list) {
          return [
            _c("div", {
              staticClass: "note-wrapper",
              domProps: { innerHTML: _vm._s(list.row.Text) },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }