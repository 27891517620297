var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("megau-button", {
    attrs: {
      classprop: "btn btn-success blue mr-1 ml-1",
      icon: this.schema.icon,
      tooltip: _vm.getTooltip(),
    },
    on: {
      handleClick: function ($event) {
        return _vm.routeToPreview()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }