var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("products-table", {
        key: _vm.tableKey,
        attrs: {
          objectId: _vm.objectId,
          invoiceId: _vm.invoiceId,
          editable: _vm.editable,
          userId: _vm.userId,
          markCurrentPackage: _vm.markCurrentPackage,
        },
        on: { editproduct: _vm.editProd },
      }),
      _c("products-detail", {
        attrs: {
          isVisible: this.isVisibleDetail,
          uProductId: this.productId,
          userId: this.userId,
          objectId: this.objectId,
          invoiceId: _vm.invoiceId,
          objectCountryId: this.objectCountryId,
        },
        on: { onClose: _vm.closeDetail, onUpdate: _vm.forceReRender },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }