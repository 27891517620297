import api from './api/auth.api';
import axios from 'axios';
import enums from '../services/helpers/enums';

const userTokenKey = 'user-token';
const userRefreshTokenKey = 'refresh-token';
const userTokenExpiredKey = 'token-expired';
const userKey = 'user';
let user = JSON.parse(localStorage.getItem(userKey));

var tokenRefreshPromise = null;

const allowedRoles = {
	[enums.role.Owner]: [enums.role.Owner],

	[enums.role.Admin]: [enums.role.Admin, enums.role.Owner],

	[enums.role.TeamLeader]: [enums.role.Admin, enums.role.Owner, enums.role.TeamLeader],

	[enums.role.Operator]: [enums.role.Admin, enums.role.Owner, enums.role.TeamLeader, enums.role.Operator],

	[enums.role.CopyWriterSenior]: [
		enums.role.Admin,
		enums.role.Owner,
		enums.role.TeamLeader,
		enums.role.Operator,
		enums.role.CopyWriterSenior,
	],

	[enums.role.CopyWriterJunior]: [
		enums.role.Admin,
		enums.role.Owner,
		enums.role.TeamLeader,
		enums.role.Operator,
		enums.role.CopyWriterSenior,
		enums.role.CopyWriterJunior,
	],
};

function resetTokenRefreshPromise() {
	// reset tokenRefreshPromise after 2s to avoid repeating token refreshing in short period of time
	setTimeout(() => (tokenRefreshPromise = null), 2000);
}

const auth = {
	StartUpSetup() {
		let token = localStorage.getItem(userTokenKey);
		if (token) {
			axios.defaults.headers.common['Authorization'] = token;
		}
	},

	GetUser() {
		return user;
	},

	IsAuthenticated() {
		var result = !!localStorage.getItem(userTokenKey);
		if (result === true) {
			return api.isAuthenticated().then((res) => res.data);
		}
		return Promise.resolve(false);
	},

	ShouldRefreshToken(error) {
		var result = error.response.headers[userTokenExpiredKey] == 'true';

		console.log('[AUTH] ShouldRefreshToken: ' + result);
		return result;
	},

	Login(loginEmail, password) {
		console.log('[AUTH] Login: ' + loginEmail);

		return new Promise(function(resolve, reject) {
			api.login(loginEmail, password)
				.then((resp) => {
					console.log('[AUTH] Login: success');

					user = resp.data;
					localStorage.setItem(userTokenKey, user.Token);
					localStorage.setItem(userRefreshTokenKey, user.RefreshToken);
					localStorage.setItem(userKey, JSON.stringify(user));

					axios.defaults.headers.common['Authorization'] = user.Token;

					resolve(resp);
				})
				.catch((err) => {
					console.log('[AUTH] Login: failed');
					console.log(err);

					user = null;

					localStorage.removeItem(userTokenKey);
					localStorage.removeItem(userRefreshTokenKey);
					localStorage.removeItem(userKey);

					delete axios.defaults.headers.common['Authorization'];

					reject(err);
				});
		});
	},

	Logout() {
		user = null;
		localStorage.removeItem(userTokenKey);
		localStorage.removeItem(userRefreshTokenKey);
		localStorage.removeItem(userKey);

		delete axios.defaults.headers.common['Authorization'];
	},

	RefreshToken() {
		console.log('[AUTH] RefreshToken');

		var entity = new Object({
			token: localStorage.getItem(userTokenKey),
			refreshToken: localStorage.getItem(userRefreshTokenKey),
		});

		return new Promise(function(resolve, reject) {
			if (tokenRefreshPromise == null) {
				console.log('[AUTH] RefreshToken: api call');
				tokenRefreshPromise = api.getRToken(entity);
				tokenRefreshPromise
					.then((response) => {
						resetTokenRefreshPromise();
						if (response.status === 200) {
							localStorage.setItem(userTokenKey, response.data);

							console.log('[AUTH] RefreshToken: success');
							resolve(response);
						} else {
							console.log('[AUTH] RefreshToken: failed with status = ' + response.status);

							reject(response);
						}
					})
					.catch((error) => {
						resetTokenRefreshPromise();

						console.log('[AUTH] RefreshToken: failed');
						console.log(error);

						reject(error);
					});
			} else {
				console.log('[AUTH] RefreshToken: already refreshing');
				tokenRefreshPromise.then((response) => resolve(response)).catch((error) => reject(error));
			}
		});
	},

	IsAllowedForUser(requiredPermission) {
		if (!user) {
			return false;
		}

		if (requiredPermission) {
			let roleId = user.RoleId;
			// roleId = enums.role.CopyWriterSenior;
			if (allowedRoles[requiredPermission]) {
				return allowedRoles[requiredPermission].includes(roleId);
			}
			return false;
		} else {
			return true;
		}
	},
};

export default auth;
