const tableVars = {
	defaultPerPage: 50,
	debounceTime: 700,
	paginationSetting: {
		chunk: 15,
		dropdown: false,
		nav: 'fixed',
		edge: 'true',
	},
	sortIcons: {
		base: 'fa',
		is: 'fa-sort',
		up: 'fa-sort-asc',
		down: 'fa-sort-desc',
	},
};

export default {
	tableVars,
};
