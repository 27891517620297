var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "file-select mb-0" }, [
    _c(
      "div",
      { staticClass: "select-button" },
      [
        _c("megau-button", {
          attrs: {
            classprop: "btn btn-secondary btn-upload",
            icon: "file",
            title: _vm.$t("uploadanimage"),
          },
          on: {
            handleClick: function ($event) {
              return _vm.openfileDialog()
            },
          },
        }),
      ],
      1
    ),
    _c("label", [_vm._v("  ")]),
    _vm.file ? _c("span", [_vm._v(" " + _vm._s(_vm.file.name))]) : _vm._e(),
    _c("div", { attrs: { id: "preview" } }, [
      _vm.url ? _c("img", { attrs: { src: _vm.url } }) : _vm._e(),
    ]),
    _c("input", {
      attrs: { id: "fileInput", accept: "image/*", type: "file" },
      on: { change: _vm.handleFileChange },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }