var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.entityId > 0
    ? _c("div", [
        _vm.isModal
          ? _c("div", [
              _c(
                "div",
                { staticClass: "remarkModal" },
                [
                  _c(
                    "div",
                    { staticClass: "dataRemarkModal" },
                    [
                      _c("date-range-picker", {
                        ref: "datepickerModal",
                        staticClass: "dtRemark",
                        attrs: {
                          dateRange: _vm.dateRange,
                          "locale-data": _vm.locale,
                          singleDatePicker: true,
                          timePicker: true,
                          timePicker24Hour: true,
                          ranges: false,
                          autoApply: true,
                          "time-picker-increment": 5,
                          showDropdowns: true,
                        },
                        on: { update: _vm.updateValue },
                      }),
                      _c("font-awesome-icon", {
                        staticClass: "calendar-icon",
                        attrs: { icon: "calendar-alt", cursor: "pointer" },
                        on: { click: _vm.clickDateIcon },
                      }),
                      _vm.remarkTypeId != 2
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "dtRemark",
                              attrs: { text: _vm.selectedOperator.name },
                            },
                            _vm._l(_vm.administratorList, function (operator) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: operator.id,
                                  attrs: { value: operator.name },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operatorSelected(operator)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(operator.name))])]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("b-textarea", {
                    ref: "textArea",
                    attrs: { rows: "12" },
                    model: {
                      value: _vm.dataText,
                      callback: function ($$v) {
                        _vm.dataText = $$v
                      },
                      expression: "dataText",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isModal
          ? _c("div", [
              _c("div", { staticClass: "remark" }, [
                _c("span", { staticClass: "titleRemark" }, [
                  _vm._v(_vm._s(_vm.detailTitle)),
                ]),
                _c(
                  "div",
                  { staticClass: "dataRemark" },
                  [
                    _vm.isLoaded
                      ? _c(
                          "div",
                          [
                            _c("date-range-picker", {
                              ref: "datepicker",
                              staticClass: "dtRemark",
                              attrs: {
                                dateRange: _vm.dateRange,
                                "locale-data": _vm.locale,
                                singleDatePicker: true,
                                timePicker: true,
                                timePicker24Hour: true,
                                ranges: false,
                                autoApply: true,
                                "time-picker-increment": 5,
                                showDropdowns: true,
                              },
                              on: { update: _vm.updateValue },
                            }),
                            _c("font-awesome-icon", {
                              staticClass: "calendar-icon",
                              attrs: {
                                icon: "calendar-alt",
                                cursor: "pointer",
                              },
                              on: { click: _vm.clickDateIcon },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "dtRemark",
                        attrs: { text: _vm.selectedOperator.name },
                      },
                      _vm._l(_vm.administratorList, function (operator) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: operator.id,
                            attrs: { value: operator.name },
                            on: {
                              click: function ($event) {
                                return _vm.operatorSelected(operator)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(operator.name))])]
                        )
                      }),
                      1
                    ),
                    _c("b-textarea", {
                      ref: "textArea",
                      attrs: { rows: "8" },
                      model: {
                        value: _vm.dataText,
                        callback: function ($$v) {
                          _vm.dataText = $$v
                        },
                        expression: "dataText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("saveremark"),
                      classprop: "btnRemark btn btn-success",
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.handleInsertOrUpdate(false)
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("saveandreturnto"),
                      classprop: "btnRemark btn btn-success",
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.handleInsertOrUpdate(true)
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deleteremark"),
                      classprop: "btnRemark btn btn-danger",
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.handleDelete(false)
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deleteandreturn"),
                      classprop: "btnRemark btn btn-danger",
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.handleDelete(true)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }