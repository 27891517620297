import axios from 'axios';
import qs from '@/services/helpers/qs';

const DEFAULT_HEADERS = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': process.env.VUE_APP_ROOT_ORIGIN,
	'Access-Control-Allow-Credentials': 'true',
};

const baseUrl = process.env.VUE_APP_ROOT_API;

/*
 * Returns default headers list
 * which will be used with every request.
 */

const http = {
	getHeaders(useToken) {
		let defaultHeaders = DEFAULT_HEADERS;

		if (useToken) {
			let tkn = localStorage.getItem('user-token');
			if (tkn) {
				defaultHeaders = {
					Authorization: `Bearer ${tkn}`,
					...defaultHeaders,
				};
			}
		}

		return defaultHeaders;
	},
	getUrl(url, data = {}) {
		console.log('[HTTP] getUrl: ' + url);
		if (Object.keys(data).length > 0) {
			url = `${url}?${qs.toUrl(data)}`;
		}

		return baseUrl + url;
	},

	get(url, data = {}, useToken = true) {
		console.log('[HTTP] get: ' + url);
		url = http.getUrl(url, data);

		return axios.get(url, {
			headers: this.getHeaders(useToken),
			withCredentials: true,
			baseURL: baseUrl,
		});
	},

	postFile(url, data) {
		console.log('[HTTP] postFile: ' + url);
		let headers = this.getHeaders(true);
		headers['Content-Type'] = 'multipart/form-data';
		return axios.post(url, data, {
			headers: headers,
			withCredentials: true,
			baseURL: baseUrl,
		});
	},

	post(url, data, useToken = true) {
		console.log('[HTTP] post: ' + url);
		return axios.post(url, data, {
			headers: this.getHeaders(useToken),
			withCredentials: true,
			baseURL: baseUrl,
		});
	},

	put(url, data, useToken = true) {
		console.log('[HTTP] put: ' + url);
		return axios.put(url, data, {
			headers: this.getHeaders(useToken),
			withCredentials: true,
			baseURL: baseUrl,
		});
	},

	remove(url, data = {}, useToken = true) {
		console.log('[HTTP] remove: ' + url);
		if (Object.keys(data).length > 0) {
			url = `${url}?${qs.toUrl(data)}`;
		}
		return axios.delete(url, {
			headers: this.getHeaders(useToken),
			withCredentials: true,
			baseURL: baseUrl,
		});
	},

	callRequest(request, useToken = true) {
		console.log('[HTTP] callRequest: ' + request.url);
		request.headers = this.getHeaders(useToken);
		return axios.request(request);
	},

	getWithFilters(url, data = {}, useToken = true) {
		if (Object.keys(data).length > 0) {
			url = `${url}?${qs.toFilterUrl(data)}`;
		}

		return axios.get(url, {
			headers: this.getHeaders(useToken),
			withCredentials: true,
			baseURL: baseUrl,
		});
	},
};

export default http;
