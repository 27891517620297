var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "map-title-wrapper" },
        [
          _c("label", { staticClass: "map-title" }, [
            _vm._v(_vm._s(_vm.$t("locationonthema"))),
          ]),
          _c("gmap-autocomplete", {
            attrs: {
              id: "searchTextField",
              placeholder: _vm.$t("enterlocation"),
            },
            on: { place_changed: _vm.setPlace },
          }),
          _c("label", [_vm._v("   ")]),
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.addMarker } },
            [_vm._v(_vm._s(_vm.$t("find")))]
          ),
          _c("br"),
        ],
        1
      ),
      _c("br"),
      _c("gmap-map", {
        ref: "map",
        staticClass: "google-map",
        attrs: { center: _vm.center, zoom: _vm.mapZoom },
        on: { zoom_changed: _vm.zoomChanged },
      }),
      _vm.boundariesEnabled
        ? _c("megau-button", {
            attrs: {
              classprop: "btn btn-customdanger google-map-btn",
              icon: "trash-alt",
              id: "delete-button",
              tooltip: _vm.$t("deletepolygon"),
            },
            on: { handleClick: _vm.deletePolygon },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }