<template>
	<div v-if="this.isLoaded">
		<megau-button classprop="btn" titlePosition="none" :title="this.buttonTitle" @handleClick="openModal()"></megau-button>
		<accounting-company-detail
			:isVisible="this.isVisibleDetail"
			:modelAC="this.modelAC"
			:schemaAC="this.schemaAC"
			:formOptionsAC="this.formOptionsAC"
			@onClose="closeDetail"
		/>
	</div>
</template>
<script>
import vue from 'vue';
import { abstractField } from 'vue-form-generator';

import apiCommon from '../../services/api/common.api';

import { detailVfgModel } from './accounting-company.models';

export default {
	mixins: [abstractField],
	name: 'accounting-company',
	created() {
		this.modelAC = this.value;
		this.isLoaded = true;
	},
	data() {
		return detailVfgModel(this.$i18n);
	},
	computed: {
		buttonTitle() {
			if (this.modelAC === undefined) {
				return '';
			}

			let ico =
				this.modelAC.AcompanyIco == '' || this.modelAC.AcompanyIco == null
					? ''
					: this.$i18n.t('companyid') + ' ' + this.modelAC.AcompanyIco;
			let dic =
				this.modelAC.AcompanyDic == '' || this.modelAC.AcompanyDic == null
					? ''
					: this.$i18n.t('taxid') + ' ' + this.modelAC.AcompanyDic;
			let icdph =
				this.modelAC.AcompanyIcdph == '' || this.modelAC.AcompanyIcdph == null
					? ''
					: this.$i18n.t('vatid') + ' ' + this.modelAC.AcompanyIcdph;

			return [
				this.modelAC.AcompanyName,
				this.modelAC.AcompanyStreet,
				this.modelAC.AcompanyZip,
				this.modelAC.AcompanyCity,
				ico,
				dic,
				icdph,
			]
				.filter((x) => typeof x == 'string' && x.length > 0)
				.join(', ');
		},
	},
	methods: {
		openModal() {
			this.isVisibleDetail = true;
		},
		closeDetail() {
			this.isVisibleDetail = false;
		},
	},
};
</script>
