var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "megauButton" }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: { hover: true },
          },
        ],
        class: _vm.buttonClass,
        attrs: { type: "button", title: _vm.tooltip, disabled: _vm.isDisabled },
        on: {
          click: function ($event) {
            return _vm.handleClick()
          },
        },
      },
      [
        _vm.title
          ? _c("span", { class: _vm.titleClass }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _vm.icon
          ? _c("font-awesome-icon", { attrs: { icon: _vm.icon, size: "lg" } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }