<template>
	<v-client-table :columns="columns" :data="value" :options="options">
		<template v-slot:Date="list">{{ list.row.Date | dateTime }}</template>
		<template v-slot:Rating="list">
			<div class="summary-rating d-flex align-items-center pointer" @click="routeToReview(list.row.ReviewId)">
				<font-awesome-icon icon="star" size="lg" class="mr-2"></font-awesome-icon>
				<span>{{ formatRating(list.row.Rating) }}</span>
			</div>
		</template>
		<template v-slot:Text="list">
			<div class="note-wrapper" v-html="list.row.Text"></div>
		</template>
	</v-client-table>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import * as links from '../.././router/links';

export default {
	mixins: [abstractField],
	name: 'reviewHistory',
	data() {
		return {
			columns: this.schema.visitorFeedback ? ['Date', 'Text', 'Rating'] : ['Date', 'Text'],
			options: {
				filterable: false,
				filterByColumn: true,
				headings: {
					Date: this.$t('dateandtime'),
					Text: this.$t('status3102'),
					Rating: this.$t('rating3103'),
				},
			},
		};
	},
	methods: {
		routeToReview(itemId) {
			if (itemId) {
				window.open('/object-reviews/' + itemId, '_blank');
			}
		},
		formatRating(num) {
			return Number(num).toFixed(1);
		},
	},
};
</script>

<style lang="scss">
.field-reviewHistory {
	.summary-rating {
		padding: 5px 10px;
		background-color: var(--primary);
		color: var(--white);
		width: 80px;
		font-size: 14px;

		span {
			font-size: 15px;
		}
	}
	.note-wrapper {
		white-space: break-spaces;
	}
	.table-responsive {
		min-height: auto;
		.VueTables__table > th:nth-child(1) {
			width: 10%;
		}
		.VueTables__table > th:nth-child(2) {
			width: 80%;
		}
		.VueTables__table > th:nth-child(3) {
			width: 10%;
		}
	}
	.VueTables--client {
		width: 100%;
	}
	.VuePagination {
		display: none;
	}
}
</style>
