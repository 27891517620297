<template>
	<ckeditor :editor="editor" v-model="value" :value="value" :config="editorConfig"></ckeditor>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';

import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import List from '@ckeditor/ckeditor5-list/src/list';
import Font from '@ckeditor/ckeditor5-font/src/font';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import AllowAttributes from '../../plugins/ckeditor-allow-atrtributes.js';
import LinkValues from '../../plugins/ckeditor-link-button.js';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	name: 'htmltexteditor',
	components: {
		ckeditor: CKEditor.component,
	},
	created() {
		this.setupImageUploadConfig();
	},
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {
				domainForText: this.model.DomainUrl,
				plugins: [
					Essentials,
					Bold,
					Italic,
					Underline,
					Link,
					Paragraph,
					Heading,
					Image,
					ImageCaption,
					ImageStyle,
					ImageToolbar,
					ImageResize,
					SimpleUploadAdapter,
					List,
					Font,
					RemoveFormat,
					Alignment,
					Indent,
					IndentBlock,
					AllowAttributes,
					LinkValues,
				],
				fontColor: {
					colors: [
						{
							color: 'hsl(0, 0%, 0%)',
							label: 'Black',
						},
						{
							color: 'hsl(0, 0%, 15%)',
							label: 'Black',
						},
						{
							color: 'hsl(0, 0%, 30%)',
							label: 'Dim grey',
						},
						{
							color: 'hsl(0, 0%, 45%)',
							label: 'Dim grey',
						},
						{
							color: 'hsl(0, 0%, 60%)',
							label: 'Grey',
						},
						{
							color: 'hsl(0, 0%, 75%)',
							label: 'Grey',
						},
						{
							color: 'hsl(0, 0%, 90%)',
							label: 'Light grey',
						},
						{
							color: 'hsl(0, 0%, 100%)',
							label: 'White',
							hasBorder: true,
						},
						{
							color: 'hsl(0, 75%, 60%)',
							label: 'Red',
						},
						{
							color: 'hsl(15, 75%, 60%)',
							label: 'Red',
						},
						{
							color: 'hsl(30, 75%, 60%)',
							label: 'Orange',
						},
						{
							color: 'hsl(45, 75%, 60%)',
							label: 'Orange',
						},
						{
							color: 'hsl(60, 75%, 60%)',
							label: 'Yellow',
						},
						{
							color: 'hsl(75, 75%, 60%)',
							label: 'Yellow',
						},
						{
							color: 'hsl(90, 75%, 60%)',
							label: 'Light green',
						},
						{
							color: 'hsl(105, 75%, 60%)',
							label: 'Light green',
						},
						{
							color: 'hsl(120, 75%, 60%)',
							label: 'Green',
						},
						{
							color: 'hsl(135, 75%, 60%)',
							label: 'Green',
						},
						{
							color: 'hsl(150, 75%, 60%)',
							label: 'Aquamarine',
						},
						{
							color: 'hsl(165, 75%, 60%)',
							label: 'Aquamarine',
						},
						{
							color: 'hsl(180, 75%, 60%)',
							label: 'Turquoise',
						},
						{
							color: 'hsl(195, 75%, 60%)',
							label: 'Turquoise',
						},
						{
							color: 'hsl(210, 75%, 60%)',
							label: 'Light blue',
						},
						{
							color: 'hsl(225, 75%, 60%)',
							label: 'Light blue',
						},
						{
							color: 'hsl(240, 75%, 60%)',
							label: 'Blue',
						},
						{
							color: 'hsl(255, 75%, 60%)',
							label: 'Blue',
						},
						{
							color: 'hsl(270, 75%, 60%)',
							label: 'Purple',
						},
					],
				},
				fontSize: {
					options: [9, 11, 'default', 17, 19, 21, 24, 28, 35, 40],
				},
				fontBackgroundColor: {
					colors: [
						{
							color: 'hsl(0, 0%, 0%)',
							label: 'Black',
						},
						{
							color: 'hsl(0, 0%, 15%)',
							label: 'Black',
						},
						{
							color: 'hsl(0, 0%, 30%)',
							label: 'Dim grey',
						},
						{
							color: 'hsl(0, 0%, 45%)',
							label: 'Dim grey',
						},
						{
							color: 'hsl(0, 0%, 60%)',
							label: 'Grey',
						},
						{
							color: 'hsl(0, 0%, 75%)',
							label: 'Grey',
						},
						{
							color: 'hsl(0, 0%, 90%)',
							label: 'Light grey',
						},
						{
							color: 'hsl(0, 0%, 100%)',
							label: 'White',
							hasBorder: true,
						},
						{
							color: 'hsl(0, 75%, 60%)',
							label: 'Red',
						},
						{
							color: 'hsl(15, 75%, 60%)',
							label: 'Red',
						},
						{
							color: 'hsl(30, 75%, 60%)',
							label: 'Orange',
						},
						{
							color: 'hsl(45, 75%, 60%)',
							label: 'Orange',
						},
						{
							color: 'hsl(60, 75%, 60%)',
							label: 'Yellow',
						},
						{
							color: 'hsl(75, 75%, 60%)',
							label: 'Yellow',
						},
						{
							color: 'hsl(90, 75%, 60%)',
							label: 'Light green',
						},
						{
							color: 'hsl(105, 75%, 60%)',
							label: 'Light green',
						},
						{
							color: 'hsl(120, 75%, 60%)',
							label: 'Green',
						},
						{
							color: 'hsl(135, 75%, 60%)',
							label: 'Green',
						},
						{
							color: 'hsl(150, 75%, 60%)',
							label: 'Aquamarine',
						},
						{
							color: 'hsl(165, 75%, 60%)',
							label: 'Aquamarine',
						},
						{
							color: 'hsl(180, 75%, 60%)',
							label: 'Turquoise',
						},
						{
							color: 'hsl(195, 75%, 60%)',
							label: 'Turquoise',
						},
						{
							color: 'hsl(210, 75%, 60%)',
							label: 'Light blue',
						},
						{
							color: 'hsl(225, 75%, 60%)',
							label: 'Light blue',
						},
						{
							color: 'hsl(240, 75%, 60%)',
							label: 'Blue',
						},
						{
							color: 'hsl(255, 75%, 60%)',
							label: 'Blue',
						},
						{
							color: 'hsl(270, 75%, 60%)',
							label: 'Purple',
						},
					],
				},
				toolbar: {
					shouldNotGroupWhenFull: true,
					items: [
						'undo',
						'redo',
						'|',
						'heading',
						'|',
						'bold',
						'italic',
						'underline',
						'link',
						'|',
						'alignment',
						'outdent',
						'indent',
						'|',
						'fontSize',
						'fontFamily',
						'fontColor',
						'fontBackgroundColor',
						'|',
						'bulletedList',
						'numberedList',
						'|',
						'removeFormat',
					],
				},
			},
		};
	},
	methods: {
		setupImageUploadConfig() {
			if (this.schema.imageUpload) {
				let editorConfig = this._data.editorConfig;
				editorConfig.plugins.push(ImageUpload);
				editorConfig.toolbar.items.push('|', 'imageUpload');
				editorConfig.fontSize = {
					options: [9, 11, 'default', 17, 19, 21, 24, 28, 35, 40],
				};
				editorConfig.image = {
					toolbar: [
						'imageStyle:alignLeft',
						'imageStyle:alignCenter',
						'imageStyle:alignRight',
						'|',
						'resizeImage:normal',
						'|',
						'resizeImage',
					],

					styles: ['alignLeft', 'alignCenter', 'alignRight'],

					resizeUnit: '%',
					resizeOptions: [
						{
							name: 'resizeImage:normal',
							value: '43.65',
							label: this.$t('predefinedsize'),
							icon: 'medium',
						},
						{
							name: 'resizeImage:original',
							value: null,
							label: this.$t('originalsize'),
						},
						{
							name: 'resizeImage:full-width',
							value: '100',
							label: this.$t('fullwidth'),
						},
					],
				};
				editorConfig.link = {
					// Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
					addTargetToExternalLinks: true,
				};
				editorConfig.simpleUpload = {
					uploadUrl:
						process.env.VUE_APP_ROOT_API +
						'SeoArticleImage/UploadImageForSeoArticle?seoArticleId=' +
						this.model.SparticleId +
						'&seoPageId=' +
						this.model.SpageId,
				};
			}
		},
	},
};
</script>

<style lang="scss">
/*
	We need to add this CSS custom property to the body instead of :root,
	because of CSS specificity.
*/
body {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}

/*
	Override Bootstrap's CSS.
	Note: this won't be necessary once the following issue is fixed and released:
	https://github.com/ckeditor/ckeditor5-theme-lark/issues/189
*/
.ck.ck-button {
	-webkit-appearance: none;
}
.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
	max-width: 100% !important;
}
.ck.ck-link-form .ck.ck-labeled-input,
.ck.ck-input.ck-input-text {
	width: 650px;
}
.ck-editor__editable_inline {
	min-height: 200px;
}
.field-html-text-editor {
	.ck-content {
		font-family: 'Montserrat', 'Open Sans', sans-serif !important;
		font-size: 13px;
		line-height: 22px;
		a {
			color: #76cd3b;
		}
	}
}
</style>
