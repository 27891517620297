import formFields from './sojourn-type-countries.fields';

function detailModel() {
	return {
		controllerName: 'ObjectSojournTypeCountry',
		schema: {
			groups: formFields.groups(),
		},
		formOptions: {
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
		},
		model: {
			Id: 0,
		},
	};
}

export { detailModel };