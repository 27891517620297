import i18n from '../../services/lang';

const service = {
	formVfgTranslations(schema) {
		schema.fields[0].label = i18n.t('companyname');
		schema.fields[1].label = i18n.t('streetandnumber');
		schema.fields[2].label = i18n.t('city');
		schema.fields[3].label = i18n.t('zip');
		schema.fields[4].label = i18n.t('country2453');
		schema.fields[5].label = i18n.t('companyid');
		schema.fields[6].label = i18n.t('taxid');
		schema.fields[7].label = i18n.t('vatid');
		schema.fields[8].label = i18n.t('phone');
		schema.fields[9].label = i18n.t('email');
		schema.fields[10].label = i18n.t('bankcode');
		schema.fields[10].label = i18n.t('bankaccount');
	},
};

export default service;
