<template>
	<invoice-link
		:iNumber="this.value.iType == 2 ? this.value.iNumber : ''"
		:iCode="this.value.iType == 2 ? this.value.iCode : ''"
		:iPFNumber="this.value.iType == 1 ? this.value.iNumber : ''"
		:iPFCode="this.value.iType == 1 ? this.value.iCode : ''"
		:iType="this.value.iType"
		:wrapperClass="this.invoiceClass()"
	></invoice-link>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	methods: {
		invoiceClass() {
			return !this.value.iNumber ? 'without-invoice' : '';
		},
	},
};
</script>
