var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-row justify-content-start align-items-center" },
    [
      _vm.model && _vm.model.OreviewReservationId
        ? _c("megau-button", {
            attrs: {
              classprop: "btn btn-info",
              icon: "calendar-alt",
              tooltip:
                _vm.$t("pairedreservati") +
                " " +
                _vm.model.OreviewReservationId,
            },
            on: {
              handleClick: function ($event) {
                return _vm.routeToReservation(_vm.model.OreviewReservationId)
              },
            },
          })
        : _c("span", [_vm._v(_vm._s(_vm.$t("3278")))]),
      _vm.user && _vm.model
        ? _c(
            "a",
            {
              staticClass: "ml-4",
              attrs: { target: "_blank", href: _vm.reservationsUrl },
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.$t("seeallreservati3279")) + "\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }