var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "b-modal",
        {
          class: _vm.modalClass,
          attrs: {
            title: _vm.title,
            "ok-variant": _vm.variant,
            centered: "",
            "cancel-title": _vm.cancelTitle,
            "ok-title": _vm.okTitle,
          },
          on: {
            ok: function ($event) {
              return _vm.confirm()
            },
            cancel: function ($event) {
              return _vm.hide()
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [_c("template", { slot: "default" }, [_vm._v(_vm._s(_vm.text))])],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }