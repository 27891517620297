<template>
	<div class="columns-group">
		<b-form-checkbox
			:value="item[valuePropName]"
			:name="item[textPropName]"
			:checked="isItemChecked(item)"
			v-for="item in schema.values"
			:key="item[valuePropName]"
			@change="onChanged($event, item)"
			>{{ item[textPropName] }}</b-form-checkbox
		>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import { isNil, clone } from 'lodash';
export default {
	mixins: [abstractField],
	computed: {
		valueAsArray: {
			get() {
				if (Number.isInteger(this.value)) {
					// console.debug('checkboxes - get - is number: ', [this.value]);
					return [this.value];
				} else if (typeof this.value === 'string') {
					const val = this.value.split(',');
					// console.debug('checkboxes - get - is string: ', Number.isInteger(val[0]) ? val.map(Number) : val);
					return Number.isInteger(val[0]) ? val.map(Number) : val;
				}

				// console.debug('checkboxes - get - as value: ', this.value);
				return this.value;
			},
			set(newVal, oldwal) {
				if (newVal === -1) {
					this.value = null;
				} else if (typeof newVal === 'boolean') {
					this.value = newVal;
				} else {
					this.value = newVal.join(',');
				}

				// console.debug('checkboxes - set: ', newVal, this.value);
			},
		},
		valuePropName() {
			return typeof this.schema.checklistOptions != 'undefined'
				? this.schema.checklistOptions.value
				: typeof this.schema.csectionOptions != 'undefined'
				? this.schema.csectionOptions.value
				: 'value';
		},
		textPropName() {
			return typeof this.schema.checklistOptions != 'undefined'
				? this.schema.checklistOptions.name
				: typeof this.schema.csectionOptions != 'undefined'
				? this.schema.csectionOptions.name
				: 'name';
		},
	},
	methods: {
		isItemChecked(item) {
			if (!this.valueAsArray) {
				return;
			}

			if (Array.isArray(this.valueAsArray)) {
				// `${item[this.valuePropName]}` converted to string, because of type strict comparision
				if (this.valueAsArray.includes(item[this.valuePropName]) || this.valueAsArray.includes(`${item[this.valuePropName]}`)) {
					return this.valueAsArray;
				}
			} else if (Number.isInteger(this.valueAsArray)) {
				if (this.valueAsArray == item[this.valuePropName]) {
					return [this.valueAsArray];
				}
			} else if (typeof this.valueAsArray === 'boolean') {
				if (this.valueAsArray == item[this.valuePropName]) {
					return this.valueAsArray;
				}
			}
		},
		onChanged(event, item) {
			// if (isNil(this.valueAsArray) || !Array.isArray(this.valueAsArray)) {
			// 	this.valueAsArray = [];
			// }

			if (typeof event === 'boolean') {
				this.valueAsArray = event;
			} else if (event != null) {
				const arr = clone(this.valueAsArray);

				if (arr.includes(item[this.valuePropName])) {
					arr.splice(this.valueAsArray.indexOf(item[this.valuePropName]), 1);
				} else {
					arr.push(item[this.valuePropName]);
				}

				//this.valueAsArray = arr;

				this.valueAsArray.splice(0, this.valueAsArray.length, ...arr);
				this.valueAsArray = this.valueAsArray;
			} else {
				const arr = clone(this.valueAsArray);
				arr.splice(this.valueAsArray.indexOf(item[this.valuePropName]), 1);
				this.valueAsArray = arr;
			}
		},
	},
};
</script>

<style lang="scss">
.field-checkboxes-group {
	.field-wrap > div {
		width: 100%;
	}

	.columns-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.custom-checkbox {
			flex-basis: 33%;
			margin-right: 0px !important;
			::before {
				top: 0.15rem !important;
			}
			::after {
				top: 0.15rem !important;
			}
		}
	}

	&.four-columns {
		.columns-group {
			.custom-checkbox {
				flex-basis: 21%;
			}
		}
	}
}
</style>
