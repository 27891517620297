<template>
	<div class="d-flex">
		<label @click.prevent="handleChange()">
			<input
				type="checkbox"
				v-model="value"
				:autocomplete="schema.autocomplete"
				:disabled="disabled"
				:name="schema.inputName"
				:id="getFieldID(schema)"
			/>
			<span
				:data-on="schema.textOn || 'On'"
				:data-off="schema.textOff || 'Off'"
				:for="getFieldID(schema)"
				class="label"
			/>
			<span class="handle" />
		</label>
		<span class="datechange">{{ schema.datechange }}</span>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import i18n from '../../services/lang';
export default {
	props: {
		titleActivate: { type: String, default: 'active2190' },
		titleDeactivate: { type: String, default: 'inactive2191' },
		textActivate: { type: String, default: 'yes2187' },
		textDeactivate: { type: String, default: 'no2188' },
	},
	mixins: [abstractField],
	methods: {
		formatValueToField(value) {
			if (value && this.schema.valueOn) return value === this.schema.valueOn;
			return value;
		},
		formatValueToModel(value) {
			if (value && this.schema.valueOn) {
				if (value) return this.schema.valueOn;
				else return this.schema.valueOff;
			}
			return value;
		},
		changeActive() {
			this.value = !this.value;
			this.schema.datechange = moment().format('DD.MM.YYYY HH:mm');
		},
		openActiveModal(changeActiveFnc) {
			this.$modal.show({
				title: this.value ? i18n.t(this.schema.titleDeactivate) : i18n.t(this.schema.titleActivate),
				text: this.value ? i18n.t(this.schema.textDeactivate) : i18n.t(this.schema.textActivate),
				modalClass: 'modal-danger',
				okTitle: this.$t('ok2344'),
				variant: 'danger',
				onConfirm() {
					return changeActiveFnc();
				},
			});
		},
		handleChange: function(e) {
			this.openActiveModal(this.changeActive);
		},
	},
};
</script>

<style lang="scss">
.vue-form-generator .field-switchAction {
	.datechange {
		margin: 5px 10px 10px 15px;
	}
}
@include custom-switch('.field-switchAction');
</style>
