var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "invoice-address-modal",
        size: "lg",
        centered: "",
        title: _vm.$t("billingaddress2448"),
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-enforce-focus": "",
      },
      on: {
        hidden: function ($event) {
          return _vm.close()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function (ref) {
            return [
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-success",
                  icon: "check",
                  title: _vm.$t("savechangesandn"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.checkGetUnpaidInvoices(false)
                  },
                },
              }),
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-primary",
                  icon: "undo",
                  title: _vm.$t("closewithoutsav2459"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("vue-form-generator", {
        ref: "detailForm",
        attrs: {
          schema: _vm.schemaInvAddress,
          model: _vm.modelInvAddress,
          options: _vm.formOptionsInvAddress,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }