var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-control border-0 shadow-none pl-0" }, [
    _c("span", { staticClass: "mr-3" }, [
      _vm._v(_vm._s(_vm._f("dateTime")(_vm.value))),
    ]),
    _c("span", [_vm._v(_vm._s(_vm.formatedUserName()))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }