<template>
	<b-modal
		modal-class="invoice-address-modal"
		size="lg"
		v-model="showModal"
		centered
		:title="$t('detailsofoursup')"
		@hidden="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
	>
		<vue-form-generator :schema="schemaAC" :model="modelAC" :options="formOptionsAC"></vue-form-generator>

		<template>
			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('closewithoutsav2459')"
				@handleClick="close()"
			></megau-button>
		</template>
	</b-modal>
</template>

<script>
import vue from 'vue';

import serviceEnums from '../../services/service/enums.service';
import serviceDictionary from '../../services/service/dictionary.service';
import apiCommon from '../../services/api/common.api';
import http from '../../services/helpers/http';
import service from './accounting-company.service';

import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);

export default {
	props: {
		isVisible: { type: Boolean, default: false },
		modelAC: { type: Object, default: () => {} },
		schemaAC: { type: Object, default: () => {} },
		formOptionsAC: { type: Object, default: () => {} },
	},
	data() {
		return {
			controllerName: 'AccountingCompany',
			showModal: false,
		};
	},
	computed: {
		detailTitle() {
			return this.$i18n.t('detail dodavatela preklad') + ' ' + this.$i18n.t('id') + ' ' + this.modelDetail.AcompanyId;
		},
		language: function () {
			return this.$i18n.locale;
		},
	},
	created() {
		var res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
	},
	watch: {
		language: function () {
			service.formVfgTranslations(this.schemaAC);
		},
		isVisible: function () {
			this.showModal = this.isVisible;
		},
	},
	methods: {
		close() {
			this.showModal = false;
			this.$emit('onClose');
		},
	},
};
</script>
