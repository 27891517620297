import utils from '../helpers/utils';
import i18n from '../lang';

const enums = {
	currency: [
		{ Id: 1, Text: 'EUR' },
		{ Id: 2, Text: 'CZK' },
	],

	countryLanguages: [
		{ Id: 1, Text: 'Slovensky' },
		{ Id: 2, Text: 'Česky' },
		{ Id: 3, Text: 'Poľsky' },
		{ Id: 4, Text: 'Anglicky' },
		{ Id: 5, Text: 'Nemecky' },
		{ Id: 6, Text: 'Maďarsky' },
	],

	countryLanguageCodes: [
		{ Id: 'sk', Text: 'Slovensky' },
		{ Id: 'cz', Text: 'Česky' },
		{ Id: 'pl', Text: 'Poľsky' },
		{ Id: 'en', Text: 'Anglicky' },
		{ Id: 'de', Text: 'Nemecky' },
		{ Id: 'hu', Text: 'Maďarsky' },
	],

	countryStatus: [
		{ Id: 0, Text: 'public' },
		{ Id: 1, Text: 'testmode' },
		{ Id: 2, Text: 'off' },
	],

	itemsPerPage: [
		{ Id: 25, Text: '25' },
		{ Id: 50, Text: '50' },
		{ Id: 75, Text: '75' },
		{ Id: 100, Text: '100' },
	],
	contactBads: [
		{ Id: 1, Text: 'wronge-mail' },
		{ Id: 2, Text: 'henolongerhasac2348' },
		{ Id: 3, Text: 'hedoesntwant' },
	],
	remark: [
		{ Id: 1, Text: 'nowoncall' },
		{ Id: 2, Text: 'todayandolderwi' },
		{ Id: 3, Text: 'tomorrowandolde' },
		{ Id: 4, Text: 'upto7daysandold' },
		{ Id: 5, Text: 'upto30daysorold' },
		{ Id: 6, Text: 'upto90daysandol' },
		{ Id: 7, Text: 'allwitharemark' },
		{ Id: 8, Text: 'allwithoutremar' },
	],
	remarkAccountant: [
		{ Id: 1, Text: 'nowoncall' },
		{ Id: 2, Text: 'todayandolderwi' },
		{ Id: 3, Text: 'tomorrowandolde' },
		{ Id: 4, Text: 'upto7daysandold' },
		{ Id: 5, Text: 'upto30daysorold' },
		{ Id: 6, Text: 'upto90daysandol' },
		{ Id: 7, Text: 'allwitharemark' },
		{ Id: 8, Text: 'allwithoutremar' },
		{ Id: 9, Text: 'nowoncall' },
		{ Id: 10, Text: 'todayandolderwi' },
		{ Id: 11, Text: 'tomorrowandolde' },
		{ Id: 12, Text: 'upto7daysandold' },
		{ Id: 13, Text: 'upto30daysorold' },
		{ Id: 14, Text: 'upto90daysandol' },
		{ Id: 15, Text: 'allwitharemark' },
		{ Id: 16, Text: 'allwithoutremar' },
	],
	contactType: [
		{ Id: 1, Text: 'potential' },
		{ Id: 2, Text: 'member' },
		{ Id: 3, Text: 'visitor' },
	],
	pemailTypeFilter: [
		{ Id: 1, Text: 'technicalemails' },
		{ Id: 2, Text: 'marketingemails' },
		{ Id: 3, Text: 'marketingemails2987' },
	],
	yesNo: [
		{ Id: 1, Text: 'yes' },
		{ Id: 0, Text: 'no' },
	],
	memberRole: [
		{ Id: 6, Text: 'owner' },
		{ Id: 1, Text: 'admin' },
		{ Id: 5, Text: 'teamleader' },
		{ Id: 3, Text: 'operator' },
		{ Id: 7, Text: 'copywritersenio' },
		{ Id: 4, Text: 'lyricist' },
		{ Id: 2, Text: 'landlord2369' },
	],
	personType: [
		{ Id: 1, Text: 'visitor' },
		{ Id: 2, Text: 'landlord' },
	],
	operationSeason: [
		{ Id: 1, Text: 'yearly' },
		{ Id: 2, Text: 'inwinter' },
		{ Id: 3, Text: 'insummer' },
	],
	objectStateFilter: [
		{ Id: 'is_verified:1', Text: 'completed2389' },
		{ Id: 'is_verified:0', Text: 'notcompleted' },
		{ Id: 'condition:0', Text: 'normalobject' },
		{ Id: 'condition:1', Text: 'fakeobject' },
		{ Id: 'condition:2', Text: 'heisnolongeracc' },
		{
			Id: 'condition:3',
			Text: 'hedoesntwanttop',
		},
	],
	objectCondition: [
		{ Id: 0, Text: 'normalobject' },
		{ Id: 1, Text: 'fakeobject' },
		{ Id: 2, Text: 'heisnolongeracc' },
		{ Id: 3, Text: 'hedoesntwanttop' },
	],
	invoiceState: [
		{ Id: 1, Text: 'unpaidinvoices' },
		{ Id: 2, Text: 'paidinvoices' },
		{ Id: 3, Text: 'canceledinvoice' },
	],
	invoiceMaturityFilter: [
		{ Id: 1, Text: 'v splatnosti' },
		{ Id: 2, Text: 'dnes splatné' },
		{ Id: 3, Text: '3 a viac dní po splatnosti' },
		{ Id: 4, Text: '7 a viac dní po splatnosti' },
		{ Id: 5, Text: '14 a viac dní po splatnosti' },
		{ Id: 6, Text: '30 a viac dní po splatnosti' },
		{ Id: 7, Text: '60 a viac dní po splatnosti' },
		{ Id: 8, Text: 'všetky po splatnosti' },
	],
	InvoiceType: [
		{ Id: 1, Text: 'proformainvoice2476' },
		{ Id: 2, Text: 'invoices2477' },
	],
	dropdownCount: [
		{ Id: 0, Text: 'select' },
		{ Id: 1, Text: '1+' },
		{ Id: 2, Text: '2+' },
		{ Id: 3, Text: '3+' },
		{ Id: 4, Text: '4+' },
		{ Id: 5, Text: '5+' },
		{ Id: 6, Text: '6+' },
		{ Id: 7, Text: '7+' },
		{ Id: 8, Text: '8+' },
		{ Id: 9, Text: '9+' },
		{ Id: 10, Text: '10+' },
		{ Id: 11, Text: '11+' },
		{ Id: 12, Text: '12+' },
		{ Id: 13, Text: '13+' },
		{ Id: 14, Text: '14+' },
		{ Id: 15, Text: '15+' },
		{ Id: 16, Text: '16+' },
		{ Id: 17, Text: '17+' },
		{ Id: 18, Text: '18+' },
		{ Id: 19, Text: '19+' },
		{ Id: 20, Text: '20+' },
		{ Id: 21, Text: '21+' },
		{ Id: 22, Text: '22+' },
		{ Id: 23, Text: '23+' },
		{ Id: 24, Text: '24+' },
		{ Id: 25, Text: '25+' },
		{ Id: 26, Text: '26+' },
		{ Id: 27, Text: '27+' },
		{ Id: 28, Text: '28+' },
		{ Id: 29, Text: '29+' },
		{ Id: 30, Text: '30+' },
	],
	portalJobRunResult: [
		{ Id: 1, Text: 'running' },
		{ Id: 2, Text: 'successful' },
		{ Id: 3, Text: 'failed' },
		{ Id: 4, Text: 'fixed' },
	],
	urlSeoState: [
		{ Id: 0, Text: 'withoutarticle' },
		{ Id: 1, Text: 'articlesfortypi' },
		{ Id: 2, Text: 'addedarticlesan' },
	],
	urlType: [
		{ Id: 1, Text: 'county2806' },
		{ Id: 2, Text: 'district2805' },
		{ Id: 3, Text: 'city2804' },
		{ Id: 4, Text: 'region' },
		{ Id: 5, Text: 'typeofaccommoda' },
		{ Id: 6, Text: 'regiontypeofacc' },
		{ Id: 7, Text: 'citytypeofaccom' },
		{ Id: 8, Text: 'districttypeofa' },
		{ Id: 9, Text: 'countytypeofacc' },
		{ Id: 10, Text: 'typeofstay' },
		{ Id: 11, Text: 'regiontypeofsta' },
		{ Id: 12, Text: 'citytypeofstay' },
		{ Id: 13, Text: 'districttypeofs' },
		{ Id: 14, Text: 'countytypeofsta' },
		{ Id: 15, Text: 'typeofaccommoda2817' },
		{ Id: 16, Text: 'attraction2818' },
		{ Id: 17, Text: 'attractiontypeo' },
		{ Id: 18, Text: 'regiontypeofacc3192' },
		{ Id: 19, Text: 'citytypeofaccom3193' },
		{ Id: 20, Text: 'districttypeofa3194' },
		{ Id: 21, Text: 'countytypeofacc3195' },
		{ Id: 22, Text: 'attractiontypeo2820' },
		{ Id: 23, Text: 'attractiontypeo3353' },
	],
	advertising: [
		{ Id: 1, Text: 'articleswithadv' },
		{ Id: 0, Text: 'noadvertising2798' },
	],
	backlinkState: [
		{ Id: 1, Text: 'ok2826' },
		{ Id: 2, Text: 'waitingforaddfi' },
		{ Id: 3, Text: 'wewanttoget' },
		{ Id: 4, Text: 'linkscanceledno' },
	],
	backlinkCategory: [
		{ Id: 1, Text: 'linksexchangedf' },
		{ Id: 2, Text: 'purchasedlinks' },
		{ Id: 3, Text: 'exchangedlinks' },
		{ Id: 4, Text: 'forumlinks' },
		{ Id: 5, Text: 'other' },
	],
	backlinkType: [
		{ Id: 1, Text: 'textlink' },
		{ Id: 2, Text: 'picture2864' },
		{ Id: 3, Text: 'textlinkimage' },
	],
	faqTargetType: [
		{ Id: 1, Text: 'forobject' },
		{ Id: 2, Text: 'forlocation' },
	],
	objectReservationType: [
		{ Id: 1, Text: 'reservationsfro' },
		{ Id: 2, Text: 'questionsfromth' },
	],
	emailType: [
		{ Id: 1, Text: 'technical' },
		{ Id: 2, Text: 'marketingformem' },
		{ Id: 3, Text: 'marketingfornon' },
	],
	emailerRecipientType: [
		{ Id: 1, Text: 'potentiallandlo' },
		{ Id: 2, Text: 'potentiallandlo2960' },
		{ Id: 3, Text: 'potentiallandlo2961' },
		{ Id: 4, Text: 'registeredlandl' },
		{ Id: 5, Text: 'activelandlords' },
		{ Id: 6, Text: 'activelandlords2964' },
		{ Id: 7, Text: 'inactivelandlor' },
		{ Id: 8, Text: 'activelandlords2966' },
		{ Id: 9, Text: 'activelandlords2967' },
		{ Id: 10, Text: 'activelandlords2968' },
		{ Id: 11, Text: 'activelandlords2969' },
		{ Id: 12, Text: 'activelandlords2970' },
		{ Id: 13, Text: 'activelandlords3505' },
		{ Id: 14, Text: 'activelandlords3506' },
		{ Id: 15, Text: 'activelandlords3507' },
		{ Id: 16, Text: 'activelandlords3508' },
		{ Id: 17, Text: 'activelandlords3509' },
		{ Id: 18, Text: 'activelandlords3572' },
		{ Id: 19, Text: 'activelandlords3573' },
		{ Id: 20, Text: 'activelandlords3574' },
		{ Id: 21, Text: 'activelandlords3575' },
	],
	emailerSubscription: [
		{ Id: 1, Text: 'onlyallowedtose' },
		{ Id: 2, Text: 'onlynotallowedt' },
		{ Id: 3, Text: 'allwithalloweda' },
		{ Id: 4, Text: 'onlyallowedtose3569' },
		{ Id: 5, Text: 'onlynotallowedt3570' },
		{ Id: 6, Text: 'allwithalloweda3571' },
	],
	emailSendLogType: [
		{ Id: 0, Text: 'unknown' },
		{ Id: 1, Text: 'common' },
		{ Id: 2, Text: 'contacts3285' },
		{ Id: 3, Text: 'objects3283' },
		{ Id: 4, Text: 'invoices3284' },
		{ Id: 5, Text: 'backlinks3286' },
	],
	productSet: [
		{ Id: 1, Text: 'standard' },
		{ Id: 2, Text: 'top3007' },
		{ Id: 3, Text: 'reinvoicing3008' },
		{ Id: 4, Text: 'additionaltop' },
		// { Id: 5, Text: 'LastminuteSet - slovko' },
		// { Id: 6, Text: 'AdditionalSectionSet - slovko' },
	],
	reservationCreatedBy: [
		{ Id: 1, Text: 'visitor' },
		{ Id: 2, Text: 'landlord' },
		{ Id: 3, Text: 'visitorfromland' },
	],
	reservationFeedbackCancelVisitorReason: [
		{ Id: 13, Text: 'allwithfeedback' },
		{ Id: 14, Text: 'allwithoutfeedb' },
		{ Id: 15, Text: 'iwashere' },
		{ Id: 17, Text: 'wewillbeaccommo' },
		{ Id: 16, Text: 'iwasnothereallr' },
		{ Id: 1, Text: 'iwasnothereland' },
		{ Id: 2, Text: 'iwasnotherethel' },
		{ Id: 3, Text: 'iwasnothereifou' },
		{ Id: 4, Text: 'iwasnothereacco' },
		{ Id: 5, Text: 'iwasnothereanot' },
		{ Id: 11, Text: 'iwasnothereidid' },
		{ Id: 12, Text: 'iwasnothereidid3085' },
	],
	reservationFeedbackCancelOwnerReason: [
		{ Id: 13, Text: 'allwithfeedback' },
		{ Id: 14, Text: 'allwithoutfeedb' },
		{ Id: 15, Text: 'realizedreserva' },
		{ Id: 16, Text: 'unrealizedreser3087' },
		{ Id: 6, Text: 'unrealizedreser3088' },
		{ Id: 7, Text: 'unrealizedreser3089' },
		{ Id: 8, Text: 'unrealizedreser3090' },
		{ Id: 9, Text: 'unrealizedreser3091' },
		{ Id: 10, Text: 'unrealizedreser3092' },
	],
	dictionaryTextType: [
		{ Id: 0, Text: 'text3207' },
		{ Id: 5, Text: 'link' },
		{ Id: 3, Text: 'validation' },
		{ Id: 4, Text: 'placeholder' },
		{ Id: 1, Text: 'tooltipforlandl' },
		{ Id: 2, Text: 'tooltipforadmin' },
		{ Id: 6, Text: 'tooltipforvisit' },
	],
	petRace: [
		{ Id: 1, Text: 'largebreed' },
		{ Id: 2, Text: 'petsallowed' },
	],
	ratings: [
		{ Id: 9, Text: 'excellentandmor3223' },
		{ Id: 8, Text: 'superbandmore3224' },
		{ Id: 7, Text: 'verygoodandmore3225' },
		{ Id: 6, Text: 'goodandmore3226' },
	],
	locationDistance: [
		{ Id: 25, Text: 'Do 25 km' },
		{ Id: 50, Text: 'Do 50 km' },
		{ Id: 100, Text: 'Do 100 km' },
		{ Id: 150, Text: 'Do 150 km' },
		{ Id: 200, Text: 'Do 200 km' },
	],
	objectSimplePropertyTypeEntityType: [
		{ Id: 1, Text: 'basicinfo3494' },
		{ Id: 2, Text: 'rooms3495' },
		{ Id: 3, Text: 'equipment' },
		{ Id: 4, Text: 'location3497' },
		{ Id: 5, Text: 'pricesanddiscou3498' },
	],
	sectionMenuType: [
		{ Id: 1, Text: 'city2804' },
		{ Id: 2, Text: 'county2806' },
		{ Id: 3, Text: 'region' },
		{ Id: 4, Text: 'county2806' },
	],
};

const serviceEnums = {
	/**
	 * Returns array of enum items [{Id, Text},..] with translated texts
	 *
	 * @param {string} entityName base enum name, without List postfix
	 * @param {boolean} addEmptyItem [optional] indicates if you want to add empty item
	 * @param {object} emptyItem [optional] specify empty item if default is not suitable
	 */
	getEnum(enumName, addEmptyItem, emptyItem, keyName = 'Id', valueName = 'Text') {
		let list = enums[enumName];
		if (list) {
			if (addEmptyItem === true && emptyItem === undefined) {
				emptyItem = {};
				emptyItem[keyName] = null;
				emptyItem[valueName] = '';
			} else if (emptyItem !== undefined && emptyItem != null) {
				emptyItem[valueName] = i18n.t(emptyItem[valueName]);
			}
			return utils.convertItems(
				list,
				function(item) {
					var result = {};
					result[keyName] = item.Id;
					result[valueName] = i18n.t(item.Text);
					return result;
				},
				addEmptyItem === true ? emptyItem : null
			);
		}
		throw `Unknown enum: ${enumName}`;
	},

	getEnumDict(enumName, addEmptyItem, emptyItem) {
		let list = this.getEnum(enumName, addEmptyItem, emptyItem);
		return utils.arrayToDict(list, 'Id');
	},

	getEnumForDropdown(enumName, addEmptyItem, emptyItem) {
		return this.getEnum(enumName, addEmptyItem, emptyItem, 'value', 'text');
	},

	getEnumForVFG(enumName, addEmptyItem, emptyItem) {
		return this.getEnum(enumName, addEmptyItem, emptyItem, 'id', 'name');
	},

	getEnumItemText(enumName, id) {
		let list = enums[enumName];
		if (list) {
			var item = list.find((x) => x.Id == id);
			if (item) {
				return i18n.t(item.Text);
			}
			return null;
		}
		throw `Unknown enum: ${enumName}`;
	},

	getEnumAsKeyValue(enumName) {
		return this.getEnum(enumName).reduce((kv, item) => {
			kv[item.Id] = item.Text;
			return kv;
		}, {});
	},
};

export default serviceEnums;
