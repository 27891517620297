import Vue from 'vue';
import Vuex from 'vuex';
import alert from './alert.store';
import dashboardState from './dashboard-state.store';
import lang from './lang.store';
import country from './country.store';
import quickFilter from './quick-filter.store';
import remarksCount from './remarks-count.store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
	modules: {
		alert,
		lang,
		country,
		quickFilter,
		remarksCount,
		dashboardState,
	},
	strict: debug,
});

export default store;
