import createItemButton from '../../../components/common/button';
import invoiceAddressDetail from './invoice-address-invoice.detail.vue';
import FieldAutocomplete from '../../../components/detail/autocomplete.field';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

function importDetailComponents(vue) {
	vue.component('font-awesome-icon', FontAwesomeIcon);
	vue.component('megau-button', createItemButton);
	vue.component('field-autocomplete', FieldAutocomplete);
	vue.component('invoice-address-invoice-detail', invoiceAddressDetail);
}

export default {
	importDetailComponents,
};
