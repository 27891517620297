import { loadDashboardState } from './actions';
import dashboardService from '../modules/dashboard/dashboard.service';

const state = {
	status: '',
};

const actions = {
	async [loadDashboardState]({ commit }) {
		try {
			const resp = await dashboardService.getState();
			commit(loadDashboardState, {
				status: 'success',
				response: resp.data,
			});
		} catch (err) {
			commit(loadDashboardState, {
				status: 'error',
				response: null,
			});
		}
	},
};

const mutations = {
	[loadDashboardState](state, { status, response }) {
		if (response) {
			state.status = response;
		}
	},
};

export default {
	state,
	actions,
	mutations,
};
