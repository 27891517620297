<template>
	<div>
		<megau-button classprop="btn" titlePosition="none" :title="buttonTitle" @handleClick="openModal()"></megau-button>
		<invoice-address-invoice-detail
			:isVisible="isVisibleDetail"
			:modelInvAddress="value"
			:schemaInvAddress="schemaInvAddress"
			:formOptionsInvAddress="formOptionsInvAddress"
			@onClose="closeDetail"
			@onChanged="onChanged"
		/>
	</div>
</template>

<script>
import vue from 'vue';
import { abstractField } from 'vue-form-generator';

import apiCommon from '../../../services/api/common.api';

import { detailModel } from './invoice-address-invoice.models';

import imports from './invoice-address-invoice.imports';
imports.importDetailComponents(vue);

export default {
	mixins: [abstractField],
	name: 'invoice-address-invoice',
	data() {
		return detailModel(this.$i18n);
	},
	created() {
		this.createButtonText();
	},
	methods: {
		onChanged() {
			if (this.schema.onChanged) {
				this.createButtonText();
				this.schema.onChanged.call(this);
			}
		},
		createButtonText() {
			let ico = this.value.InvaddrIco == '' ? '' : this.$i18n.t('companyid') + ' ' + this.value.InvaddrIco;
			let dic = this.value.InvaddrDic == '' ? '' : this.$i18n.t('taxid') + ' ' + this.value.InvaddrDic;
			let icdph = this.value.InvaddrIcdph == '' ? '' : this.$i18n.t('vatid') + ' ' + this.value.InvaddrIcdph;

			this.buttonTitle = [
				this.value.InvaddrName,
				this.value.InvaddrStreet,
				this.value.InvaddrZip,
				this.value.InvaddrCity,
				ico,
				dic,
				icdph,
			]
				.filter((x) => typeof x == 'string' && x.length > 0)
				.join(', ');
		},
		openModal() {
			this.isVisibleDetail = true;
		},
		closeDetail() {
			this.isVisibleDetail = false;
		},
	},
};
</script>
