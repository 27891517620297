import { loadRemarksCount } from './actions';
import http from "../services/helpers/http";
import auth from '../services/auth.service';
import enums from '../services/helpers/enums';

const state = {
	remarksContactsCount: "",
	remarksObjectsCount: "",
	remarksInvoicesCount: "",
	remarksBacklinksCount: "",
};

const actions = {
	[loadRemarksCount]({ commit }) {
		return http
			.get('Remark/GetRemarksCountForUser', { userId: auth.GetUser().Id })
			.then(res => res.data)
			.then(remarks => {
				commit(loadRemarksCount, {
					remarksContactsCount: remarks[enums.remarkEntityType.Contact] || 0,
					remarksObjectsCount: remarks[enums.remarkEntityType.Object] || 0,
					remarksInvoicesCount: remarks[enums.remarkEntityType.Invoice] || 0,
					remarksBacklinksCount: remarks[enums.remarkEntityType.Backlink] || 0
				});
				return remarks;
			});
			}
	};	

const mutations = {

	[loadRemarksCount](state, {
		remarksContactsCount,
		remarksObjectsCount,
		remarksInvoicesCount,
		remarksBacklinksCount
	}) {
			state.remarksContactsCount = remarksContactsCount;
			state.remarksObjectsCount = remarksObjectsCount;
			state.remarksInvoicesCount = remarksInvoicesCount;
			state.remarksBacklinksCount = remarksBacklinksCount;
	},
};

export default {
	state,
	actions,
	mutations
};
