<template>
	<div class="country-flag">
		<span v-if="existCountry">{{ srcCountry }}</span>
		<span v-else-if="existLang">{{ srcLang }}</span>
		<span v-else>-</span>
	</div>
</template>

<script>
import serviceEnums from '../../services/service/enums.service';

export default {
	props: {
		countryId: { type: Number, default: 0 },
		languageCode: { type: String, default: '' },
	},
	data() {
		return {
			countryDict: [],
		};
	},
	created() {
		this.countryDict = this.$store.getters.countryDict;
	},
	computed: {
		existCountry() {
			return this.countryId == 0 ? false : true;
		},
		existLang() {
			return this.languageCode == '' ? false : true;
		},
		srcCountry() {
			if (this.countryId > 0) {
				return this.countryDict[this.countryId].Code;
			}
			return '';
		},
		altTitleCountry() {
			if (this.countryId > 0) {
				return this.countryDict[this.countryId].Name;
			}
			return '';
		},
		srcLang() {
			return this.languageCode;
		},
		altTitleLang() {
			return this.languageCode;
		},
	},
};
</script>
<style lang="scss" scoped>
.country-flag {
	height: 15px;
	> img {
		height: 100%;
	}
}
</style>