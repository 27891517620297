import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class Extension extends Plugin {
	init() {
		const editor = this.editor;

		const allowedElements = ['table', 'tr', 'td', 'th', 'div', 'p' /*, 'span'*/];
		const allowedAttributes = [
			'id',
			'style',
			'class',
			'width',
			'height',
			'name',
			'title',
			'align',
			'border',
			'cellspacing',
			'cellpadding',
			'color',
			'valign',
			'clear',
			'src',
			'rows',
			'cols',
			'col',
			'colspan',
			'rowspan',
			'span',
			'bgcolor',
			'alt',
			'action',
			'method',
			'value',
			'checked',
			'selected',
			'rel',
			'cellpacing',
			'nowrap',
			'preload',
			'tabindex',
			'vspace',
			'hspace',
		];

		// Allow table elements in the model to have all attributes.
		editor.model.schema.addAttributeCheck((context) => {
			for (let index = 0; index < allowedElements.length; index++) {
				const element = allowedElements[index];
				if (context.endsWith(element)) {
					return true;
				}
			}
		});

		editor.model.schema.register('table', {
			allowWhere: '$block',
			allowContentOf: '$root',
			allowAttributes: allowedAttributes,
		});
		editor.model.schema.register('tr', { allowWhere: 'table', allowContentOf: '$root', allowAttributes: allowedAttributes });
		editor.model.schema.register('th', { allowWhere: 'tr', allowContentOf: '$block', allowAttributes: allowedAttributes });
		editor.model.schema.register('td', { allowWhere: 'tr', allowContentOf: '$block', allowAttributes: allowedAttributes });
		editor.model.schema.register('div', { inheritAllFrom: '$block' });
		// editor.model.schema.register('span', {
		// 	allowIn: '$block',
		// 	isInline: true,
		// 	allowAttributes: allowedAttributes,
		// });

		allowedElements.forEach((element) => {
			// Allow element in the model.
			// editor.model.schema.register(element, {
			// 	allowWhere: '$block',
			// 	allowContentOf: '$root',
			// 	allowAttributes: allowedAttributes,
			// });

			// View-to-model converter converting a view of the element with all its attributes to the model.
			editor.conversion.for('upcast').elementToElement({
				view: element,
				model: (viewElement, modelWriter) => {
					return modelWriter.writer.createElement(element, viewElement.getAttributes());
				},
			});

			// Model-to-view converter for the element (attrbiutes are converted separately).
			editor.conversion.for('downcast').elementToElement({
				model: element,
				view: element,
			});
		});

		// Model-to-view converter for table attributes.
		// Note that a lower-level, event-based API is used here.
		editor.conversion.for('downcast').add((dispatcher) => {
			dispatcher.on('attribute', (evt, data, conversionApi) => {
				// Convert allowed elements attributes only.
				if (allowedElements.indexOf(data.item.name) == -1) {
					return;
				}

				const viewElement = conversionApi.mapper.toViewElement(data.item);

				// In the model-to-view conversion we convert changes.
				// An attribute can be added or removed or changed.
				// The below code handles all 3 cases.
				if (data.attributeNewValue) {
					conversionApi.writer.setAttribute(data.attributeKey, data.attributeNewValue, viewElement);
				} else {
					conversionApi.writer.removeAttribute(data.attributeKey, viewElement);
				}
			});
		});
	}
}
