<template>
	<div>
		<div :class="wrapperClass">
			<label :isVisible="this.showEmailSend">{{this.labelText}}</label>
			<b-dropdown id="dropdown-header" :text="this.buttonText" class="mr-1">
				<b-dropdown-item
					v-for="offer in offersList"
					:key="offer.Id"
					@click="onDropdownSelect(offer.Id)"
				>
					<span>{{offer.Subject}}</span>
				</b-dropdown-item>
			</b-dropdown>
			<email-send-modal
				:isVisible="this.showEmailSend"
				:typeId="pesLogTypeId"
				:templateId="emailTemplateId"
				:entityId="entityId"
				:isSentEmail="false"
				@onClose="onCloseEmailSend"
			/>
		</div>
		<emails-table
			v-if="withTable"
			ref="parentEmailsTable"
			:show="showTable"
			:entityId="entityId"
			:entityType="pesLogTypeId"
		></emails-table>
	</div>
</template>
<script>
export default {
	components: {
		'email-send-modal': () => import('./email-send.modal'),
		'emails-table': () => import('./emails.table'),
	},
	props: {
		labelText: { type: String, default: '' },
		buttonText: { type: String, default: '' },
		offersList: { type: Array, default: new Array() },
		entityId: { type: Number, default: 0 },
		pesLogTypeId: { type: Number, default: 2 },
		emailExist: { type: Boolean, default: false },
		withTable: { type: Boolean, default: false },
	},

	data() {
		return {
			showEmailSend: false,
			emailTemplateId: 0,
			showTable: this.emailExist,
			wrapperClass: this.withTable ? 'form-group' : '',
		};
	},
	methods: {
		onDropdownSelect(offerId) {
			this.emailTemplateId = offerId;
			this.showEmailSend = true;
		},
		onCloseEmailSend(getData) {
			this.showEmailSend = false;
			if (getData && this.withTable) {
				this.showTable = true;
				this.$refs.parentEmailsTable.$refs.emailsTable.getData();
			}
		},
	},
	watch: {
		emailExist: function() {
			this.showTable = this.emailExist;
		},
	},
};
</script>
