import http from '../../services/helpers/http';
import i18n from '../../services/lang';
import { formatter } from '../../services/helpers/formatter';

const service = {
	handleDeleteWithModal(invoiceId, modal, callback) {
		modal.show({
			title: i18n.t('deleteremark2312'),
			text: i18n.t('areyousureyouwa2313'),
			modalClass: 'modal-danger',
			variant: 'danger',
			onConfirm() {
				return http.put('Invoice/PutInvoiceNote', { invoiceId: invoiceId, invoiceNote: '' }).then(() => callback());
			},
		});
	},

	confirmInvoicePayment(invoiceId, modal, callback, startLoading) {
		modal.show({
			title: i18n.t('receivepayment'),
			text: i18n.t('areyouconfirmin'),
			okTitle: i18n.t('okacceptpayment'),
			cancelTitle: i18n.t('closewithoutcha2494'),
			modalClass: 'modal-danger',
			variant: 'primary',
			onConfirm() {
				startLoading(false);
				return http.put('Invoice/ConfirmInvoicePayment', { invoiceId: invoiceId }).then(() => callback());
			},
		});
	},

	cancelInvoice(invoiceId, modal, callback) {
		modal.show({
			title: i18n.t('cancelinvoice2496'),
			text: i18n.t('areyousureyouwa2497'),
			okTitle: i18n.t('okcanceltheinvo'),
			cancelTitle: i18n.t('closewithoutcha2494'),
			modalClass: 'modal-danger',
			variant: 'danger',
			onConfirm() {
				return http.put('Invoice/CancelInvoice', { invoiceId: invoiceId }).then(() => callback());
			},
		});
	},
};

export default service;
