var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datepicker-wrapper" },
    [
      _c("font-awesome-icon", {
        staticClass: "calendar-icon",
        attrs: { icon: "calendar-alt", cursor: "pointer" },
        on: { click: _vm.clickDateIcon },
      }),
      _c("font-awesome-icon", {
        staticClass: "delete-icon",
        attrs: { icon: "times", cursor: "pointer" },
        on: { click: _vm.clickDeleteIcon },
      }),
      _c("date-range-picker", {
        ref: "datepicker",
        attrs: {
          "locale-data": _vm.locale,
          singleDatePicker: true,
          timePicker: _vm.showTime,
          timePicker24Hour: true,
          ranges: false,
          value: _vm.value,
          autoApply: _vm.autoSelect,
          "time-picker-increment": 1,
          showDropdowns: true,
        },
        on: { update: _vm.updateValue },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }