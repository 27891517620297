<template>
	<div v-if="showContact" class="contact-wrapper">
		<div class="left-wrapper">
			<div>
				<font-awesome-icon icon="user" :size="iconSize" :class="iconClass" />
				<span>
					{{ value.ContactName }}
					{{ value.ContactSurname }}
					<a v-if="contactDetailLink" :href="contactDetailLink" target="_blank">P{{ value.UserId }}</a>
					<span v-else>P{{ value.UserId }}</span>
				</span>
			</div>
			<div>
				<font-awesome-icon icon="envelope" :size="iconSize" :class="iconClass" />
				<a :href="`mailto:${value.ContactEmailLogin}`" target="_blank">{{ value.ContactEmailLogin }}</a>
			</div>
			<div>
				<font-awesome-icon icon="phone-square-alt" :size="iconSize" :class="iconClass" />
				<div class="phone-list">
					<a v-for="phone in value.ContactPhonesList" :key="phone" :href="`tel:${phone}`">{{ phone }}</a>
				</div>
			</div>
		</div>
		<div class="right-wrapper">
			<div>
				<font-awesome-icon icon="user" :size="iconSize" :class="iconClass" />
				<span>
					{{ value.ObjectContactPerson }}
					<a :href="objectDetailLink" target="_blank">O{{ value.ObjectId }}</a>
				</span>
			</div>
			<div>
				<font-awesome-icon icon="envelope" :size="iconSize" :class="iconClass" />
				<a :href="`mailto:${value.ObjectEmailForReservations}`" target="_blank">{{ value.ObjectEmailForReservations }}</a>
			</div>
			<div>
				<font-awesome-icon icon="phone-square-alt" :size="iconSize" :class="iconClass" />
				<div class="phone-list">
					<a v-for="phone in this.objectPhoneList" :key="phone" :href="`tel:${phone}`">{{ phone }}</a>
				</div>
			</div>
			<div>
				<font-awesome-icon icon="globe" :size="iconSize" :class="iconClass" />
				<a :href="value.ObjectWeb" target="_blank">{{ value.ObjectWeb }}</a>
			</div>
			<div class="backlinks-list">
				<span v-if="!value.ObjectWebBacklinks.length">{{ $t('nobacklink') }}</span>
				<div v-else v-for="backlink in value.ObjectWebBacklinks" :key="backlink.Id">
					<span v-if="backlink.State == 1">
						<a :href="backlink.linkToDetail" target="_blank"
							>{{ $t('backlinkfrom') }} {{ backlinkDate }} </a>
					</span>
					<span v-if="backlink.State == 2">
						<a :href="backlink.linkToDetail" target="_blank">{{ $t('waitingforaddfi') }} </a>
					</span>
					<span v-if="backlink.State == 3">
						<a :href="backlink.linkToDetail" target="_blank">{{ $t('wewanttoget') }} </a>
					</span>
					<span v-if="backlink.State == 4">
						<a :href="backlink.linkToDetail" target="_blank">{{ $t('linkscanceledno') }} </a>
					</span>
					<!-- {{ backlink.WebLink }} -->
				</div>
			</div>
		</div>

		<b-textarea ref="textArea" class="contact-note" rows="3" v-model="value.InvoiceNote"></b-textarea>
	</div>
</template>
<script>
import auth from '../../services/auth.service';
import { abstractField } from 'vue-form-generator';
import { objectDetailName, contactDetailName } from '@/router/links';

export default {
	mixins: [abstractField],
	name: 'contact-box',

	data() {
		return {
			iconSize: 'lg',
			iconClass: 'mr-2',
		};
	},
	computed: {
		backlinkDate() {
			return new Date(this.value.ObjectWebBacklinks[0].CreationDate).toLocaleDateString();
		},

		objectPhoneList() {
			return this.createListFromSeparatPhones();
		},
		showContact() {
			return this.value ? true : false;
		},
		objectDetailLink() {
			const route = this.$router.resolve({ name: objectDetailName, params: { ObjectId: this.value.ObjectId } });
			return route.href;
		},
		contactDetailLink() {
			if (!this.value.ContactId) {
				return null;
			}

			const route = this.$router.resolve({ name: contactDetailName, params: { ContactId: this.value.ContactId } });
			return route.href;
		},
	},
	mounted() {
		this.user = auth.GetUser();
	},
	methods: {
		createListFromSeparatPhones() {
			var phoneArray = [this.value.ObjectPhone1, this.value.ObjectPhone2, this.value.ObjectPhone3];
			return phoneArray.filter(function (el) {
				return el != '';
			});
		},
	},
};
</script>

<style lang="scss">
.contact-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-shadow: 2px 2px 10px #dadada;
	border-radius: 5px;
	margin: 0px !important;
	background-image: linear-gradient(to right, #eee, var(--white));

	> div {
		flex: 1 1 0px;
		padding: 10px 12px;

		.phone-list {
			> a::after {
				content: '; ';
			}
			> a:last-child::after {
				content: '';
			}
		}

		&.left-wrapper {
			svg {
				color: var(--primary);
			}
		}

		&.right-wrapper {
			svg {
				color: var(--warning);
			}
		}

		> div {
			display: flex;
			align-items: flex-start;
			padding: 5px 0;
		}
	}
	.contact-note {
		margin: 0px 12px 10px 12px;
	}

	.backlinks-list {
		display: flex;
		flex-direction: column;
	}
}
</style>
