<template>
	<div class="link-to-manual">
		<font-awesome-icon icon="info-circle" id="tooltipTrigger"></font-awesome-icon>
		<!--
		<span id="tooltipTrigger"></span>
		-->
		<b-tooltip target="tooltipTrigger" triggers="hover">
			<span v-html="$t(tooltip)"></span>
		</b-tooltip>
	</div>
</template>

<script>
export default {
	props: {
		tooltip: { type: String, required: true },
	},
};
</script>

<style lang="scss">
.link-to-manual {
	color: #6c757d;
	display: inline-block;
	margin: 0 1rem;
	font-size: 1.5rem;
	line-height: 1.5rem;
}

#tooltipTrigger {
	cursor: pointer;
}
</style>