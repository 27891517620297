var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "invoice-address-modal",
        size: "lg",
        centered: "",
        title: _vm.$t("detailsofoursup"),
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-enforce-focus": "",
      },
      on: {
        hidden: function ($event) {
          return _vm.close()
        },
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("vue-form-generator", {
        attrs: {
          schema: _vm.schemaAC,
          model: _vm.modelAC,
          options: _vm.formOptionsAC,
        },
      }),
      [
        _c("megau-button", {
          attrs: {
            classprop: "btn btn-primary",
            icon: "undo",
            title: _vm.$t("closewithoutsav2459"),
          },
          on: {
            handleClick: function ($event) {
              return _vm.close()
            },
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }