var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns-group" },
    _vm._l(_vm.schema.values, function (item) {
      return _c(
        "b-form-checkbox",
        {
          key: item[_vm.valuePropName],
          attrs: {
            value: item[_vm.valuePropName],
            name: item[_vm.textPropName],
            checked: _vm.isItemChecked(item),
          },
          on: {
            change: function ($event) {
              return _vm.onChanged($event, item)
            },
          },
        },
        [_vm._v(_vm._s(item[_vm.textPropName]))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }