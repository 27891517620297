var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-client-table", {
    attrs: { columns: _vm.columns, data: _vm.value, options: _vm.options },
    scopedSlots: _vm._u([
      {
        key: "ContactIsSubscribed",
        fn: function (contactsList) {
          return [
            _vm._v(
              _vm._s(_vm.formatTitle(contactsList.row.ContactIsSubscribed))
            ),
          ]
        },
      },
      {
        key: "CreatedOn",
        fn: function (contactsList) {
          return [_vm._v(_vm._s(_vm._f("date")(contactsList.row.CreatedOn)))]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }