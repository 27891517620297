export const DEFAULT = '/';
export const homeName = 'defaultHome';
export const LOGIN = '/login';

export const dashboard = '/dashboard';
export const dashboardName = 'dashboard';

export const portalRatings = '/portal-rating';
export const PORTALRATINGDETAIL = portalRatings + '/:PratingId';
export const portalRatingsDetailName = 'portalRatingDetail';
export const portalRatingsTableName = 'portalRatingTable';

export const OBJECTREVIEWS = '/object-reviews';
export const OBJECTREVIEWDETAIL = OBJECTREVIEWS + '/:OreviewId';
export const objectReviewsDetailName = 'objectReviewDetail';
export const objectReviewsTableName = 'objectReviewsTable';

export const contactsTable = '/contact';
export const contactDetail = contactsTable + '/:ContactId';
export const contactDetailName = 'contactDetail';
export const contactsTableName = 'contactsTable';

export const objectsTable = '/object';
export const objectDetail = objectsTable + '/:ObjectId';
export const objectDetailName = 'objectDetail';
export const objectsTableName = 'objectsTable';

export const EMAILSEND = '/email-send';
export const EMAILSENDNAME = 'emailSend';

export const invoicesTable = '/invoice';
export const invoicesTableName = 'invoicesTable';
export const invoiceDetail = invoicesTable + '/:InvoiceId';
export const invoiceDetailName = 'invoiceDetail';

export const objectDemandTable = '/object-demands';
export const objectDemandTableName = 'objectDemandTable';
export const objectDemandDetail = objectDemandTable + '/:OdemandId';
export const objectDemandDetailName = 'objectDemandDetail';

export const regionTable = '/regions';
export const regionTableName = 'regionTable';
export const regionDetail = regionTable + '/:RegionId';
export const regionDetailName = 'regionDetail';

export const seoPageTable = '/seo-page';
export const seoPageTableName = 'seoPageTable';
export const seoPageDetail = seoPageTable + '/:SeoPageId';
export const seoPageDetailName = 'seoPageDetail';

export const attractionTable = '/attraction';
export const attractionTableName = 'attractionTable';
export const attractionDetail = attractionTable + '/:AttractionId';
export const attractionDetailName = 'attractionDetail';

export const reinvoicingDetail = '/reinvoicing';
export const reinvoicingDetailName = 'reinvoicingDetail';

export const accountingExportDetail = '/accounting-export';
export const accountingExportDetailName = 'accountingExport';

export const portalJobRunsTable = '/portal-job-runs';
export const portalJobRunsTableName = 'portalJobRunsTable';

export const urlCatalogTable = '/url-catalog';
export const urlCatalogTableName = 'curlTable';
export const urlCatalogDetail = urlCatalogTable + '/:UrlCatalogId';
export const urlCatalogDetailName = 'curlDetail';

export const backlinksTable = '/backlink';
export const backlinksTableName = 'backlinksTable';
export const backlinkDetail = backlinksTable + '/:Id';
export const backlinkDetailName = 'backlinkDetail';

export const faqTable = '/faq';
export const faqTableName = 'faqTable';
export const faqDetail = faqTable + '/:faqId';
export const faqDetailName = 'faqDetail';

export const objectReservationTable = '/object-reservations';
export const objectReservationTableName = 'objectReservationTable';
export const objectReservationDetail = objectReservationTable + '/:objectReservationId';
export const objectReservationDetailName = 'objectReservationDetail';

export const emailsTemplatesTable = '/emails-template';
export const emailsTemplatesTableName = 'emailsTemplateTable';
export const emailsTemplateDetail = emailsTemplatesTable + '/:PemailId';
export const emailsTemplateDetailName = 'emailsTemplateDetail';

export const emailsLogsTable = '/emails-logs';
export const emailsLogsTableName = 'emailsLogsTable';

export const emailerTable = '/emailer';
export const emailerTableName = 'emailerTable';
export const emailerDetail = emailerTable + '/:PetaskId';
export const emailerDetailName = 'emailerDetail';

export const productTable = '/portal-products';
export const productTableName = 'portalProductTable';
export const productDetail = productTable + '/:ProductId';
export const productDetailName = 'portalProductDetail';

export const accountingCompanyTable = '/accounting-companies';
export const accountingCompanyTableName = 'accountingCompanyTable';
export const accountingCompanyDetail = accountingCompanyTable + '/:AcompanyId';
export const accountingCompanyDetailName = 'accountingCompanyDetail';

export const translationTable = '/translations';
export const translationTableName = 'translationTable';
export const translationDetail = translationTable + '/:DtextId';
export const translationDetailName = 'translationDetail';

export const sojournTypesTable = '/sojourn-type';
export const sojournTypeTableName = 'sojournTypeTable';
export const sojournTypeDetail = sojournTypesTable + '/:OstypeId';
export const sojournTypeDetailName = 'sojournTypeDetail';

export const attractionGroupTable = '/attraction-groups';
export const attractionGroupTableName = 'attractionGroupTable';
export const attractionGroupDetail = attractionGroupTable + '/:AgroupId';
export const attractionGroupDetailName = 'attractionGroupDetail';

export const cityTable = '/cities';
export const cityTableName = 'cityTable';
export const cityDetail = cityTable + '/:CityId';
export const cityDetailName = 'cityDetail';

export const districtTable = '/districts';
export const districtTableName = 'districtTable';
export const districtDetail = districtTable + '/:DistrictId';
export const districtDetailName = 'districtDetail';

export const territoryTable = '/territories';
export const territoryTableName = 'territoryTable';
export const territoryDetail = territoryTable + '/:TerritoryId';
export const territoryDetailName = 'territoryDetail';

export const countryTable = '/countries';
export const countryTableName = 'countryTable';
export const countryDetail = countryTable + '/:CountryId';
export const countryDetailName = 'countryDetail';

export const statisticsPage = '/statistics/:statistic';
export const statisticsPageWithParam = (statistic) => `/statistics/${statistic}`;
export const statisticsPageName = 'statisticsPage';

export const priceListSeasonTable = '/price-list-season';
export const priceListSeasonTableName = 'priceListSeasonTable';
export const priceListSeasonDetail = priceListSeasonTable + '/:id';
export const priceListSeasonDetailName = 'priceListSeasonDetail';

export const priceListUnitTable = '/price-list-unit';
export const priceListUnitTableName = 'priceListUnitTable';
export const priceListUnitDetail = priceListUnitTable + '/:id';
export const priceListUnitDetailName = 'priceListUnitDetail';

export const accomodationUnitTypeTable = '/accomodation-unit-type';
export const accomodationUnitTypeTableName = 'accomodationUnitTypeTable';
export const accomodationUnitTypeDetail = accomodationUnitTypeTable + '/:id';
export const accomodationUnitTypeDetailName = 'accomodationUnitTypeDetail';

export const currencyTable = '/currency';
export const currencyTableName = 'currencyTable';
export const currencyDetail = currencyTable + '/:id';
export const currencyDetailName = 'currencyDetail';

export const discountCategoryTable = '/discount-category';
export const discountCategoryTableName = 'discountCategoryTable';
export const discountCategoryDetail = discountCategoryTable + '/:id';
export const discountCategoryDetailName = 'discountCategoryDetail';

export const discountTypeTable = '/discount-type';
export const discountTypeTableName = 'discountTypeTable';
export const discountTypeDetail = discountTypeTable + '/:id';
export const discountTypeDetailName = 'discountTypeDetail';

export const objectSimplePropertyTable = '/object-simple-property';
export const objectSimplePropertyTableName = 'objectSimplePropertyTable';
export const objectSimplePropertyDetail = objectSimplePropertyTable + '/:id';
export const objectSimplePropertyDetailName = 'objectSimplePropertyDetail';

export const objectTypesTable = '/object-type';
export const objectTypesTableName = 'objectTypesTable';
export const objectTypesDetail = objectTypesTable + '/:id';
export const objectTypesDetailName = 'objectTypesDetail';

export const catalogSectionTable = '/catalog-section';
export const catalogSectionTableName = 'catalogSectionTable';
export const catalogSectionDetail = catalogSectionTable + '/:id';
export const catalogSectionDetailName = 'catalogSectionDetail';

export const sectionMenuTable = '/section-menu';
export const sectionMenuTableName = 'sectionMenuTable';
export const sectionMenuDetail = sectionMenuTable + '/:id';
export const sectionMenuDetailName = 'sectionMenuDetail';
