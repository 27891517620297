import { loadCountries } from './actions';
import apiDictionary from '../services/api/dictionary.api';
import utils from '../services/helpers/utils';
import i18n from '@/services/lang';

const state = {
	data: [],
	status: '',
};

const actions = {
	[loadCountries]({ commit }) {
		return apiDictionary
			.getList('Country', false)
			.then((resp) => {
				commit(loadCountries, {
					status: 'success',
					response: resp.data,
				});
			})
			.catch((err) => {
				commit(loadCountries, {
					status: 'error',
					response: null,
				});
			});
	},
};

const mutations = {
	[loadCountries](state, { status, response }) {
		if (response) {
			state.data = response;
			state.status = status;
		}
	},
};

const getters = {
	countryDict: (state) => {
		return utils.arrayToDict(state.data, 'Id', [{ Id: 0, Name: '' }]);
	},
	publicCountries: (state) => {
		return state.data.filter(x => x.CountryStatus === 0);
	},
	countryForDropdown: (state) => {
		return utils.convertItems(
			state.data,
			function(item) {
				return { value: item.Id, text: item.Name };
			},
			{ value: null, text: '' }
		);
	},
	countryCodeForDropdown: (state) => {
		return utils.convertItems(
			state.data,
			function(item) {
				return { value: item.Code, text: item.Code };
			},
			{ value: null, text: '' }
		);
	},
	countryLanguageCodeForDropdown: (state) => {
		return utils.convertItems(
			state.data,
			function(item) {
				return { value: item.LanguageCode, text: item.LanguageCode };
			},
			{ value: null, text: '' }
		);
	},
	countryDictLabels: (state) => {
		const selectedLangCode = i18n.locale.charAt(0).toUpperCase() + i18n.locale.slice(1);
		let fieldName = 'CountryName' + selectedLangCode;

		if (fieldName === 'CountryNameSk') {
			fieldName = 'Name';
		}
		if (fieldName === 'CountryNameCs') {
			fieldName = 'CountryNameCz';
		}

		return state.data.reduce((dic, country) => {
			dic[country.Id] = country[fieldName];
			return dic;
		}, {});
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
