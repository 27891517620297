import i18n from '../../../services/lang';

const service = {
	formTranslations(schema) {
		schema.fields[0].label = i18n.t('companyname');
		schema.fields[1].label = i18n.t('streetandnumber');
		schema.fields[2].label = i18n.t('InvaddrCity');
		schema.fields[3].label = i18n.t('zip');
		schema.fields[5].label = i18n.t('companyid');
		schema.fields[6].label = i18n.t('taxid');
		schema.fields[7].label = i18n.t('vatid');
	},
};

export default service;
