var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link-to-manual" },
    [
      _c("font-awesome-icon", {
        attrs: { icon: "info-circle", id: "tooltipTrigger" },
      }),
      _c(
        "b-tooltip",
        { attrs: { target: "tooltipTrigger", triggers: "hover" } },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.$t(_vm.tooltip)) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }