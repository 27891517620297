var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        [
          _c("megau-button", {
            attrs: {
              classprop: "btn",
              titlePosition: "none",
              title: this.buttonTitle,
            },
            on: {
              handleClick: function ($event) {
                return _vm.openModal()
              },
            },
          }),
          _c("accounting-company-detail", {
            attrs: {
              isVisible: this.isVisibleDetail,
              modelAC: this.modelAC,
              schemaAC: this.schemaAC,
              formOptionsAC: this.formOptionsAC,
            },
            on: { onClose: _vm.closeDetail },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }