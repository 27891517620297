<template>
	<div class="form-control border-0 shadow-none pl-0">
		<span class="mr-3">{{value | dateTime}}</span>
		<span>{{formatedUserName()}}</span>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
export default {
	mixins: [abstractField],
	methods: {
		formatedUserName() {
			return (
				this.model.SparticleCreatedByUserObject.ContactPersonName +
				' ' +
				this.model.SparticleCreatedByUserObject.ContactPersonSurname
			);
		},
	},
};
</script>

<style lang="scss">
</style>
