import store from '../../store/index';
import utils from '../helpers/utils';
import apiCommon from '../api/common.api';
import i18n from '../lang';
import { cloneDeep, isEmpty } from 'lodash';
import serviceEnums from '../../services/service/enums.service';
import { filters, formatter } from '../../services/helpers/formatter';

var dataState = { callId: 0, table: { data: [], count: 0 } };

function handleCount(info, callId) {
	window.setTimeout(function() {
		apiCommon
			.getCount(
				{
					filter: info.data.filter,
					limit: info.data.limit,
					page: info.data.page,
					ascending: info.data.ascending,
					orderByColumn: info.data.orderBy,
				},
				info.controller
			)
			.then((resp) => {
				if (dataState.callId == callId) {
					dataState.table.count = resp.data;
					store.commit(info.tableName + '/SET_COUNT', resp.data);
					console.log('[Table][' + callId + '] count loaded: ' + resp.data);
				}
			});
	}, 1);
}

const serviceCommon = {
	getListForTable(info) {
		var callId = 0;
		var postponeCountCheck = false;
		if (info.tableName && info.tableName.length > 0) {
			postponeCountCheck = true;
			callId = ++dataState.callId;
			console.log('[Table][' + callId + '] loading');
		}

		return apiCommon
			.getList(
				{
					filter: info.data.filter,
					limit: info.data.limit,
					page: info.data.page,
					ascending: info.data.ascending,
					orderByColumn: info.data.orderBy,
					getCount: !postponeCountCheck,
				},
				info.controller
			)
			.then((resp) => {
				if (postponeCountCheck === true && dataState.callId == callId) {
					if (resp.data.data.length > 0 && dataState.table.count > 0) {
						resp.data.count = dataState.table.count;
					}

					// when filter change, data.page is reset to 1, so load count
					if (info.data.page == 1) {
						dataState.table.data = resp.data.data;
						handleCount(info, callId);
					}
				}
				if (info.onLoaded) {
					info.onLoaded();
				}
				return resp.data;
			});
	},

	getCountForTable(data, controller) {
		return apiCommon.getCount(
			{
				filter: data.filter,
				limit: data.limit,
				page: data.page,
				ascending: data.ascending,
				orderByColumn: data.orderBy,
			},
			controller
		);
	},

	getCurrencySymbolById(currencyId) {
		var currencyCode = this.getCurrencyCodeById(currencyId);
		var currency = formatter.getCurrencySymbol(currencyCode);
		return currency;
	},
	getCurrencyCodeById(currencyId) {
		this.currencyDict = serviceEnums.getEnumDict('currency');
		var code = this.currencyDict[currencyId].Text;
		return code;
	},

	insertOrUpdateInternal(self, itemId, controller, callback) {
		self.startLoading(false);
		if (itemId > 0) {
			apiCommon
				.putEntity(self.model, controller)
				.then(() => callback(itemId))
				.finally(() => self.stopLoading());
		} else {
			apiCommon
				.postEntity(self.model, controller)
				.then((resp) => callback(resp.data))
				.finally(() => self.stopLoading());
		}
	},

	insertOrUpdate(self, itemId, controller, callback) {
		var validateResult = self.$refs.detailForm.validate();
		var isPromise = typeof validateResult.then == 'function';
		if (isPromise) {
			validateResult.then((value) => {
				if (value.length == 0) {
					serviceCommon.insertOrUpdateInternal(self, itemId, controller, callback);
				}
			});
		} else if (validateResult === true) {
			serviceCommon.insertOrUpdateInternal(self, itemId, controller, callback);
		}
	},

	getEntity(thisEl, itemId, controller) {
		apiCommon.getEntity(itemId, controller).then((resp) => {
			thisEl.showDeleteButton = true;
			thisEl.model = resp.data;
		});
	},

	deleteItem(itemIdToDelete, callback, controller) {
		apiCommon.deleteEntity(itemIdToDelete, controller).then(() => callback());
	},

	deleteDetailItem(itemId, controller, callback) {
		apiCommon.deleteEntity(itemId, controller).then(() => callback());
	},

	openDeleteModal(itemId, modal, callback) {
		modal.show({
			title: i18n.t('deletethisitem'),
			text: i18n.t('areyousureyouwa'),
			modalClass: 'modal-danger',
			variant: 'danger',
			onConfirm() {
				return callback(itemId);
			},
		});
	},

	recordsPaginationsTexts() {
		return {
			count:
				'{from}' +
				' ' +
				i18n.t('to') +
				' ' +
				'{to} ' +
				i18n.t('of') +
				' {count} ' +
				' ' +
				i18n.t('records2201') +
				'|{count}' +
				' ' +
				i18n.t('records2200') +
				'|{count}' +
				' ' +
				i18n.t('record'),
			first: i18n.t('first'),
			last: i18n.t('last'),
			filter: 'Filter:',
			filterPlaceholder: 'Search query',
			limit: 'Records:',
			page: 'Page:',
			noResults: i18n.t('nomatchingrecor'),
			filterBy: 'Filter by {column}',
			loading: 'Loading...',
			defaultOption: 'Select {column}',
			columns: i18n.t('columns'),
		};
	},

	getTablePagination(tableName) {
		if (!store.state[tableName]) {
			return {};
		}

		const tableState = store.state[tableName];

		return {
			limit: tableState.limit,
			page: tableState.page,
			ascending: tableState.ascending,
			orderByColumn: tableState.sortBy,
		};
	},

	getCustomFilter(tableName) {
		if (!store.state[tableName]) {
			return {};
		}

		const customQueries = store.state[tableName].customQueries;
		return customQueries.filter || {};
	},

	hasCustomFilter(tableName) {
		let filters = this.getCustomFilter(tableName);

		filters = utils.removeEmptyRecursive(filters);

		return !isEmpty(filters);
	},

	updateCustomFilter(filtersModelPartial, tableName) {
		const existingFilters = this.getCustomFilter(tableName);

		this.setCustomFilter(
			{
				...existingFilters,
				...filtersModelPartial,
			},
			tableName
		);
	},

	setCustomFilter(filtersModel, tableName) {
		store.commit('DELETE_QUICK_FILTER', { filter: tableName });

		let value = cloneDeep(filtersModel);
		store.commit(tableName + '/SET_CUSTOM_FILTER', {
			filter: 'filter',
			value,
		});
	},

	removeCustomFilter(filtersModel, tableName) {
		utils.setObjValuesToNull(filtersModel);
		this.setCustomFilter(filtersModel, tableName);
	},

	handleScrollOnSelectPage(mutation) {
		if (mutation.type.includes('/PAGINATION')) {
			var top = 0;
			var vueTable = document.getElementsByClassName('detail-table');
			if (vueTable.length > 0) {
				top = vueTable[0].offsetTop + 10;
			}

			window.scrollTo({
				top: top,
				left: 0,
				behavior: 'smooth',
			});
		}
	},

	addNewLineToText(text) {
		if (text != '') {
			return '\n' + text;
		}

		return text;
	},
};

export default serviceCommon;
