<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap align-items-center">
			<b-card-title class="mt-4" v-if="showTitle">{{ $t('orderedpackages') }}</b-card-title>
			<megau-button
				classprop="btn btn-success"
				icon="plus"
				titlePosition="right"
				:title="$t('addanewpackage')"
				v-if="editable"
				@handleClick="editOrInsertDetail(0)"
			></megau-button>
		</div>
		<v-server-table
			v-show="showTable"
			name="productsTable"
			:columns="columns"
			:options="tableOptions"
			ref="productsTable"
			class="detail-table"
			:key="tableKey"
		>
			<template #NumberRow="list">{{ list.index }}.</template>

			<template #DateStart="list">{{ list.row.DateStart | date }}</template>

			<template #DateEnd="list">{{ list.row.DateEnd | date }}</template>

			<template #PriceTotal="list">
				{{ list.row.PriceTotal | currency(getCurrencyById(list.row.CurrencyId)) }}
			</template>

			<template #Vat="list">
				{{ list.row.Vat | currency(getCurrencyById(list.row.CurrencyId)) }}
			</template>

			<template #Price="list">
				{{ list.row.Price | currency(getCurrencyById(list.row.CurrencyId)) }}
			</template>

			<template #DatePayment="list">{{ formatDatePayment(list.row) }}</template>

			<template #INumber="list">
				<div class="d-flex justify-content-between">
					<invoice-link
						:iNumber="list.row.INumber"
						:iPFNumber="list.row.IPFNumber"
						:iCode="list.row.ICode"
						:iPFCode="list.row.IPFCode"
						:iType="list.row.InvoiceType"
						:rowMarked="list.row.Marked"
						:wrapperClass="invoiceClass(list.row)"
					></invoice-link>
					<invoice-note-for-products
						:invoiceId="list.row.InvoiceId"
						:invoiceNote="list.row.InvoiceNote"
						:refreshMethod="refresh"
						:editable="editable"
					></invoice-note-for-products>
				</div>
			</template>
			<template #InvoiceVariableSymbol="list">{{ formatVariableSymbol(list.row.InvoiceVariableSymbol) }}</template>

			<template #Edit="list">
				<div class="d-flex justify-content-end" v-if="!invoiceId || list.row.Marked">
					<div v-b-tooltip.hover.left :title="$t('editpackage')">
						<font-awesome-icon
							icon="edit"
							cursor="pointer"
							:class="list.row.Marked ? 'higlighted-link' : 'detail-table-icon'"
							size="lg"
							@click="editOrInsertDetail(list.row.Id)"
						></font-awesome-icon>
					</div>
				</div>
			</template>
		</v-server-table>
	</div>
</template>

<script>
import { debounce } from 'debounce';
import { Promise } from 'q';
import { abstractField } from 'vue-form-generator';

import vars from '../../services/helpers/variables';
import serviceCommon from '../../services/service/common.service';
import serviceEnums from '../../services/service/enums.service';
import { formatter } from '../../services/helpers/formatter';

import service from './product.service';
import { tableModel } from './product.model';

export default {
	mixins: [abstractField],
	props: {
		objectId: { type: Number },
		invoiceId: { type: Number },
		userId: { type: Number },
		editable: { type: Boolean, default: true },
		filterable: { type: [Boolean, Array], default: false },
		showTitle: { type: Boolean, default: true },
		markCurrentPackage: { type: Boolean, default: false },
		tableKey: { type: Number },
	},
	data() {
		return tableModel();
	},
	computed: {
		tableOptions() {
			return {
				preserveState: true,
				columns: 'Cols',
				hiddenColumns: this.editable ? [] : ['Edit'],
				sortable: [],
				filterable: this.filterable,
				texts: serviceCommon.recordsPaginationsTexts(),
				headings: service.headingTranslations(),
				rowClassCallback: function (row) {
					return row.Marked === true ? 'highlighted-row' : '';
				},
				requestFunction: (data) => {
					return service.getList(this.objectId, this.invoiceId, this.markCurrentPackage).then((resp) => {
						if (resp.data.length > 0) {
							this.showTable = true;
						} else {
							this.showTable = false;
						}
						// if (this.invoiceId) {
						// 	this.moveMarkedToStart(resp.data);
						// }

						return resp;
					});
				},
			};
		},
	},
	created() {
		this.getCurrencyEnum();
	},
	methods: {
		refresh() {
			this.tableKey += 1;
		},
		moveMarkedToStart(data) {
			let markedIndex = data.findIndex((el) => el.Marked == true);
			if (markedIndex > -1) data.move(markedIndex, 0);
		},
		editOrInsertDetail(productId) {
			this.$emit('editproduct', productId);
		},

		formatDatePayment(item) {
			if (!item.DatePayment) {
				if (item.IPFNumber || item.INumber) {
					return '-';
				}
				return formatter.dateToString(item.DateCreated);
			}
			return formatter.dateToString(item.DatePayment);
		},

		formatVariableSymbol(varSymbol) {
			return varSymbol ? varSymbol : '-';
		},

		getCurrencyEnum() {
			this.currencyDict = serviceEnums.getEnumDict('currency');
		},

		getCurrencyById(currencyId) {
			return this.currencyDict[currencyId].Text;
		},
		invoiceClass(row) {
			return !row.INumber && !row.IPFNumber ? 'd-flex flex-row without-invoice' : 'd-flex flex-column';
		},
		showEditProduct(row) {
			return row.Marked && !!row.DatePayment ? false : true;
		},
	},
};
</script>

<style lang="scss">
.without-invoice {
	> span:nth-child(1) {
		display: none;
	}
}

@import '@/styles/components/detail-table.scss';
</style>
