import vueFormGenerator from 'vue-form-generator';

var OstypeOrder = {
	type: 'input',
	inputType: 'number',
    model: 'OstypeOrder',
    min: 0,
    styleClasses: 'half-width',
	label: '',
	validator: vueFormGenerator.validators.integer,
};

var CountryName = {
	type: 'label',
	inputType: 'string',
	model: 'CountryName',
	label: '',
};

var OstypeIsVisibleHomepage = {
	type: 'switch',
	label: '',
	model: 'OstypeIsVisibleHomepage',
	textOn: '',
	textOff: '',
	help: '',
};

var OstypeIsVisibleCatalogSubfilter = {
	type: 'switch',
	label: '',
	model: 'OstypeIsVisibleCatalogSubfilter',
	textOn: '',
	textOff: '',
	help: '',
};

var OstypeIsVisibleHomepage = {
	type: 'switch',
	label: '',
	model: 'OstypeIsVisibleHomepage',
	textOn: '',
	textOff: '',
	help: '',
};

var OstypeIsEnabled = {
	type: 'switch',
	label: '',
	model: 'OstypeIsEnabled',
	textOn: '',
	textOff: '',
	help: '',
};

function groups() {
	return [
		{		
			fields: [
                CountryName,
                OstypeOrder,
                OstypeIsEnabled,
				OstypeIsVisibleHomepage,
                OstypeIsVisibleCatalogSubfilter,      
			],
		}
	];
}

export default {
    groups,
    CountryName,
	OstypeOrder,
    OstypeIsVisibleHomepage,
    OstypeIsVisibleCatalogSubfilter,
    OstypeIsEnabled
};
