Date.prototype.toJSON = function() {
	// converts local time as Zulu time - ignore timezone

	//console.log("Date.prototype.toJSON");
	var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time
	var sign = timezoneOffsetInHours >= 0 ? '+' : '-';
	var leadingZero = Math.abs(timezoneOffsetInHours) < 10 ? '0' : '';

	//It's a bit unfortunate that we need to construct a new Date instance
	//(we don't want _this_ Date instance to be modified)
	var correctedDate = new Date(
		this.getFullYear(),
		this.getMonth(),
		this.getDate(),
		this.getHours(),
		this.getMinutes(),
		this.getSeconds(),
		this.getMilliseconds()
	);
	correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
	var iso = correctedDate.toISOString().replace('Z', '');

	return iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ':00';
};

String.prototype.isNullOrWhiteSpace = function() {
	if (this) {
		return this.replace(/\s/g, '').length == 0;
	}

	return true;
};

String.prototype.withoutDiacritics = function() {
	if (this) {
		return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	return true;
};

Array.prototype.move = function(from, to) {
	this.splice(to, 0, this.splice(from, 1)[0]);
};

Array.prototype.customGroupBy = function(groupBy) {
	return this.reduce(function(rv, x) {
		(rv[x[groupBy]] = rv[x[groupBy]] || []).push(x);
		return rv;
	}, {});
};
