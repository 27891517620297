<template>
	<div>
		<div class="chpwd-buttons">
			<megau-button
				classprop="btn btn-secondary btn-chPwd"
				icon="unlock-alt"
				:title="$t('changepassword')"
				@handleClick="changeVisible"
			></megau-button>
			<megau-button
				classprop="btn btn-secondary btn-resetAlc"
				icon="sync-alt"
				:title="$t('resetautolog')"
				@handleClick="resetAutologCode"
			></megau-button>
		</div>
		<b-modal
			size="lg"
			:visible="visible"
			centered
			@hide="close()"
			:title="$t('changepassword2361')"
			no-close-on-backdrop
			no-enforce-focus
		>
			<vue-form-generator
				:schema="chPwdschema"
				ref="chPwdVfg"
				:model="chPwdmodel"
				:options="chPwdformOptions"
			></vue-form-generator>

			<template v-slot:modal-footer>
				<megau-button
					classprop="btn btn-success"
					icon="check"
					:title="$t('changepassword2365')"
					@handleClick="change"
				></megau-button>
				<megau-button
					classprop="btn btn-primary"
					icon="undo"
					:title="$t('closewithoutcha')"
					@handleClick="close()"
				></megau-button>
			</template>
		</b-modal>
	</div>
</template>


<script>
import VueFormGenerator from 'vue-form-generator';
import { abstractField } from 'vue-form-generator';

import service from './contact.service';
import * as links from '../../router/links';
import i18n from '../../services/lang';

export default {
	props: {
		contactId: { type: Number },
	},
	name: 'change-password',
	components: {
		'vue-form-generator': VueFormGenerator.component,
	},
	created() {
		var res = VueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
		res.textTooSmall = this.$i18n.t('passwordmustbea');
		this.chPwdmodel.password = '';
		this.chPwdmodel.passwordRepeat = '';
	},
	methods: {
		validateEquals: function() {
			if (this.chPwdmodel.password !== this.chPwdmodel.passwordRepeat) {
				return [this.$i18n.t('thepasswordisno')];
			} else {
				return [];
			}
		},
		changeVisible: function() {
			this.chPwdmodel.password = '';
			this.chPwdmodel.passwordRepeat = '';
			this.visible = true;
		},
		change: function() {
			if (this.chPwdmodel.password === this.chPwdmodel.passwordRepeat) {
				return service.setContactPassword(this.schema.contactId, this.chPwdmodel.password).then(this.close);
			}
		},
		close: function() {
			this.visible = false;
		},
		resetAutologCode: function() {
			let contactId = this.schema.contactId;

			this.$modal.show({
			title: i18n.t('resetautolog2737'),
				text: i18n.t('doyouwanttorese'),
				modalClass: 'modal-danger',
				variant: 'danger',
				okTitle: i18n.t('okresetautolog'),
				cancelTitle: i18n.t('closewithoutcha'),
				onConfirm() {
					service.resetAutologCode(contactId);
				},
			});
		},
	},
	mixins: [abstractField],
	data() {
		return {
			visible: false,
			chPwdmodel: {
				password: '',
				passwordRepeat: '',
				showPassword: false,
			},

			chPwdschema: {
				fields: [
					{
						type: 'input',
						inputType: 'password',
						label: i18n.t('newpassword'),
						min: 6,
						model: 'password',
						required: true,
						validator: VueFormGenerator.validators.string,
					},
					{
						type: 'input',
						inputType: 'password',
						label: i18n.t('newpasswordagai'),
						model: 'passwordRepeat',
						required: true,
						validator: this.validateEquals,
					},
					{
						type: 'switch',
						label: i18n.t('showpassword'),
						model: 'showPassword',
						textOn: i18n.t('yes2220'),
						textOff: i18n.t('no2221'),
						onChanged(model, newVal, oldVal) {
							if (newVal) {
								this.$parent.$parent.fields[0].inputType = 'text';
								this.$parent.$parent.fields[1].inputType = 'text';
							} else {
								this.$parent.$parent.fields[0].inputType = 'password';
								this.$parent.$parent.fields[1].inputType = 'password';
							}
						},
					},
				],
			},
			chPwdformOptions: {
				validateAfterLoad: false,
				validateAfterChanged: true,
				validateDebounceTime: 500,
				validateAsync: true,
			},
		};
	},
};
</script>


<style lang="scss" scoped>
.chpwd-buttons {
	display: flex;
	flex-flow: row wrap;
	flex-direction: row;

	> div {
		margin-right: 10px;
	}
}
</style>

<style lang="scss">
.btn-chPwd {
	color: var(--white) !important;
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}

.btn-resetAlc {
	padding: 10px;
	color: var(--white) !important;
	background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}
</style>
