import { loadLanguageTranslations, setLanguage } from './actions';
import i18n from '../services/lang';
import api from '../services/api/lang.api';

const loadedLanguages = [];
const state = {
	status: '',
	DateFormat: 'DD.MM.YYYY',
	DateTimeFormat: 'DD.MM.YYYY H:mm',
};

const actions = {
	[loadLanguageTranslations]({ commit }, lang) {
		if (loadedLanguages.includes(lang)) {
			commit(setLanguage, { lang: lang });
		} else {
			commit(loadLanguageTranslations, { status: 'loading', response: null, lang: lang });

			api.getTranslations(lang)
				.then((resp) => {
					commit(loadLanguageTranslations, {
						status: 'success',
						response: resp.data,
						lang: lang,
					});

					commit(setLanguage, { lang: lang });
				})
				.catch((err) => {
					commit(loadLanguageTranslations, {
						status: 'error',
						response: null,
						lang: lang,
					});
				});
		}
	},
};

const mutations = {
	[loadLanguageTranslations](state, { status, response, lang }) {
		if (response) {
			state.status = status;
			loadedLanguages.push(lang);
			i18n.setLocaleMessage(lang, response);
		}
	},
	[setLanguage](state, { lang }) {
		i18n.locale = lang;
		switch (lang) {
			case 'sk':
				state.DateFormat = 'DD.MM.YYYY';
				state.DateTimeFormat = 'DD.MM.YYYY HH:mm';
				state.locale = 'sk-SK';
				break;
			case 'cs':
				state.DateFormat = 'DD.MM.YYYY';
				state.DateTimeFormat = 'DD.MM.YYYY HH:mm';
				state.locale = 'cs-CZ';
				break;
			case 'en':
				state.DateFormat = 'DD.MM.YYYY';
				state.DateTimeFormat = 'DD.MM.YYYY HH:mm';
				state.locale = 'en-EN';
				break;
		}
	},
};

export default {
	state,
	actions,
	mutations,
};
