<template>
	<div class="note-wrapper" v-html="value"></div>
</template>
<script>
import { abstractField } from 'vue-form-generator';

export default {
	mixins: [abstractField],
	name: 'owner-history',
};
</script>

<style lang="scss" scoped>
.note-wrapper {
	white-space: break-spaces;
}
</style>
