import { library } from '@fortawesome/fontawesome-svg-core';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons/faShareSquare';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons/faUnlockAlt';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons/faPhoneSquareAlt';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faFlag } from '@fortawesome/free-regular-svg-icons/faFlag';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons/faStickyNote';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faRunning } from '@fortawesome/free-solid-svg-icons/faRunning';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons/faSkullCrossbones';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons/faUmbrellaBeach';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faPercentage } from '@fortawesome/free-solid-svg-icons/faPercentage';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl';

import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons/faArrowsAltH';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons/faHouseUser';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons/faFortAwesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMapSigns } from '@fortawesome/free-solid-svg-icons/faMapSigns';
import { faCity } from '@fortawesome/free-solid-svg-icons/faCity';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faFlag as fasFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';
import { faSketch } from '@fortawesome/free-brands-svg-icons/faSketch';
import { faSkiing } from '@fortawesome/free-solid-svg-icons/faSkiing';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faFileExport } from '@fortawesome/free-solid-svg-icons/faFileExport';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons/faLaptopHouse';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';

library.add(
	faComment,
	faEllipsisH,
	faPlusCircle,
	faEdit,
	faTimesCircle,
	faPlus,
	faCheck,
	faTimes,
	faExclamation,
	faCalendarAlt,
	faUndo,
	faTrashAlt,
	faHome,
	faStar,
	faSignOutAlt,
	faSort,
	faSortUp,
	faSortDown,
	faMinus,
	faEye,
	faShareSquare,
	faUnlockAlt,
	faKey,
	faCogs,
	faChartBar,
	faUser,
	faPhoneSquareAlt,
	faEnvelope,
	faUserLock,
	faFlag,
	faStickyNote,
	faSyncAlt,
	faRunning,
	faFile,
	faCheckCircle,
	faCog,
	faQuestion,
	faQuestionCircle,
	faExclamationTriangle,
	faMailBulk,
	faInfoCircle,
	faSkullCrossbones,
	faList,
	faUmbrellaBeach,
	faTags,
	faDoorOpen,
	faEuroSign,
	faPercent,
	faPercentage,
	faListOl,
	faArrowsAltH,
	faAngleRight,
	faChartLine,
	faHouseUser,
	faFileAlt,
	faAddressCard,
	faPaperPlane,
	faLink,
	faFortAwesome,
	faFileContract,
	faGlobe,
	faComments,
	faHandHoldingHeart,
	faLayerGroup,
	faCode,
	faBook,
	faMapMarkerAlt,
	faMapSigns,
	faCity,
	faMapMarkedAlt,
	faMap,
	fasFlag,
	faFileInvoice,
	faCoins,
	faSketch,
	faSkiing,
	faGoogle,
	faFileExport,
	faLaptopHouse,
	faChartArea
);
