import vueFormGenerator from 'vue-form-generator';

function fieldsVfg(i18n) {
	return [
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('companyname'),
			model: 'AcompanyName',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('streetandnumber'),
			model: 'AcompanyStreet',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			model: 'AcompanyCity',
			label: i18n.t('city'),
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('zip'),
			model: 'AcompanyZip',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('country2453'),
			model: 'AcompanyCountry',
			readonly: true,
			validator: vueFormGenerator.validators.integer,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('companyid'),
			model: 'AcompanyIco',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('taxid'),
			model: 'AcompanyDic',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('vatid'),
			model: 'AcompanyIcdph',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('phone'),
			model: 'AcompanyPhone',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('email'),
			model: 'AcompanyEmail',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('bankcode'),
			model: 'AcompanyBankCode',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
		{
			type: 'input',
			inputType: 'text',
			label: i18n.t('bankaccount'),
			model: 'AcompanyBankAccount',
			readonly: true,
			validator: vueFormGenerator.validators.string,
		},
	];
}

export default {
	fieldsVfg,
};
