var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autocomplete", {
    ref: "rautocomplete",
    attrs: {
      url: this.url,
      customHeaders: _vm.headers,
      customParams: _vm.customParams,
      onSelect: _vm.getData,
      onInput: _vm.onInput,
      label: "",
      anchor: "Name",
      debounce: 500,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }