<template>
	<b-modal
		modal-class="sojourn-type-countries-modal"
		size="lg"
		v-model="isVisible"
		centered
		:title="$t('editthesojournt')"
		@hidden="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
		body-class="no-scrollable"
	>
		<vue-form-generator
			:schema="this.schema"
			:model="this.model"
			:options="this.formOptions"
			ref="detailForm"
		></vue-form-generator>

		<template v-slot:modal-footer>
			<megau-button
				classprop="btn btn-success"
				:title="$t('savechangesandn')"
				icon="check"
				@handleClick="update()"
			></megau-button>
			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('closewithoutsav2459')"
				@handleClick="close()"
			></megau-button>
		</template>
	</b-modal>
</template>
<script>
import apiCommon from '../../../services/api/common.api';
import loading from '../../../mixins/loading.mixin';

import { detailModel } from './sojourn-type-countries.model';
import service from './sojourn-type-countries.service';
import formFields from './sojourn-type-countries.fields';

export default {
	name: 'sojournTypeCountriesModal',

	mixins: [loading],

	props: {
		entity: { type: Object },
		isVisible: { type: Boolean, default: false },
	},
	data() {
		return detailModel(this.$i18n);
	},

	mounted() {
		service.formTranslations(formFields, this.$i18n);
	},

	watch: {
		isVisible: function () {
			if (this.isVisible) {
				this.fetchData();
			}
		},
	},

	methods: {
		fetchData() {
			if (!this.entity) return;

			this.model.Id = this.entity.Id;
			this.model.OstypeId = this.entity.OstypeId;
			this.model.CountryId = this.entity.CountryId;
			this.model.CountryName = this.entity.CountryName;
			this.model.OstypeIsVisibleHomepage = this.entity.OstypeIsVisibleHomepage;
			this.model.OstypeIsVisibleCatalogSubfilter = this.entity.OstypeIsVisibleCatalogSubfilter;
			this.model.OstypeIsEnabled = this.entity.OstypeIsEnabled;
			this.model.OstypeOrder = this.entity.OstypeOrder;
		},

		close() {
			this.$emit('onClose');
			this.showModal = false;
		},
		update() {
			let _this = this;
			_this.startLoading(false);
			if (_this.model.Id > 0) {
				apiCommon.putEntity(_this.model, _this.controllerName).then(() => {
					_this.stopLoading();
					_this.$emit('onUpdate');
				});
			} else {
				apiCommon.postEntity(_this.model, _this.controllerName).then(() => {
					_this.stopLoading();
					_this.$emit('onUpdate');
				});
			}
		},
	},
};
</script>

<style lang="scss">
.sojourn-type-countries-modal .modal-body {
	.vue-form-generator {
		.field-radios-group {
			.field-wrap {
				padding-top: 6px;
			}
		}
		.form-group {
			label {
				width: 25%;
			}
		}
	}
}
</style>
