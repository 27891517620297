var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "country-flag" }, [
    _vm.existCountry
      ? _c("span", [_vm._v(_vm._s(_vm.srcCountry))])
      : _vm.existLang
      ? _c("span", [_vm._v(_vm._s(_vm.srcLang))])
      : _c("span", [_vm._v("-")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }