<template>
	<div class="datepicker-wrapper">
		<font-awesome-icon
			icon="calendar-alt"
			@click="clickDateIcon"
			cursor="pointer"
			class="calendar-icon"
		></font-awesome-icon>
		<font-awesome-icon icon="times" @click="clickDeleteIcon" cursor="pointer" class="delete-icon"></font-awesome-icon>
		<date-range-picker
			ref="datepicker"
			v-model="dateRange"
			:locale-data="locale"
			:singleDatePicker="false"
			:ranges="false"
			:autoApply="true"
			:showDropdowns="true"
			@update="updateValue"
		/>
	</div>
</template>
<script>
import { abstractField } from 'vue-form-generator';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
	mixins: [abstractField],
	components: { DateRangePicker },
	data() {
		return {
			dateRange: {
				startDate: null,
				endDate: null,
			},
			locale: {
				format: this.$store.state.lang.DateFormat, //fomart of the dates displayed
				applyLabel: this.$i18n.t('delete2315'),
				cancelLabel: this.$i18n.t('cancel2314'),
				daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
				firstDay: 1, //ISO first day of week - see moment documenations for details
			},
		};
	},

	created() {
		this.dateRange = this.value;
	},

	watch: {
		value() {
			console.log('watch ' + this.value);
			this.dateRange = this.value;
		},
	},

	computed: {
		disabledCalendar() {
			return {
				to: this.schema.minDate ? this.schema.minDate : null,
			};
		},
		language() {
			const locale = this.schema.language ? this.schema.language : 'en';
			console.log(translatorLocales[locale]);
			return translatorLocales[locale];
		},
		dateTimeFormat() {
			return this.$store.state.lang.DateTimeFormat;
		},
	},
	methods: {
		updateValue(date) {
			this.$emit('handleUpdateDates', this.dateRange);
			if (this.schema.onChanged) {
				this.schema.onChanged.call(this, this.dateRange);
			}
		},
		clickDeleteIcon() {
			this.dateRange.startDate = null;
			this.dateRange.endDate = null;
			this.updateValue(null);
		},
		clickDateIcon() {
			if (this.$refs.datepicker.open == false) {
				this.$refs.datepicker.open = true;
			} else {
				this.$refs.datepicker.open = false;
			}
		},
	},
};
</script>
<style lang="scss">
@import '@/styles/components/detail-datepicker.scss';
</style>
